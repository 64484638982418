import { RowTest } from ".";
import TestStateBadge from "./TestStateBadge";
import TestLocation from "../../shared/Metadata/TestLocation";

type Props = {
  test: RowTest;
};

const Row = ({ test }: Props) => (
  <li className="row-link__container">
    <div className="ta-panel-row grid-cols-[auto_6rem_8rem]">
      <div className="main-section">
        {test.state && <TestStateBadge state={test.state} />}
        <a
          href={test.url}
          className="block font-medium text-inherit no-underline hover:underline focus:underline truncate"
        >
          {test.scope} {test.name}
        </a>

        <TestLocation
          location={test.location}
          locationUrl={test.location_url}
        />
      </div>
      <div className="text-right">
        {test.reliability}
        <span className="hide"> reliable</span>
      </div>
      <div className="text-right">
        {test.average_duration}
        <span className="hide"> average duration</span>
      </div>
    </div>
  </li>
);

export default Row;
