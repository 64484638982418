import Dropdown from "app/components/shared/Dropdown";
import Button from "app/components/shared/Button";
import Icon from "app/components/shared/Icon";
import {
  parseTagParams,
  keyValueToTag,
} from "app/components/analytics/shared/tagHelpers";

const TAG_PARAM_NAMES = {
  filterBy: "tags",
  groupBy: "group_by_tag",
};

const TagSelectors = ({ tags }) => {
  const baseUrl = new URL(window.location.href);
  const filterByTags = parseTagParams(
    baseUrl.searchParams.get(TAG_PARAM_NAMES.filterBy),
  );
  const groupByTags = parseTagParams(
    baseUrl.searchParams.get(TAG_PARAM_NAMES.groupBy),
  );
  const linkClassNames =
    "text-decoration-none p-1 text-xs text-charcoal-700 rounded focus:no-underline focus:text-charcoal-700 focus:outline focus:outline-2 focus:outline-lime-500 hover:text-purple-600 data-[active=true]:text-purple-600 hover:no-underline truncate";

  const generateFilterUrl = (key, value, tag) => {
    let updatedTags;
    const url = new URL(window.location.href);

    // Remove the execution params so the drawer does not open
    url.searchParams.delete("execution_id");

    if (filterByTags.hasTag(tag)) {
      updatedTags = filterByTags.excludeByTag(tag);
    } else {
      // Use `includeByKey` to override any existing tags with the same key
      updatedTags = filterByTags.includeByKey(key, value);
    }

    url.searchParams.set(TAG_PARAM_NAMES.filterBy, updatedTags.toQueryString());

    return url.toString();
  };

  // TODO: when we have multiple group_by tags, we can consolidate this with
  // the generateTagUrl function. Currently it is a bit different as it replaces
  // the existing param rather than adding to it.
  const generateGroupUrl = (tag) => {
    const url = new URL(window.location.href);

    // Remove the execution params so the drawer does not open
    url.searchParams.delete("execution_id");

    // Only supports adding a single group_by at the moment
    if (groupByTags.hasTag(tag)) {
      url.searchParams.delete(TAG_PARAM_NAMES.groupBy);
    } else {
      url.searchParams.set(TAG_PARAM_NAMES.groupBy, tag);
    }

    return url.toString();
  };

  return (
    <div className="flex flex-wrap items-center gap1 w-full">
      {tags.map(({ key, value }) => {
        const tag = keyValueToTag(key, value);
        const activeFilter = filterByTags.hasTag(tag);
        const activeGroup = groupByTags.hasTag(key);

        return (
          <Dropdown key={tag} className="flex">
            <Button
              theme="small"
              data-active={activeFilter || activeGroup}
              className="text-xs h-auto py-0.5 px-2 data-[active=true]:text-purple-600"
            >
              {tag}
              <Icon
                icon="down-triangle"
                style={{ width: 8, height: 8, marginLeft: "5px" }}
              />
            </Button>

            <div className="p-2 flex flex-column gap-1">
              <a
                href={generateFilterUrl(key, value, tag)}
                data-active={activeFilter}
                className={linkClassNames}
              >
                {activeFilter ? "Remove filter: " : "Filter by: "}
                <strong>{tag}</strong>
              </a>
              <a
                href={generateGroupUrl(key)}
                data-active={activeGroup}
                className={linkClassNames}
              >
                {activeGroup ? "Remove group: " : "Group by: "}
                <strong>{key}</strong>
              </a>
            </div>
          </Dropdown>
        );
      })}
    </div>
  );
};

export default TagSelectors;
