import compact from "lodash/compact";

export const BRANCH_KEY = "scm.branch";
// Tags cannot contain commas or colons which enables us to split by commas and colons.
// We will need to update the constants below logic if this syntax ever changes.
const TAGS_SEPARATOR = ",";
const TAG_KEY_VALUE_SEPARATOR = ":";

export function parseTagParams(tags: string | null | undefined): Tags {
  const tagArray = tags
    ? tags.split(TAGS_SEPARATOR).map((tag) => tag.trim())
    : [];
  return new Tags(tagArray);
}

export function keyValueToTag(key: string, value: string): string {
  return `${key}${TAG_KEY_VALUE_SEPARATOR}${value}`;
}

class Tags {
  tags: string[];

  constructor(tags: string[]) {
    this.tags = tags;
  }

  hasTag(tag: string): boolean {
    return this.tags.includes(tag);
  }

  excludeByTag(tag: string): Tags {
    if (!tag) {
      return this;
    }

    let newTags = this.tags.filter((parsedTag) => parsedTag !== tag);
    // Remove any empty strings
    newTags = compact(newTags);

    return new Tags(newTags);
  }

  includeByTag(tag: string): Tags {
    if (!tag) {
      return this;
    }

    // Use a Set to ensure that we don't add duplicate tags
    let newTags = Array.from(new Set([...this.tags, ...[tag]]));
    // Remove any empty strings
    newTags = compact(newTags);

    return new Tags(newTags);
  }

  excludeByKey(key: string): Tags {
    if (!key) {
      return this;
    }

    const filteredTags = this.tags.filter(
      (existingTag) =>
        !existingTag.startsWith(`${key}${TAG_KEY_VALUE_SEPARATOR}`),
    );

    return new Tags(filteredTags);
  }

  includeByKey(key: string, value: string): Tags {
    if (!key || !value) {
      return this;
    }

    // Remove any existing tags with the same key and add the new tag
    const updatedTags = this.excludeByKey(key).includeByTag(
      keyValueToTag(key, value),
    );
    return new Tags(updatedTags.tags);
  }

  toQueryString(): string {
    return this.tags.join(TAGS_SEPARATOR);
  }
}
