/* eslint-disable id-length */

import Icon from "app/components/shared/Icon";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import { useHotKey } from "app/components/Playground/Controls/useHotKey";
import {
  DockPosition,
  useBuildPreferencesStore,
} from "../lib/useBuildPreferencesStore";
import { ResizeHandle } from "./ResizeHandle";

interface Props {
  children: React.ReactNode;
  onClose: () => void;
}

// TODO: If we can't find a way to get animations working as expected, we should remove this code.
const getVariants = (dock: DockPosition) => {
  switch (dock) {
    case DockPosition.Center:
      return {
        initial: {},
        animate: { scale: 1, transition: { type: "spring", duration: 0.25 } },
        exit: { transition: { duration: 0 } },
      };
    // No animation for bottom docked drawer
    case DockPosition.Bottom:
      return {
        initial: {},
        animate: { y: 0 },
        exit: { transition: { duration: 0 } },
      };
    // No animation for right docked drawer
    case DockPosition.Right:
      return {
        initial: {},
        animate: { x: 0 },
        exit: { transition: { duration: 0 } },
      };
  }
};

/**
 * A collapsible drawer
 */
export const Drawer = ({ children, onClose }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const [width, setResizedDrawerWidth, resetDrawerWidth] =
    useBuildPreferencesStore((state) => [
      state.resizedDrawerWidth,
      state.setResizedDrawerWidth,
      state.resetDrawerWidth,
    ]);

  const [height, setResizedDrawerHeight, resetDrawerHeight] =
    useBuildPreferencesStore((state) => [
      state.resizedDrawerHeight,
      state.setResizedDrawerHeight,
      state.resetDrawerHeight,
    ]);

  const dockPosition = useBuildPreferencesStore((state) => state.dockPosition);

  useHotKey("Escape", () => {
    if (expanded) {
      setExpanded(false);
    } else {
      onClose();
    }
  });

  const toggleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  // Store a reference to the container element
  const containerRef = useRef<HTMLElement>();
  useEffect(() => {
    const container = document.getElementById("drawer-container");
    if (container) {
      containerRef.current = container;
    }
  }, []);

  // TODO: Work out where this comes from
  const margin = 15;

  /**
   * Resize the drawer when dragging the resize handle
   *
   * Hook into the mouse down event to start dragging the resize handle
   * and update the drawer size based on the mouse position, storing the
   * new size in a preferences store for persistence across sessions.
   *
   * Sizes are stored as percentages to allow for responsive resizing.
   */
  const resize = useCallback(
    (x: number, y: number) => {
      // Prevent resizing when the drawer is expanded
      if (expanded) {
        return;
      }

      // If the drawer is docked to bottom, resize the height (as a percentage)
      if (dockPosition === DockPosition.Bottom) {
        if (!containerRef.current?.clientHeight) {
          return resetDrawerHeight();
        }

        let height =
          ((window.innerHeight - y - margin) /
            containerRef?.current?.clientHeight) *
          100;

        if (height > 100) {
          height = 100;
        } else if (height < 5) {
          height = 5;
        }

        setResizedDrawerHeight(height);
      }

      // If the drawer is docked to right, resize the width (as a percentage)
      if (dockPosition === DockPosition.Right) {
        if (!containerRef.current?.clientWidth) {
          return resetDrawerWidth();
        }

        let width =
          ((window.innerWidth - x - margin) /
            containerRef?.current?.clientWidth) *
          100;

        if (width > 100) {
          width = 100;
        } else if (width < 5) {
          width = 5;
        }

        setResizedDrawerWidth(width);
      }
    },
    [setResizedDrawerHeight, setResizedDrawerWidth, expanded, dockPosition],
  );

  const style: React.CSSProperties = {};
  if (dockPosition === DockPosition.Right) {
    style.width = expanded ? `100%` : `${width}%`;
  }

  if (dockPosition === DockPosition.Bottom) {
    style.height = expanded ? `100%` : `${height}%`;
  }

  return (
    <motion.div
      key={dockPosition}
      data-position={dockPosition}
      data-testid="drawer"
      style={style}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={getVariants(dockPosition)}
      transition={{ type: "spring", duration: 0.3 }}
      className={twMerge(
        // Custom class to override the some job list item styles (consolidate once this is the source of truth)
        "drawer group",

        "bg-white z-20 relative rounded-md border-gray-400 border",
        classNames({
          // Centre-docked w/ outline hack to overlap the build state border
          "absolute left-0 right-0 top-0 bottom-0 outline outline-1 outline-white":
            dockPosition === DockPosition.Center,

          // Expanded
          "fixed left-0 right-0 top-0 bottom-0": expanded,
        }),
      )}
    >
      <div>
        {dockPosition === DockPosition.Bottom && (
          <ResizeHandle
            onResize={resize}
            direction="vertical"
            onDoubleClick={resetDrawerHeight}
          />
        )}

        {dockPosition === DockPosition.Right && (
          <ResizeHandle
            onResize={resize}
            direction="horizontal"
            onDoubleClick={resetDrawerWidth}
          />
        )}

        <div className="absolute h-full w-full left-0 top-0 overflow-auto rounded-md">
          <div className="flex flex-col px-3">
            <div className="flex gap-2 sticky top-0 z-10 bg-white py-2">
              <DrawerButton onClick={onClose} data-testid="drawer-close-button">
                <Icon icon="heroicons/20/solid/x-mark" className="h-5" />
              </DrawerButton>

              <div className="flex gap-2 ml-auto">
                <DrawerButton
                  onClick={toggleExpand}
                  isActive={expanded}
                  data-testid="drawer-expand-button"
                >
                  <Icon
                    className="h-4 rotate-90"
                    icon={
                      expanded
                        ? "custom/outline/minimize"
                        : "custom/outline/maximize"
                    }
                  />
                </DrawerButton>

                <DockPositions onClose={onClose} />
              </div>
            </div>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const DrawerButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & { isActive?: boolean },
) => (
  <button
    {...props}
    className={classNames(
      "rounded-md px-0.5 h-7 flex items-center justify-center transition-colors duration-200 ease-in-out",
      {
        "bg-purple-100 text-purple-600 hover:bg-purple-200": props.isActive,
        "bg-gray-100 hover:bg-purple-100 hover:text-purple-600":
          !props.isActive,
      },
    )}
  />
);

const positions = [
  {
    icon: "custom/outline/panel",
    position: DockPosition.Center,
  },
  {
    icon: "custom/outline/panel-bottom",
    position: DockPosition.Bottom,
  },
  {
    icon: "custom/outline/panel-right",
    position: DockPosition.Right,
  },
];

/**
 * A collection of buttons to change the dock position of the drawer.
 *
 * Clicking a button will change the dock position and if the dock position is already selected, the drawer will close.
 */
const DockPositions = ({ onClose }: { onClose: () => void }) => {
  const dockPosition = useBuildPreferencesStore((state) => state.dockPosition);
  const setDockPosition = useBuildPreferencesStore(
    (state) => state.setDockPosition,
  );

  return (
    <div className="bg-gray-100 rounded-md flex">
      {positions.map(({ icon, position }) => (
        <DrawerButton
          key={position}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => {
            // Close the drawer if the active button is clicked again
            if (dockPosition === position) {
              onClose();
            } else {
              setDockPosition(position);
            }
          }}
          isActive={dockPosition === position}
        >
          <Icon icon={icon} className="h-4" />
        </DrawerButton>
      ))}
    </div>
  );
};
