import * as React from "react";
import copy from "copy-to-clipboard";

import { DateLike } from "app/lib/date";
import LegacyDispatcher from "app/lib/legacyDispatcher";

import JobLogGroupStore from "app/stores/JobLogGroupStore";
import JobLogLineStore from "app/stores/JobLogLineStore";
import {
  LogGroupHeader,
  LogGroupName,
  LogGroupTime,
  LogLineNumber,
  LogTimestamp,
  LogCopyPermalink,
} from "app/components/shared/Log";
import Icon from "app/components/shared/Icon";

const getStateFromStores = (id: string) => ({
  highlighted: JobLogLineStore.isHighlighted(id),
  expanded: JobLogGroupStore.isExpanded(id),
});

type Props = {
  id: string;
  name: string;
  number: number;
  deemphasized?: boolean;
  startedAt: DateLike | null | undefined;
  finishedAt: DateLike | null | undefined;
  finished: boolean;
  durationPercentage: number | null | undefined;
  timestampsOn: boolean | null | undefined;
  timestampsInUTC: boolean | null | undefined;
  onTimestampClick: (
    arg1: React.MouseEvent<HTMLElement>,
  ) => void | null | undefined;
};

type State = {
  highlighted: boolean;
  expanded: boolean;
  showCopyPermalink: boolean;
};

export default class JobLogOutputHeader extends React.PureComponent<
  Props,
  State
> {
  wrapperNode: HTMLTableRowElement | null | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      ...getStateFromStores(props.id),
      showCopyPermalink: false,
    };
  }

  // Notify the stores that a highlighted line has been rendered. This gives the
  // stores an opportunity to scroll to this line if they need to.
  dispatchRenderedHighlightAction() {
    if (this.state.highlighted) {
      // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
      return LegacyDispatcher.emit("job_log_group:highlighted", {
        groupId: this.props.id,
        el: this.wrapperNode,
      });
    }
  }

  componentDidMount() {
    JobLogLineStore.addChangeListener(this.props.id, this.handleStoreChange);
    JobLogGroupStore.addChangeListener(this.props.id, this.handleStoreChange);
    this.dispatchRenderedHighlightAction();
  }

  componentWillUnmount() {
    JobLogLineStore.removeChangeListener(this.props.id, this.handleStoreChange);
    JobLogGroupStore.removeChangeListener(
      this.props.id,
      this.handleStoreChange,
    );
  }

  componentDidUpdate() {
    this.dispatchRenderedHighlightAction();
  }

  handleStoreChange = () => {
    this.setState(getStateFromStores(this.props.id));
  };

  handleToggleHeaderClick = (evt: React.MouseEvent<HTMLTableCellElement>) => {
    evt.preventDefault();

    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    return LegacyDispatcher.emit("job_log_group:toggle", {
      groupId: this.props.id,
    });
  };

  handleNumberClick = (evt: React.MouseEvent<HTMLTableCellElement>) => {
    evt.preventDefault();

    this.setState({ showCopyPermalink: true });

    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    return LegacyDispatcher.emit("job_log_line:highlight", {
      lineId: this.props.id,
    });
  };

  handleCopyPermalink = (evt: React.MouseEvent) => {
    // Prevent the line from being unhighlighted
    evt.stopPropagation();

    const url = new URL(window.location.href);
    url.hash = this.props.id;
    copy(url.toString());

    this.setState({ showCopyPermalink: false });
  };

  handleTimestampClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    if (this.props.onTimestampClick) {
      this.props.onTimestampClick(evt);
    }
  };

  renderCopyPermalink = () => {
    return (
      <LogCopyPermalink
        onClick={this.handleCopyPermalink}
        visible={this.state.highlighted && this.state.showCopyPermalink}
      />
    );
  };

  render() {
    return (
      <LogGroupHeader
        ref={(wrapperNode) => (this.wrapperNode = wrapperNode)}
        highlighted={this.state.highlighted}
        deemphasized={this.props.deemphasized}
      >
        <LogLineNumber
          onClick={this.handleNumberClick}
          number={this.props.number}
        >
          {this.renderCopyPermalink()}
        </LogLineNumber>

        {this.props.timestampsOn && (
          <LogTimestamp
            time={this.props.startedAt}
            utc={this.props.timestampsInUTC}
            onClick={this.handleTimestampClick}
          />
        )}

        <LogGroupName
          onClick={this.handleToggleHeaderClick}
          name={this.props.name}
          expanded={this.state.expanded}
        />

        <LogGroupTime
          onClick={this.handleToggleHeaderClick}
          startedAt={this.props.startedAt}
          finishedAt={this.props.finishedAt}
          finished={this.props.finished}
          durationPercentage={this.props.durationPercentage}
        />
      </LogGroupHeader>
    );
  }
}
