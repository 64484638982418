import { useBuild } from "app/components/Playground/BuildContext";
import { CancelButton } from "../../Header/CancelButton";
import { RebuildButton } from "../../Header/RebuildButton";
import RetryFailedJobsButton from "../../Header/RetryFailedJobsButton";

export const BuildActions = () => {
  const { build, store } = useBuild();
  if (!build || !store) {
    throw new Error("Missing build context");
  }

  return (
    <>
      <RetryFailedJobsButton
        build={build}
        store={store}
        className="btn-small border border-slate-400 hover:border-slate-500 rounded-sm shadow-sm active:shadow-inner active:bg-gray-300 hover:bg-gray-100 transition-all font-medium"
      />
      <RebuildButton
        build={build}
        className="btn-small border border-slate-400 hover:border-slate-500 rounded-sm shadow-sm active:shadow-inner active:bg-gray-300 hover:bg-gray-100 transition-all font-medium"
      />
      <CancelButton
        build={build}
        store={store}
        // Tidy this up when we've got a single build experience
        className="btn-small border border-slate-400 hover:border-slate-500 rounded-sm shadow-sm active:shadow-inner active:bg-gray-300 hover:bg-gray-100 transition-all font-medium !text-red-500 hover:shadow-sm"
      />
    </>
  );
};
