import React, { useState } from "react";
import Dialog from "app/components/shared/Dialog";
import Button from "app/components/shared/Button";
import Icon from "app/components/shared/Icon";
import { useBuild } from "app/components/Playground/BuildContext";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const NEW_UI_FEATURES = [
  {
    icon: "custom/outline/devices",
    title: "Redesigned Layout",
    description:
      "The new layout aims to provide a more flexible workspace to visualise and understand your builds, allowing you to arrange the interface in a way that suits your workflow.",
  },
  {
    icon: "custom/outline/panel-left",
    title: "Steps Sidebar",
    description:
      "The new steps sidebar replaces the build header steps and provides a more granular, interactive view of your steps, with filters and grouping options.",
  },
  {
    icon: "custom/outline/panel-right-bottom",
    title: "Slide-out Drawer",
    description:
      "Dive deep into your build details with a new slide-out drawer, accessible from anywhere in your build by clicking a step or job.",
  },
];

const EMAIL_TEMPLATE = {
  email: "support@buildkite.com",
  subject: encodeURIComponent("Feedback on the new build page experience"),
};

const PROVIDE_FEEDBACK_URL = `mailto:${EMAIL_TEMPLATE.email}?subject=${EMAIL_TEMPLATE.subject}`;

const BuildPageToggleDialog: React.FC = () => {
  const { store } = useBuild();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { org } = useParams<{ org: string }>();

  if (!store) {
    throw new Error("Missing build context");
  }

  return (
    <>
      <button
        className={twMerge(
          "px-3 py-1 self-center rounded-md transition-colors flex items-center truncate",
          Features.BuildSidebar
            ? "self-center font-regular text-charcoal-600 hover:bg-gray-200 hover:border-gray-300"
            : "space-x-2 self-start font-medium text-purple-600 bg-purple-100 border border-purple-200 hover:bg-purple-100 hover:border-purple-300 hover:text-purple-600",
        )}
        onClick={() => setIsDialogOpen(true)}
      >
        <Icon
          icon={Features.BuildSidebar ? "arrow-left" : "custom/outline/sparkle"}
          className="w-4 h-4"
        />
        <span className="truncate">
          {Features.BuildSidebar
            ? "Switch to classic build page"
            : "Try the new build experience"}
        </span>
      </button>

      <Dialog
        isOpen={isDialogOpen}
        onRequestClose={() => setIsDialogOpen(false)}
        width={600}
      >
        <form
          className="p-8 bg-white"
          method="POST"
          action={
            Features.BuildSidebar
              ? `/${org}/build_sidebar_disable`
              : `/${org}/build_sidebar_enable`
          }
        >
          <input
            type="hidden"
            name={window._csrf.param}
            value={window._csrf.token}
          />
          <h2 className="flex items-center text-2xl font-medium text-charcoal-900">
            <span>
              {Features.BuildSidebar
                ? "Switch back to classic build page?"
                : "Try the new build page experience"}
            </span>
          </h2>
          {Features.BuildSidebar ? (
            <>
              <p className="text-base text-charcoal-600 mb-4">
                You&apos;ll be returned to the classic build page experience.
                You can return to the new experience at any time.
              </p>
              <div className="space-y-2 mb-6">
                <div className="px-3 py-1 rounded-md bg-purple-100 flex items-center space-x-3">
                  <p className="text-sm text-charcoal-700">
                    Want to help us improve the new build page experience?&nbsp;
                    <a
                      href={PROVIDE_FEEDBACK_URL}
                      target="_blank"
                      rel="noreferrer"
                      className="font-medium text-purple-600 hover:text-purple-600"
                    >
                      Give Feedback
                    </a>
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="text-base text-charcoal-600 mb-6">
                A redesigned layout with more customization and flexible
                navigation.
              </p>
              <div className="space-y-2 mb-6">
                {NEW_UI_FEATURES.map((feature) => (
                  <div
                    key={feature.title}
                    className="flex items-start space-x-4"
                  >
                    <Icon
                      icon={feature.icon}
                      className="w-6 h-6 text-blue-500 flex-shrink-0 mt-1"
                    />
                    <div>
                      <h3 className="font-medium text-lg mt-0.5 mb-0 text-charcoal-800">
                        {feature.title}
                      </h3>
                      <p className="text-charcoal-400 mt-1">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="px-3 py-1 rounded-md bg-gray-200 flex items-center space-x-3">
                  <Icon icon="info-circle" className="opacity-90" />
                  <p className="text-sm text-charcoal-500">
                    You can return to the classic build page experience at any
                    time.
                  </p>
                </div>
              </div>
            </>
          )}
          <div className="flex justify-end space-x-2 md:space-x-4">
            <Button
              type="button"
              onClick={() => setIsDialogOpen(false)}
              theme="default"
              className="px-4 py-2 rounded-md hover:bg-gray-200"
            >
              {Features.BuildSidebar ? "Nevermind" : "Maybe later"}
            </Button>
            <Button
              type="submit"
              theme="primary"
              className="px-4 py-2 font-medium transition-colors"
            >
              {Features.BuildSidebar ? "Switch back" : "Try it now"}
            </Button>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default BuildPageToggleDialog;
