import Icon from "app/components/shared/Icon";
import { shortCommit } from "app/lib/commits";
import { twMerge } from "tailwind-merge";

interface Props {
  commitId?: string;
  commitShortLength?: number;
  commitUrl?: string;
  project: {
    provider: {
      frontendIcon?: string;
      url?: string;
      id?: string;
    };
  };
  className?: string;
}

export function Commit({
  commitId,
  commitShortLength,
  commitUrl,
  project,
  className,
}: Props) {
  if (!commitId) {
    return null;
  }

  if (commitUrl) {
    return (
      <span
        title={commitId}
        className={twMerge("flex items-center font-mono", className)}
      >
        <a
          href={commitUrl}
          className="color-inherit hover-color-inherit flex items-center"
        >
          <Icon
            icon={`custom/solid/provider-${project.provider.frontendIcon}`}
            className="h-5 w-5 mr-1"
          />
          {shortCommit(commitId, commitShortLength)}
        </a>
      </span>
    );
  }

  return (
    <span title={commitId} className={twMerge("font-mono", className)}>
      {shortCommit(commitId, commitShortLength)}
    </span>
  );
}
