import React, { useEffect } from "react";
import moment from "moment";
import classNames from "classnames";

import Duration from "app/components/shared/Duration";
import LegacySpinner from "app/components/shared/LegacySpinner";
import Icon from "app/components/shared/Icon";
import parseEmoji from "app/lib/parseEmoji";
import {
  DateLike,
  getComputerDateString,
  getLocalTimezoneAbbr,
} from "app/lib/date";

export function Log({
  className,
  theme,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  theme?: string;
}) {
  return (
    <div
      className={classNames("JobLogOutputComponent", className)}
      data-theme={theme}
      {...props}
    >
      {children}
    </div>
  );
}

export function LogHeader({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames("JobLogOutputComponent__Controls", className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function LogControl({
  children,
  active,
  ...props
}: (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | ({ href: string } & React.AnchorHTMLAttributes<HTMLAnchorElement>)
) & {
  active?: boolean;
}) {
  const className = classNames(
    "JobLogOutputComponent__Button",
    { "JobLogOutputComponent__Button--Active": active },
    props.className,
  );

  if ("href" in props) {
    return (
      <a {...props} className={className}>
        {children}
      </a>
    );
  }

  return (
    <button {...props} className={className}>
      {children}
    </button>
  );
}

export function LogBody({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames("JobLogOutputComponent__Body", className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function LogLoading({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames("JobLogOutputComponent__Loader", className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function LogMessage({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames("p3", className)} {...props}>
      {children}
    </div>
  );
}

export function LogContent({
  className,
  children,
  totalLines,
  ...props
}: React.HTMLAttributes<HTMLTableElement> & {
  totalLines?: number;
}) {
  const lineNumberWidth = totalLines?.toString().length ?? 0;

  const style = {
    "--line-number-width": `${Math.max(lineNumberWidth, 2)}ch`,
  } as React.CSSProperties;

  return (
    <table
      className={classNames("JobLogOutputComponent__Table", className)}
      style={style}
      {...props}
    >
      {children}
    </table>
  );
}

export function LogGroup({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <tbody className={className} {...props}>
      {children}
    </tbody>
  );
}

export const LogGroupHeader = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & {
    highlighted?: boolean;
    deemphasized?: boolean;
  }
>(({ className, highlighted, deemphasized, children, ...props }, ref) => {
  const innerRef = useScrollToHighlighted(highlighted);

  return (
    <tr
      ref={ref ?? innerRef}
      className={classNames(
        "JobLogOutputComponent__Header",
        {
          "JobLogOutputComponent__Header--Highlighted": highlighted,
          "JobLogOutputComponent__Header--Deemphasized": deemphasized,
        },
        className,
      )}
      {...props}
    >
      {children}
    </tr>
  );
});

export function LogGroupName({
  className,
  name,
  expanded,
  ...props
}: React.TdHTMLAttributes<HTMLTableCellElement> & {
  name: string;
  expanded?: boolean;
}) {
  return (
    <td
      className={classNames("JobLogOutputComponent__Header__Name", className)}
      {...props}
    >
      <span className="w-[2ch] float-left py-px -ml-1 mr-1">
        <Icon
          icon={
            expanded
              ? "heroicons/16/solid/chevron-down"
              : "heroicons/16/solid/chevron-right"
          }
          className="h-4 w-4 block"
        />
      </span>

      <span
        dangerouslySetInnerHTML={{
          __html: parseEmoji(name, { escape: false }),
        }}
      />
    </td>
  );
}

function useScrollToHighlighted(highlighted?: boolean) {
  const ref = React.useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (highlighted && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  return ref;
}

export const LogLine = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & { highlighted?: boolean }
>(({ className, highlighted, children, ...props }, ref) => {
  const innerRef = useScrollToHighlighted(highlighted);

  return (
    <tr
      ref={ref ?? innerRef}
      className={classNames(
        "JobLogOutputComponent__Line",
        { "JobLogOutputComponent__Line--Highlighted": highlighted },
        className,
      )}
      {...props}
    >
      {children}
    </tr>
  );
});

export function LogLineContent({
  className,
  children,
  ...props
}: React.TdHTMLAttributes<HTMLTableCellElement>) {
  return (
    <td
      className={classNames("JobLogOutputComponent__Line__Content", className)}
      {...props}
    >
      {children}
    </td>
  );
}

export function LogLineNumber({
  className,
  number,
  children,
  ...props
}: React.TdHTMLAttributes<HTMLTableCellElement> & {
  number: number;
}) {
  return (
    <td
      className={classNames(
        "JobLogOutputComponent__Number w-[--line-number-width]",
        className,
      )}
      data-line-number={number}
      {...props}
    >
      {children}
    </td>
  );
}

export function LogTimestamp({
  className,
  time,
  utc,
  ...props
}: React.TdHTMLAttributes<HTMLTableCellElement> & {
  time?: DateLike | null;
  utc?: boolean | null;
}) {
  let timestamp: string | null = null;

  if (time) {
    timestamp = utc
      ? `${getComputerDateString(moment.utc(time))} UTC`
      : `${getComputerDateString(moment(time))} ${getLocalTimezoneAbbr()}`;
  }
  return (
    <td
      className={classNames(
        "JobLogOutputComponent__Timestamp",
        { "JobLogOutputComponent__Timestamp--UTC": utc },
        className,
      )}
      {...props}
    >
      {timestamp}
    </td>
  );
}

export function LogGroupTime({
  startedAt,
  finishedAt,
  finished,
  durationPercentage,
  className,
  ...props
}: React.TdHTMLAttributes<HTMLTableCellElement> & {
  startedAt?: DateLike | null;
  finishedAt?: DateLike | null;
  finished: boolean;
  durationPercentage?: number | null;
}) {
  // Set the width of the badge to it's percentage of time taken to run
  // the group. We only bother doing this if the percentage is more than
  // 20% (anything lower is kinda insignificant)
  let badgeStyle = {};

  if (durationPercentage && durationPercentage > 20) {
    badgeStyle = { width: `${durationPercentage}%` };
  } else if (startedAt && !finishedAt) {
    badgeStyle = { width: "20px" };
  }

  // Only show the timing node if we have the full timing information. We
  // could do live countdowns, but because we only get new data every 5
  // seconds, the counter would go up to 6 seconds, we get new data, then it
  // drops to 2 seconds. So it looks funky town.
  return (
    <td
      className={classNames("JobLogOutputComponent__Time", className)}
      {...props}
    >
      {startedAt && (
        <span className="JobLogOutputComponent__Time__Badge" style={badgeStyle}>
          {finishedAt ? (
            <Duration.Short from={startedAt} to={finishedAt} />
          ) : finished ? null : (
            <LegacySpinner />
          )}
        </span>
      )}
    </td>
  );
}
export function LogCopyPermalink({
  onClick,
  visible,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  visible?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const [copied, setCopied] = React.useState(false);

  const handleClick = React.useCallback(
    (event) => {
      onClick(event);

      // Unrender the button for a frame so that the line number is un-hovered
      setCopied(true);

      window.requestAnimationFrame(() => setCopied(false));
    },
    [onClick],
  );

  if (copied) {
    return null;
  }

  return (
    <div
      className={classNames("JobLogOutputComponent__CopyPermalink", {
        "JobLogOutputComponent__CopyPermalink--Visible": visible,
      })}
      {...props}
    >
      <button
        type="button"
        title="Copy link to this line"
        onClick={handleClick}
        className="btn btn-depth btn-small bg-white w-8 h-8 p-0 flex items-center justify-center
          before:w-2 before:h-2 before:absolute before:top-3 before:-right-1 before:rounded-[0.5px] before:-rotate-45 before:-z-10 before:shadow-depth-150
          after:w-2 after:h-2 after:absolute after:top-3 after:-right-1 after:rounded-[0.5px] after:-rotate-45 after:bg-white"
      >
        <Icon icon="heroicons/16/solid/link" className="h-4 w-4" />
      </button>
    </div>
  );
}
