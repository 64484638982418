import classNames from "classnames";
import React from "react";

import Emojify from "app/components/shared/Emojify";
import Panel from "app/components/shared/Panel";

// Grab guides from the file system (at ../docs)
const GUIDES = ((guideRequire) =>
  guideRequire
    .keys()
    .map((guidePath: string) => guideRequire(guidePath).default))(
  require.context(
    "../docs",
    true,
    /^\.\/[^/]+(?:\/index)?\.[^/]*$/, // matches any file in ../../docs, or any index file in a subdirectory of ../../docs
  ),
);

const getEmojiForGuide = ({ emoji, title }: { emoji: string; title: string }) =>
  emoji || `:${title.toLowerCase()}:`;
const getSlugForGuide = ({ slug, title }: { slug: string; title: string }) =>
  slug || encodeURIComponent(title.toLowerCase());

interface Props {
  agentToken?: string;
  center?: boolean;
  organization: {
    uuid?: string;
    slug: string;
    name: string;
  };
  title: string;
}

interface State {
  slug: string | null;
}

class AgentQuickStart extends React.PureComponent<Props, State> {
  state = {
    slug: null,
  };

  static defaultProps = {
    center: true,
    title: "Agent Quick Start Guides",
  };

  getSlugFromHash() {
    const hash = this.state.slug || window.location.hash;

    if (hash.length < 2) {
      return null;
    }

    return hash.split("#setup-").pop() || null;
  }

  getIndexOfGuide() {
    const slugToFind = this.getSlugFromHash();

    if (!slugToFind) {
      return null;
    }

    const index = GUIDES.findIndex(
      (Guide) => getSlugForGuide(Guide) === slugToFind,
    );

    if (index === -1) {
      return null;
    }

    return index;
  }

  renderGuideButtons(selectedGuideIndex) {
    return (
      <div className="text-center" style={{ margin: -5 }}>
        {GUIDES.map((Guide, index) => {
          const slug = getSlugForGuide(Guide);

          return (
            <a
              key={slug}
              onClick={(event) => {
                event.preventDefault();
                if (index === selectedGuideIndex) {
                  window.location.hash = "";
                  this.setState({ slug: "" });
                } else {
                  window.location.hash = "#setup-" + slug;
                  this.setState({ slug: slug });
                }
              }}
              className={classNames(
                "inline-block semi-bold cursor-pointer black hover-lime text-decoration-none border rounded m1 p1",
                {
                  "border-white": index !== selectedGuideIndex,
                  "border-gray": index === selectedGuideIndex,
                },
              )}
            >
              <Emojify
                className="block mt1"
                style={{ fontSize: "1.15em" }}
                text={getEmojiForGuide(Guide)}
              />
              {Guide.title}
            </a>
          );
        })}
      </div>
    );
  }

  renderGuide(selectedGuideIndex) {
    const GuideToRender = GUIDES[selectedGuideIndex];
    const {
      organization: { name, slug, uuid },
      agentToken,
    } = this.props;

    if (GuideToRender) {
      return (
        <GuideToRender
          className="border border-gray mt3"
          style={{
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none",
          }}
          token={agentToken}
          organization={{ name, slug, uuid }}
        />
      );
    }
  }

  render() {
    const selectedGuideIndex = this.getIndexOfGuide();
    const headerClassName = classNames({ "text-center": this.props.center });

    return (
      <Panel className="mb3">
        <Panel.Header className={headerClassName}>
          {this.props.title}
        </Panel.Header>
        <Panel.Section>
          {this.renderGuideButtons(selectedGuideIndex)}
          {this.renderGuide(selectedGuideIndex)}
        </Panel.Section>
      </Panel>
    );
  }
}

export default AgentQuickStart;
