import * as React from "react";
import classNames from "classnames";

import parseEmoji from "app/lib/parseEmoji";

import Icon from "app/components/shared/Icon";

import StepName from "./StepName";

import {
  StepID,
  StepReorderPosition,
  Step,
} from "app/stores/ProjectPipelineStore";

// NOTE: The state of all StepPreview components is managed by this shared variable reference.
let SHARED_DRAG_STATE:
  | {
      draggingElement: HTMLElement | null | undefined;
      draggingId: StepID;
      hoveringElement?: HTMLElement;
      position?: StepReorderPosition;
    }
  | null
  | undefined;

type Props = {
  step: Step;
  onClick: (step: Step) => unknown;
  onSort: (
    draggingId: StepID,
    stepId: StepID,
    position?: StepReorderPosition | null | undefined,
  ) => unknown;
};

type State = {
  dragging: boolean;
  hovering: boolean;
};

/* eslint-disable react/require-optimization */
// TODO: Fix the architecture of these components so this one can be optimised.
// The problem is the `step` object is actually passed from the store, which is always
// the same object reference, even if mutated, so ShallowCompare functions do not work.

export default class StepPreview extends React.Component<Props, State> {
  wrapperNode: HTMLDivElement | null | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      dragging: false,
      hovering: false,
    };
  }

  renderManualPipelineStepName() {
    const nameWithEmoji = parseEmoji(this.props.step.name || "Continue");

    return (
      <span className="step-preview-type-manual-name">
        <span dangerouslySetInnerHTML={{ __html: nameWithEmoji }} />
      </span>
    );
  }

  renderContentsNode() {
    switch (this.props.step.type) {
      case "script":
        return <StepName step={this.props.step} />;
      case "manual":
        return (
          <span>
            <span className="build-pipeline-job__icon">
              <Icon icon="heroicons/outline/lock-closed" className="h-3 w-3" />
            </span>
            {this.renderManualPipelineStepName()}
          </span>
        );
      case "waiter":
        return (
          <Icon
            icon="chevron-right"
            style={{ height: 15, width: 15, top: -1 }}
            className="relative dark-gray"
          />
        );
      case "trigger":
        return <StepName step={this.props.step} />;
    }
  }

  render() {
    return (
      <div
        data-step-id={this.props.step.id}
        className={classNames({
          [`step-preview-type-${
            this.props.step.type
              ? this.props.step.type.replace(/_/g, "-")
              : "error"
          }`]: this.props.step.type,
          // Only add the hovering class if our mouse is over it, and we're not currently dragging on top of it
          "step-preview": true,
          "step-preview-selected": this.props.step.selected,
          "step-preview-dragging": this.state.dragging,
          "step-preview-hover": this.state.hovering && !SHARED_DRAG_STATE,
        })}
        draggable={true}
        onDragStart={this.handleDragStart}
        onDragEnd={this.handleDragEnd}
        onDragOver={this.handleDragOver}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onClick={this.handleClick}
        ref={(wrapperNode) => (this.wrapperNode = wrapperNode)}
      >
        <div
          className="step-preview-inner truncate align-middle"
          style={{ maxWidth: "15em" }}
        >
          {this.renderContentsNode()}
        </div>
      </div>
    );
  }

  calculatePosition(evt: React.MouseEvent<HTMLElement>) {
    // Find out relative co-ords of the mouse on the element
    // const relY = evt.clientY - evt.currentTarget.getBoundingClientRect().top;
    const relX = evt.clientX - evt.currentTarget.getBoundingClientRect().left;

    // Figure out if we're hovering over the left or the right hand
    // side of the element.
    const width = evt.currentTarget.offsetWidth;

    if (relX > width / 2) {
      return "after";
    }

    return "before";
  }

  handleClick = () => {
    return this.props.onClick(this.props.step);
  };

  handleDragStart = (evt: React.DragEvent<HTMLDivElement>) => {
    if (!evt.dataTransfer) {
      return;
    }

    evt.dataTransfer.effectAllowed = "move";

    // A super lazy global variable
    SHARED_DRAG_STATE = {
      draggingElement: this.wrapperNode,
      draggingId: this.props.step.id,
    };

    // We're transitioning to a drag, so don't hover anymore
    this.setState({
      hovering: false,
    });

    if (!evt.dataTransfer) {
      return;
    }

    // If we don't set some data, Firefox freaks out and doesn't drag. Stupid
    // firefox.
    try {
      // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'string'.
      evt.dataTransfer.setData("text/html", null);
    } catch (ex: any) {
      evt.dataTransfer.setData("text", "");
    }

    // Let the drag event start, then change the state of the node
    // to be dragging.
    return setTimeout(() => {
      // If we're still dragging
      if (SHARED_DRAG_STATE) {
        this.setState({
          dragging: true,
        });
      }
    }, 100);
  };

  handleDragEnd = () => {
    // Reset out global drag state var
    SHARED_DRAG_STATE = null;

    // Change our state back to not-dragging
    this.setState({
      dragging: false,
    });
  };

  // onDragOver is called from the _other_ element when an element is dragged on top
  // of it.
  handleDragOver = (evt: React.MouseEvent<HTMLDivElement>) => {
    // Necessary. Allows us to drop.
    evt.preventDefault();

    // If theres no dragging element, just bail.
    if (!SHARED_DRAG_STATE) {
      return;
    }

    const hoveringElement = evt.currentTarget;

    // If the element being hovered over, is also the dragging element, bail.
    if (SHARED_DRAG_STATE.draggingElement === hoveringElement) {
      return;
    }

    // Grab the element we're hovering over, and calculate the position
    const position = this.calculatePosition(evt);

    // If we're already hovering over this element, and the position hasn't changed,
    // then no need to trigger to call @props.onSort again
    if (
      !SHARED_DRAG_STATE ||
      (SHARED_DRAG_STATE.hoveringElement === hoveringElement &&
        SHARED_DRAG_STATE.position === position)
    ) {
      return;
    }

    // Update the global drag state with the new data
    SHARED_DRAG_STATE.hoveringElement = hoveringElement;
    SHARED_DRAG_STATE.position = position;

    // Trigger the onSort event
    this.props.onSort(
      SHARED_DRAG_STATE.draggingId,
      this.props.step.id,
      position,
    );
  };

  handleMouseOver = () => {
    this.setState({
      hovering: true,
    });
  };

  handleMouseOut = () => {
    this.setState({
      hovering: false,
    });
  };
}
