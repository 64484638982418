import { useState, useCallback, useEffect } from "react";
import useFetch, { Res } from "use-http";

import FriendlyTime from "app/components/shared/FriendlyTime";
import Emojify from "app/components/shared/Emojify";
import { StyledUnderline } from "../../shared/styles";
import { FlakyTest, Team } from "../../shared/type";
import ManageFlakyDropdown, { FormFields } from "../ManageFlakyDropdown";
import { NoTeamsAvailableProps } from "../ManageFlakyDropdown/NoTeamsAvailable";
import TestStateBadge from "../Table/TestStateBadge";
import TestLocation from "../../shared/Metadata/TestLocation";

export type FlakyRowProps = NoTeamsAvailableProps & {
  currentAccountMember?: boolean;
  teams: Team[];
  refreshAssignments?: () => Promise<void>;
  isFlaky?: boolean;
};

type Props = FlakyRowProps & {
  flakyTest: FlakyTest;
};

const FlakyTestRow = ({
  flakyTest,
  currentAccountMember,
  teams,
  isAdmin,
  flakyTestAssignmentFeatureAvailable = false,
  billingUrl,
  manageTeamsUrl,
  refreshAssignments,
  isFlaky = true,
}: Props) => {
  const [assignedTeam, setAssignedTeam] = useState<FlakyTest["assigned_team"]>(
    flakyTest.assigned_team,
  );
  const [resolved, setResolved] = useState(flakyTest.resolved);
  const [reoccurring, setReoccurring] = useState(flakyTest.reoccurring);

  const fetchData = useFetch({
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
    },
  });

  const manageResponse = useCallback(
    (response: Res<any>, assignedTeam: FlakyTest["assigned_team"]) => {
      if (response.ok) {
        if (refreshAssignments) {
          refreshAssignments();
        } else {
          setAssignedTeam(assignedTeam);
        }
      }

      return !!response.ok;
    },
    [],
  );

  const handleRemoveAssignment = useCallback(
    async (assignment: FlakyTest["assigned_team"]) => {
      if (!assignment?.id) {
        return false;
      }

      await fetchData.delete(
        `${flakyTest.test_assignment_endpoint}/${flakyTest.test_id}?team_id=${assignment.id}`,
      );

      return manageResponse(fetchData.response, undefined);
    },
    [flakyTest],
  );

  const handleCreateAssignment = useCallback(
    async (data: FormFields) => {
      await fetchData.post(
        `${flakyTest.test_assignment_endpoint}?test_uuid=${flakyTest.test_id}&team_id=${data.teamId}`,
      );

      return manageResponse(fetchData.response, fetchData.response.data);
    },
    [flakyTest],
  );

  const handleResolveTest = useCallback(async () => {
    await fetchData.post(
      `${flakyTest.test_resolution_endpoint}?test_uuid=${flakyTest.test_id}`,
    );

    const response = manageResponse(fetchData.response, undefined);

    if (response) {
      setResolved(true);
      setReoccurring(false);
    }

    return response;
  }, [flakyTest]);

  const showAssignmentDropdown = currentAccountMember;

  useEffect(() => {
    setAssignedTeam(flakyTest.assigned_team);
  }, [flakyTest.assigned_team]);

  return (
    <li className="row-link__container">
      <div className="ta-panel-row grid-cols-[auto_7rem_7rem_7rem] lg:grid-cols-[auto_7rem_14rem_17rem] ">
        <div className="main-section">
          <div className="flex gap-1">
            {assignedTeam?.name && flakyTestAssignmentFeatureAvailable && (
              <span className="badge bg-light-purple purple semi-bold">
                <Emojify text={`Assigned to ${assignedTeam.name}`} />
              </span>
            )}

            {resolved && (
              <span className="badge bg-blue-100 blue-500 semi-bold">
                Flaky behaviour marked as resolved
              </span>
            )}

            {reoccurring && (
              <span className="badge bg-red-100 red-700 semi-bold">
                Reoccurring Flaky
              </span>
            )}

            {flakyTest.state && <TestStateBadge state={flakyTest.state} />}
          </div>

          <a
            href={flakyTest.test_path}
            className="block font-medium text-inherit no-underline hover:underline focus:underline truncate"
          >
            {flakyTest.description}
          </a>

          <TestLocation
            location={flakyTest.location}
            locationUrl={flakyTest.location_url}
          />
        </div>

        {isFlaky && (
          <>
            <div className="text-center">
              {flakyTest.instances}
              <span className="hide"> flaky instances</span>
            </div>

            <div className="relative z1">
              <span className="hide">First occurrence</span>
              <StyledUnderline
                as={FriendlyTime}
                value={flakyTest.first_occurrence_at}
              />
            </div>
          </>
        )}

        {!isFlaky && (
          <>
            <div /> {/* Empty column to help alignment*/}
            <div className="relative z1">
              <span className="hide">Assigned at</span>
              {/* @ts-expect-error - TS2769 - No overload matches this call. */}
              <StyledUnderline
                as={FriendlyTime}
                value={assignedTeam?.created_at}
              />
            </div>
          </>
        )}

        <div className="flex justify-between items-center">
          <div className="relative z1">
            <span className="hide">Latest instance at</span>
            <StyledUnderline
              as={FriendlyTime}
              value={flakyTest.latest_occurrence_at}
            />
          </div>

          <div className="relative -right-4">
            {showAssignmentDropdown && (
              <ManageFlakyDropdown
                teams={teams}
                assignment={assignedTeam}
                onCreateAssignment={handleCreateAssignment}
                onRemoveAssignment={handleRemoveAssignment}
                onResolveTest={handleResolveTest}
                resolved={resolved}
                flakyTestAssignmentFeatureAvailable={
                  flakyTestAssignmentFeatureAvailable
                }
                manageTeamsUrl={manageTeamsUrl}
                billingUrl={billingUrl}
                isAdmin={isAdmin}
              />
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default FlakyTestRow;
