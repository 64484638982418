import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fromPairs, get, map, pipe, toPairs, values } from "lodash/fp";
import classNames from "classnames";

import Dropdown from "app/components/shared/Dropdown";
import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";
import Indicator from "app/components/shared/Indicator";
import cable from "app/lib/cable";
import { CommandStep } from "app/lib/pipeline";
import { Current } from "app/utils/Current";
import buildy from "app/images/buildy-animated.gif";

import { useTestEngineStore, TestPlan } from "./useTestEngineStore";

interface Props {
  buildId: string;
  steps: CommandStep[];
}

const testPlanResultSelector = pipe(get("testPlans"), values);

const BktecBadge = ({ buildId, steps }: Props) => {
  const setTestPlan = useTestEngineStore((state) => state.setTestPlan);
  const testPlans = useTestEngineStore(testPlanResultSelector);

  const labels = useMemo(
    () =>
      pipe(
        map((step: CommandStep) => [
          step.uuid,
          step.label || step.commandScript,
        ]),
        fromPairs,
      )(steps),
    [steps],
  );

  const [opened, setOpened] = useState(false);
  const hasResults = testPlans.length > 0;

  useEffect(() => {
    const subscription = cable.subscriptions.create(
      {
        channel: "TestEngine::TestPlanChannel",
        uuid: buildId,
        organization_uuid: Current.organization.uuid,
      },
      {
        received({ testPlan }) {
          setTestPlan(testPlan);
        },
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [buildId, setTestPlan]);

  const handleClick = useCallback(() => {
    setOpened(true);
  }, []);

  return (
    <Dropdown width={300} className="inline-block" offsetY={5} nibOffsetX={22}>
      <button
        onClick={handleClick}
        className={classNames(
          "relative badge border rounded-lg flex gap-2 items-center font-mono [box-shadow:0_3px_0_0_var(--tw-shadow-color)] active:translate-y-0.5 active:[box-shadow:0_0.125rem_0_0_var(--tw-shadow-color)] transition-all duration-200",
          { "bg-slate-300 border-slate-500 shadow-slate-500": !hasResults },
          { "bg-purple-100 border-purple-600 shadow-purple-600 ": hasResults },
        )}
      >
        bktec
        <Icon icon="down-triangle" style={{ width: 7, height: 7 }} />
        {hasResults && !opened && (
          <Indicator className="absolute left-full bottom-full" />
        )}
      </button>
      <div className="p-3 flex flex-col gap-3">
        {hasResults ? (
          map(
            (testPlan) => (
              <details
                key={testPlan.id}
                name="test-plan-result"
                className="flex flex-col bg-purple-100 border border-purple-600 rounded-lg shadow-purple-600 [box-shadow:0_3px_0_0_var(--tw-shadow-color)]"
              >
                <summary className="cursor-pointer p-3">
                  <Emojify text={get(get("stepId", testPlan), labels)} />
                </summary>
                <div className="px-3">
                  <ResultTable testPlan={testPlan} />
                </div>
              </details>
            ),
            testPlans,
          )
        ) : (
          <>
            <img src={buildy} className="inline" />
            <div className="font-mono text-sm text-gray-700 text-center">
              Results coming soon
            </div>
          </>
        )}
      </div>
    </Dropdown>
  );
};

const ResultTable = ({ testPlan }: { testPlan: TestPlan }) => {
  const result = testPlan.statistics.results;
  return (
    <div className="font-mono py-3 border-t border-purple-300">
      <table className="table-auto w-full leading-relaxed">
        <tbody>
          <tr>
            <td rowSpan={2} className="content-baseline">
              Passed
            </td>
            <td>on first run</td>
            <td>{result.passed_on_first_run}</td>
          </tr>
          <tr>
            <td>on retry</td>
            <td>{result.passed_on_retry}</td>
          </tr>
          <tr>
            <td rowSpan={2} className="content-baseline">
              Muted
            </td>
            <td>passed</td>
            <td>{result.muted_passed}</td>
          </tr>
          <tr>
            <td>failed</td>
            <td>{result.muted_failed}</td>
          </tr>
          <tr>
            <td colSpan={2}>Failed</td>
            <td>{result.failed}</td>
          </tr>
          <tr>
            <td colSpan={2}>Skipped</td>
            <td>{result.skipped}</td>
          </tr>
          <tr className="border-t pt-2 border-purple-300 font-bold">
            <td colSpan={2}>Total</td>
            <td>{result.total}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BktecBadge;
