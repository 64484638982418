import * as React from "react";

import Icon from "app/components/shared/Icon";

type Props = {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  expanded: boolean;
  className?: string;
};

export default class RowAccordion extends React.PureComponent<Props> {
  static displayName = "Panel.RowAccordion";

  render() {
    const baseStyles = {
      width: "100%",
    } as const;

    const expandedStyles = {
      ...baseStyles,
      margin: 0,
      backgroundColor: "var(--slate-100)",
      fontWeight: 500,
      height: "60px",
    } as const;

    let className =
      "btn text-left block flex items-center py2 px3 hover-bg-silver hover-black focus-black";

    if (this.props.className !== undefined) {
      className += " " + this.props.className;
    }

    return (
      <button
        // @ts-expect-error - TS2322 - Type '((event: MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | undefined' is not assignable to type 'MouseEventHandler<HTMLButtonElement> | undefined'.
        onClick={this.props.onClick}
        className={className}
        style={this.props.expanded ? expandedStyles : baseStyles}
      >
        {this.props.children}
        <Icon
          icon="chevron-down"
          className="flex-none dark-gray"
          style={{ height: 15, width: 15 }}
        />
      </button>
    );
  }
}
