/* eslint-disable id-length */
/* eslint-disable react/jsx-no-bind */
import { Job, useBuild } from "app/components/Playground/BuildContext";
import * as TableCell from "./TableCell";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { useBuildPreferencesStore } from "../../lib/useBuildPreferencesStore";
import Icon from "app/components/shared/Icon";
import { filterJobs, useFilterStore } from "../Sidebar/useFilterStore";
import { useRef } from "react";
import { StepRouteParams } from "../..";

interface Column {
  key: string;
  label: string;
  component: (props: { job: Job }) => React.ReactNode;
  sortFn?: TableCell.SortFn;
  className?: string;
}

const columns: Column[] = [
  {
    key: "state",
    label: "State",
    component: TableCell.State,
    className: "w-0",
    sortFn: TableCell.sortByState,
  },
  {
    key: "label",
    label: "Label",
    component: TableCell.Label,
    sortFn: TableCell.sortByLabel,
  },
  {
    key: "command",
    label: "Command",
    component: TableCell.Command,
    sortFn: TableCell.sortByCommand,
  },
  {
    key: "queue",
    label: "Queue",
    component: TableCell.Queue,
    sortFn: TableCell.sortByQueue,
  },
  {
    key: "agent",
    label: "Agent",
    component: TableCell.Agent,
    sortFn: TableCell.sortByAgent,
  },
  {
    key: "wait_duration",
    label: "Wait time",
    component: TableCell.WaitDuration,
    sortFn: TableCell.sortByWaitDuration,
  },
  {
    key: "run_duration",
    label: "Run time",
    component: TableCell.RunDuration,
    sortFn: TableCell.sortByRunDuration,
  },
  {
    key: "start_time",
    label: "Started at",
    component: TableCell.StartTime,
    sortFn: TableCell.sortByStartTime,
  },
];

const useTableSorting = () => {
  const sortDirection = useBuildPreferencesStore(
    (state) => state.sortDirection,
  );
  const setSortDirection = useBuildPreferencesStore(
    (state) => state.setSortDirection,
  );

  const sortColumn = useBuildPreferencesStore((state) => state.sortColumn);
  const setSortColumn = useBuildPreferencesStore(
    (state) => state.setSortColumn,
  );

  const clickCountRef = useRef(0);

  const setSort = (column: string) => {
    if (column === sortColumn) {
      clickCountRef.current += 1;

      // Reset sorting if the same column is clicked twice
      if (clickCountRef.current > 1) {
        setSortColumn(null);
        setSortDirection(null);
        clickCountRef.current = 0;
      } else {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      }
    } else {
      clickCountRef.current = 0;
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  return { sortColumn, sortDirection, setSort };
};

export function TableView() {
  const { build } = useBuild();
  const { sortColumn, sortDirection, setSort } = useTableSorting();
  const filter = useFilterStore((state) => state.filterBy);
  const { stepOrJobId } = useParams<StepRouteParams>();

  // Filter to executable jobs
  let jobs =
    build?.jobs.filter(
      (job) =>
        job.state !== "broken" &&
        (job.type === "script" || job.type === "trigger"),
    ) || [];

  // Filter jobs based on the current filter
  jobs = filterJobs(jobs, filter);

  // Sort jobs by the current sort column/direction
  const column = columns.find((c) => c.key === sortColumn);
  if (column?.sortFn && sortDirection) {
    jobs.sort(column.sortFn(sortDirection));
  }

  return (
    <table className="w-full h-max" data-testid="build-table">
      <thead>
        <tr className="sticky top-0 bg-white bg-opacity-95 z-10 [&>th:first-of-type]:pl-2 [&>th:last-of-type]:pr-4">
          {columns.map((column) => (
            <th key={column.key} className="border-b border-gray-400">
              <span
                onClick={column.sortFn ? () => setSort(column.key) : undefined}
                className={classNames(
                  "flex gap-1 items-center select-none p-2 font-medium",
                  {
                    "hover:underline cursor-pointer": column.sortFn,
                    underline: column.key === sortColumn,
                  },
                )}
              >
                <span className="truncate">{column.label}</span>

                {column.key === sortColumn && sortDirection === "desc" && (
                  <Icon
                    icon="heroicons/outline/arrow-down"
                    className="w-2 h-2"
                  />
                )}
                {column.key === sortColumn && sortDirection === "asc" && (
                  <Icon icon="heroicons/outline/arrow-up" className="w-2 h-2" />
                )}
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {jobs.map((job) => (
          <Link
            key={job.id}
            draggable={false}
            id={`job-${job.id}`}
            to={
              job.type === "trigger"
                ? `${build?.path}/table/${job.stepUuid}`
                : `${build?.path}/table/${job.id}`
            }
            className={twMerge(
              "select-text",
              "table-row bg-gray-100 bg-opacity-25 hover:bg-opacity-100 border-b border-gray-400",
              "text-inherit hover:text-inherit focus:text-inherit hover:no-underline focus:no-underline",
              "[&>td:first-of-type]:pl-4 [&>td:last-of-type]:pr-4",
              classNames({
                "bg-opacity-100":
                  stepOrJobId === job.id || stepOrJobId === job.stepUuid,
              }),
            )}
          >
            {columns.map((column) => (
              <td
                className={twMerge(
                  "p-2 truncate max-w-0 align-middle",
                  column.className,
                )}
                key={column.key}
              >
                {column.component({ job }) || "--"}
              </td>
            ))}
          </Link>
        ))}
      </tbody>
    </table>
  );
}
