import debounce from "lodash/debounce";
import { v4 as uuid } from "uuid";
import { type BuildStates } from "app/constants/BuildStates";
import cable from "app/lib/cable";
import Database from "app/lib/Database";
import updateFavicon, {
  type BuildState,
  type FaviconPaths,
} from "app/lib/Favicon";
import performanceNow from "app/lib/performanceNow";
import { Step } from "app/lib/pipeline";
import BaseStore from "app/stores/BaseStore";
import { type Job } from "app/components/build/Header/pipeline/types/Job";

export { type Job };

export interface Build {
  triggeredFrom?: {
    uuid: string;
    url: string;
    name: string;
    project: {
      name: string;
    };
    build: {
      number: number;
    };
  };
  pipelineSchedule?: {
    url: string;
    label: string;
  };
  creatorVerified: boolean;
  cancelPath: string;
  canRetryFailedJobs: boolean;
  canRetryFailedTriggeredJobs: boolean;
  nextBranchBuild?: {
    url: string;
    number: number;
    state: BuildStates;
  };
  prevBranchBuild?: {
    url: string;
    number: number;
    state: BuildStates;
  };
  authorName?: string;
  authorAvatar?: string;
  id: string;
  jsonPath: string;
  message?: string;
  number: number;
  account: {
    slug: string;
    name: string;
    jobMinuteLimitReached: boolean;
  };
  project: {
    id: string;
    name: string;
    slug: string;
    description?: string;
    url: string;
    public: boolean;
    archived: boolean;
    provider: {
      id: string;
      url: string;
      frontendIcon: string;
    };
    exampleRepository: boolean;
    emoji?: string;
    color?: string;
    allowRebuilds: boolean;
    permissions: {
      edit?: {
        allowed: boolean;
      };
    };
  };
  steps: Step[];
  jobs: Job[];
  state: BuildStates;
  cancelReason?: string;
  blockedState?: string;
  createdAt: string;
  canceledAt?: string;
  cancelledBy?: {
    name: string;
    avatar: string;
  };
  startedAt?: string;
  scheduledAt?: string;
  finishedAt?: string;
  source: string;
  pullRequest?: {
    id: string;
    url: string;
  };
  branchName: string;
  branchPath: string;
  commitId: string;
  commitUrl?: string;
  commitShortLength: number;
  jobsCount: number;
  totalJobDuration: number;
  annotationCountsByStyle: {
    [key: string]: number;
  };
  path: string;
  dispatchPausedClusterQueues: {
    key: string;
    path: string;
    dispatchPausedAt: string;
    dispatchPausedBy: {
      name: string;
      avatar: {
        url: string;
      };
    };
    dispatchPausedNote?: string | null;
  }[];
  duration?: {
    total: number;
    running: number;
    runningRetries: number;
    idle: number;
  };
  retry_counts?: {
    total: number;
    manual: number;
    automatic: number;
  };
  jobsRunning?: boolean;
  jobsIdle?: boolean;
  retriesRunning?: boolean;
  hasTestAnalytics?: boolean;
  hasBktecBadge?: boolean;
  permissions: {
    retry: {
      allowed: boolean;
      reason?: string;
      message?: string;
    };
    rebuild: {
      allowed: boolean;
      reason?: string;
      message?: string;
    };
    cancel: {
      allowed: boolean;
      reason?: string;
      message?: string;
    };
  };
  rebuildPath: string;
  rebuildBranchPath: string;
  newPath: string;
}

export default class BuildShowStore extends BaseStore {
  uuid: string = uuid();
  oldestReloadRequestTime: number | null | undefined = null;
  reloadInProgress = false;

  // A map of step UUIDs to step objects for easy lookup
  steps = new Map<string, Step>();

  // A map of step UUIDs to job objects for easy lookup
  stepJobs = new Map<string, Job[]>();

  // A map of job IDs to job objects for easy lookup
  jobs = new Map<string, Job>();

  build: Build;
  faviconPaths: FaviconPaths;
  waterfallAvailable: boolean;
  isImpersonating: boolean;

  constructor({
    build: rawBuild,
    faviconPaths,
    waterfallAvailable = false,
    isImpersonating = false,
  }: {
    build: Build;
    faviconPaths: FaviconPaths;
    waterfallAvailable: boolean;
    isImpersonating: boolean;
  }) {
    // Call super on the parent store
    super();

    const build: Build = Database.parse(rawBuild);
    this.build = build;

    this.steps = new Map(build.steps.map((step) => [step.uuid, step]));
    this.stepJobs = new Map();
    this.jobs = new Map();

    build.jobs.forEach((job) => {
      this.stepJobs.get(job.stepUuid)?.push(job) ||
        this.stepJobs.set(job.stepUuid, [job]);

      this.jobs.set(job.id, job);
    });

    this.faviconPaths = faviconPaths;
    this.waterfallAvailable = waterfallAvailable;
    this.isImpersonating = isImpersonating;

    // When we receive a build update, update the build and emit an event.
    // @ts-expect-error - TS2339 - Property 'buildSubscription' does not exist on type 'BuildShowStore'.
    this.buildSubscription = cable.subscriptions.create(
      { channel: "Pipelines::BuildChannel", uuid: this.build.id },
      {
        store: this,
        number: this.build.number,

        received({ event }) {
          if (
            // Generically changed
            event === "updated" ||
            // Build state transitions which change appearance
            event === "started" ||
            event === "finished" ||
            event === "skipped" ||
            event === "canceling" ||
            // Happens when the commit is resolved from HEAD to a sha
            event === "commit:changed" ||
            // The steps have changed, maybe a pipeline upload or a step has
            // changed state, and we might need to re-render the build pipeline
            event === "steps:changed" ||
            // An annotation has been created, updated or removed
            event === "annotations:changed"
          ) {
            this.store.reload({
              source: this.store.uuid,
              trigger: `event:${event}`,
            });
          }
        },
      },
    );

    this._updateFavicon();

    // Kick off an initial reload
    this.reload({ source: this.uuid, trigger: "initial" });
  }

  getBuild() {
    return this.build;
  }

  setBuild(build: Build) {
    this.build = build;

    this.steps = new Map(build.steps.map((step) => [step.uuid, step]));
    this.stepJobs = new Map();
    this.jobs = new Map();

    build.jobs.forEach((job) => {
      this.stepJobs.get(job.stepUuid)?.push(job) ||
        this.stepJobs.set(job.stepUuid, [job]);

      this.jobs.set(job.id, job);
    });

    this.emit("change");
    this._updateFavicon();
  }

  loadAndEmit(build: Build) {
    this.setBuild(Database.parse(build));
  }

  reload(data: any | null = {}) {
    if (this.oldestReloadRequestTime == null) {
      this.oldestReloadRequestTime = performanceNow();
    }

    this._debouncedReload(data);
  }

  // Each reload attempt defers the actual function by 1000 ms
  // A continuous stream of attempts would mean the function is never called.
  // maxWait puts an upper bound of 4000 ms on that.
  _debouncedReload = debounce(
    (data: any | null = {}) => {
      this._serialReload(data);
    },
    1000,
    { maxWait: 4000 },
  );

  _serialReload(data: any | null = {}) {
    if (this.reloadInProgress) {
      // A reload request is still in progress, so we need to defer this reload
      // again - we can do so by sending it back to the debounced method:
      this._debouncedReload(data);
    } else {
      this.reloadInProgress = true;

      this._performReload(data).always(() => {
        this.reloadInProgress = false;
      });
    }
  }

  _performReload(data: any | null = {}) {
    let delay = 0;
    if (this.oldestReloadRequestTime != null) {
      // Compute the number of milliseconds that have passed since the oldest
      // attempt to trigger a reload was made. This way we can tell how much
      // delay there was between an event that should trigger a reload and the
      // reload actually being attempted.
      //
      // This is just the delay to trigger the reload, the total delay perceived
      // by the user will be this plus the time it takes for the reload request
      // to complete.
      delay = Math.round(
        performanceNow() - (this.oldestReloadRequestTime || 0),
      );
    }

    const params = { ...data, delay } as const;

    // Clear oldest reload request time, so that the next reload attempt will
    // set it:
    this.oldestReloadRequestTime = null;

    return jQuery.ajax({
      url: this.build.jsonPath,
      data: params,
      headers: {
        "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
      } as {
        [key: string]: string;
      },
      success: (build) => {
        this.setBuild(Database.parse(build) as Build);
      },
    });
  }

  private readonly STATE_MAPPINGS: Record<string, BuildState> = {
    failed: "failed",
    failing: "failed",
    canceled: "failed",
    canceling: "failed",
    not_run: "failed",
    passed: "passed",
    started: "started",
    blocked: "blocked",
  } as const;

  private readonly BLOCKED_STATE_MAPPINGS: Record<string, BuildState> = {
    failed: "failed",
    running: "started",
    passed: "passed",
    blocked: "blocked",
  } as const;

  private _faviconKey(state: string, blockedState?: string | null): BuildState {
    if (state === "blocked" && blockedState) {
      return this.BLOCKED_STATE_MAPPINGS[blockedState] ?? "passed";
    }

    return this.STATE_MAPPINGS[state] ?? "scheduled";
  }

  private _updateFavicon(): void {
    const state = this._faviconKey(this.build.state, this.build.blockedState);
    updateFavicon(this.faviconPaths, state, this.isImpersonating);
  }
}
