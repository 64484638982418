import { Step } from "app/lib/pipeline";
import { twMerge } from "tailwind-merge";
import { StepLink } from "./StepLink";
import { Label } from "./Label";
import { StateIcon } from "app/components/Playground/Node/BaseNode/StateIcon";
import { textColorForStep } from "app/components/Playground/Node/colors";
import Emojify from "app/components/shared/Emojify";
import { useMostRecentJob } from "app/components/Playground/BuildContext";

/**
 * A catch-all step list item.
 */
export function StepListItem({
  step,
  onClick,
  className,
}: {
  step: Step;
  onClick?: () => void;
  className?: string;
}) {
  const job = useMostRecentJob(step.uuid);
  const waitingOnConcurrencyGroup = job?.state === "limited";

  return (
    <StepLink
      uuid={step.uuid}
      onClick={onClick}
      className={twMerge("rounded-md hover:bg-purple-100 pl-2", className)}
      type={step.type}
    >
      <Label
        command={step.type === "command" ? step.commandScript : undefined}
        status={
          <StateIcon
            className={twMerge(
              "w-7",
              textColorForStep({
                ...step,
                waitingOnConcurrencyGroup,
              }),
            )}
            waitingOnConcurrencyGroup={waitingOnConcurrencyGroup}
            {...step}
          />
        }
      >
        {step.label && <Emojify text={step.label} />}
      </Label>
    </StepLink>
  );
}
