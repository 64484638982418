import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import groupBy from "lodash/groupBy";
import {
  Step,
  State as StepState,
  isFailed,
  isPassed,
  isRunning,
  isScheduled,
  isSoftFailed,
  isWaiting,
} from "app/lib/pipeline";
import { JobStates } from "app/components/build/Header/pipeline/types/jobStates";
import { BuildStates, isTerminalBuildState } from "app/constants/BuildStates";
import { Job } from "app/stores/BuildShowStore";
import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";
import { TriggerJob } from "app/components/build/Header/pipeline/types/TriggerJob";
import { SortFn } from "../TableView/TableCell";

export enum GroupByOption {
  State = "state",
}

export enum FilterByOption {
  Failed = "failed",
  Passed = "passed",
  Running = "running",
  Scheduled = "scheduled",
  SoftFailed = "soft-failed",
  Waiting = "waiting",
}

export const StateOrder = [
  FilterByOption.Failed,
  FilterByOption.Running,
  FilterByOption.Scheduled,
  FilterByOption.Waiting,
  FilterByOption.SoftFailed,
  FilterByOption.Passed,
];

// Essentially the same as JobStates except with the finished state resolved to either: passed, hard_failed, and soft_failed
// to allow for sorting by state.
export type ResolvedJobState =
  | Exclude<JobStates, "finished">
  | "hard_failed"
  | "soft_failed"
  | "passed";

export const JobStateOrder: ResolvedJobState[] = [
  // Failed
  "hard_failed",
  "canceled",
  "canceling",
  "timed_out",
  "expired",

  // Running
  "running",

  // Scheduled
  "accepted",
  "assigned",
  "scheduled",
  "limited",
  "limiting",

  // Waiting
  "waiting",
  "blocked",

  // Soft failed
  "soft_failed",

  // Passed
  "passed",
  "unblocked",

  // Initial states (pending, skipped, broken)
  "pending",
  "skipped",
  "broken",
];

/**
 * Sort jobs by state order
 * @param a
 * @param b
 * @returns sorted jobs
 */
// eslint-disable-next-line id-length
export const sortJobsByState: SortFn = (direction) => (a, b) => {
  const aState = resolveJobState(a as CommandJob | TriggerJob);
  const bState = resolveJobState(b as CommandJob | TriggerJob);
  const aIndex = JobStateOrder.indexOf(aState);
  const bIndex = JobStateOrder.indexOf(bState);

  if (aIndex === bIndex) {
    return 0;
  }

  if (direction === "asc") {
    return aIndex - bIndex;
  }

  return bIndex - aIndex;
};

export function resolveJobState(
  job: CommandJob | TriggerJob,
): ResolvedJobState {
  if (job.state === "finished") {
    if (!job.passed) {
      return "hard_failed";
    }
    if (job.softFailed) {
      return "soft_failed";
    }
    return "passed";
  }
  return job.state;
}

interface FilterState {
  groupOption: GroupByOption | null;
  filterBy: FilterByOption[];
  setGroupOption: (filters: GroupByOption | null) => void;
  setFilterBy: (filters: FilterByOption[]) => void;
  resetGroup: () => void;
  resetFilters: () => void;
  resetAll: () => void;
}

export const useFilterStore = create<FilterState>()(
  persist(
    (set) => ({
      groupOption: GroupByOption.State,
      filterBy: [],
      setGroupOption: (groupOption) => set({ groupOption }),
      setFilterBy: (filterBy) => set({ filterBy }),
      resetAll: () => set({ filterBy: [], groupOption: null }),
      resetFilters: () => {
        set({ filterBy: [] });
      },
      resetGroup: () => {
        set({ groupOption: null });
      },
    }),
    {
      name: "build-filters",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ groupOption: state.groupOption }),
      version: 1,
    },
  ),
);

type Build = {
  state: BuildStates;
};

const STEP_FILTER_FUNCTIONS: Record<
  FilterByOption,
  (step: Step, build: Build) => boolean
> = {
  // A step is considered failed if it's failing, failed, canceled (ie. errored),
  // or has missing dependencies (only if the build is terminal).
  [FilterByOption.Failed]: function (step: Step, build: Build) {
    if (isTerminalBuildState(build.state)) {
      return isFailed(step) || Boolean(step?.missingDependencies?.length);
    }

    return isFailed(step);
  },
  [FilterByOption.Passed]: function (step: Step) {
    return isPassed(step);
  },
  [FilterByOption.Running]: function (step: Step) {
    return isRunning(step);
  },
  [FilterByOption.Scheduled]: function (step: Step) {
    return isScheduled(step);
  },
  [FilterByOption.SoftFailed]: function (step: Step) {
    return isSoftFailed(step);
  },
  [FilterByOption.Waiting]: function (step: Step) {
    return isWaiting(step);
  },
};

const JOB_FILTER_FUNCTIONS: Record<FilterByOption, (job: Job) => boolean> = {
  [FilterByOption.Failed]: function (job: Job) {
    return (
      (job.type === "script" && job.state === "finished" && !job.passed) ||
      job.state === "canceled" ||
      job.state === "timed_out"
    );
  },
  [FilterByOption.Passed]: function (job: Job) {
    if (job.type === "script" || job.type === "trigger") {
      return job.state === "finished" && Boolean(job.passed);
    }
    return job.state === "finished";
  },
  [FilterByOption.Running]: function (job: Job) {
    return job.state === "running";
  },
  [FilterByOption.Scheduled]: function (job: Job) {
    return job.state === "scheduled";
  },
  [FilterByOption.SoftFailed]: function (job: Job) {
    return Boolean(job.softFailed);
  },
  [FilterByOption.Waiting]: function (job: Job) {
    return job.state === "waiting";
  },
};

export function filterSteps(
  steps: Step[],
  filterOptions: FilterByOption[],
  build: Build,
): Step[] {
  // Remove ignored steps from the list
  steps = steps.filter((step) => step.state !== StepState.Ignored);

  if (filterOptions.length < 1) {
    return steps;
  }

  const filters = filterOptions.map((filter) => STEP_FILTER_FUNCTIONS[filter]);
  return steps.filter((step) => {
    // Remove wait steps from the list
    if (step.type === "wait") {
      return false;
    }

    // Apply all filters using logical OR operation
    return filters.some((filter) => filter(step, build));
  });
}

export function filterJobs<TJob extends Job>(
  jobs: TJob[],
  filters: FilterByOption[],
): TJob[] {
  if (filters.length < 1) {
    return jobs;
  }

  return jobs.filter((job) => {
    // Apply all filters using logical OR operation
    return filters.some((filter) => {
      return JOB_FILTER_FUNCTIONS[filter](job);
    });
  });
}

export function groupStepsBy(steps: Step[], groupOption: GroupByOption | null) {
  if (groupOption === GroupByOption.State) {
    return groupBy(steps, (step) => {
      // Remove wait steps from the list
      if (step.type === "wait") {
        return false;
      }

      if (isFailed(step)) {
        return FilterByOption.Failed;
      }

      if (isPassed(step)) {
        return FilterByOption.Passed;
      }

      if (isRunning(step)) {
        return FilterByOption.Running;
      }

      if (isScheduled(step)) {
        return FilterByOption.Scheduled;
      }

      if (isSoftFailed(step)) {
        return FilterByOption.SoftFailed;
      }

      if (isWaiting(step)) {
        return FilterByOption.Waiting;
      }
    });
  }
}
