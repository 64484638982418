import * as React from "react";
import QRCode from "qrcode.react";
import { parseUrl } from "query-string";
import ValidationErrors from "app/lib/ValidationErrors";
import Panel from "app/components/shared/Panel";
import Button from "app/components/shared/Button";
import TokenCodeInput from "app/components/shared/TokenCodeInput";
import buildkiteqr from "./buildkite.svg";

type ValidationError = {
  field: string;
  message: string;
};

type Props = {
  onActivateOtp: (token: string, callback?: () => void) => void;
  provisioningUri: string;
};

type State = {
  errors: Array<ValidationError> | null | undefined;
  isActivating: boolean;
  totpCodeValue: string;
  copiedOTPSecretKey: boolean;
};

export default class TwoFactorConfigureActivate extends React.PureComponent<
  Props,
  State
> {
  tokenInputRef = React.createRef<TokenCodeInput>();

  state = {
    errors: [],
    isActivating: false,
    totpCodeValue: "",
    copiedOTPSecretKey: false,
  };

  render() {
    const errors = new ValidationErrors(this.state.errors);
    const provisioningUriSecret = parseUrl(this.props.provisioningUri).query
      .secret;

    return (
      <>
        <p>
          To activate two-factor authentication, scan this barcode with your
          authenticator application, then enter the generated One Time Password
          below.
        </p>
        <Panel className="mb3">
          <div className="flex justify-center items-center">
            <div className="mt1 mb3">
              <figure
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ position: "absolute", width: 102, marginTop: 6 }}
                  src={buildkiteqr}
                />
                <QRCode
                  renderAs="svg"
                  fgColor="currentColor"
                  bgColor="transparent"
                  width="260"
                  height="260"
                  className="block mx-auto"
                  level="H" // approx 30% error correction
                  style={{ maxWidth: "100%" }}
                  value={this.props.provisioningUri}
                />
              </figure>
              <div className="dark-gray text-center mt2">
                Can’t use the barcode?
                <br />
                You can use this secret key instead:{" "}
                <span
                  className="monospace rounded"
                  style={{ fontSize: 13 }}
                  aria-label="Secret Key"
                >
                  {provisioningUriSecret}
                </span>
              </div>
            </div>
          </div>
        </Panel>
        <div className="py3 mb3">
          <TokenCodeInput
            ref={this.tokenInputRef}
            errors={errors.findForField("token")}
            disabled={this.state.isActivating}
            value={this.state.totpCodeValue}
            onChange={this.handleTotpCodeChange}
          />
        </div>
        <Button
          className="col-12"
          disabled={this.state.isActivating}
          theme="primary"
          onClick={this.handleTotpActivate}
        >
          Activate
        </Button>
      </>
    );
  }

  handleTotpCodeChange = (totpCodeValue: string) => {
    this.setState({ totpCodeValue });
  };

  handleTotpActivate = () => {
    this.setState({ isActivating: true }, () => {
      // @ts-expect-error - TS2345 - Argument of type '(errors: any) => void' is not assignable to parameter of type '() => void'.
      this.props.onActivateOtp(this.state.totpCodeValue, (errors) => {
        if (errors) {
          this.setState({ errors, isActivating: false }, () => {
            if (this.tokenInputRef.current) {
              // $FlowExpectError Typing createRefs seems broken at the moment, hopefully we can fis this at some point.
              this.tokenInputRef.current.focus();
            }
          });
        } else {
          this.setState({ errors: [], isActivating: false });
        }
      });
    });
  };
}
