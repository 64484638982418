import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";
import classNames from "classnames";
import Button from "app/components/shared/Button";
import Spinner from "app/components/shared/Spinner";

type Props = {
  connection: any | null | undefined;
  onShowMore: () => void;
  label?: string;
  loading: boolean;
  searching: boolean;
  className?: string;
};

export class ShowMoreFooter extends React.PureComponent<Props> {
  static defaultProps = {
    loading: false,
    searching: false,
    className: "px3 py2",
  };

  render() {
    const { connection, loading, searching } = this.props;

    // don't show any footer if we're searching
    if (searching) {
      return null;
    }

    // don't show any footer if we haven't ever loaded
    // any items, or if there's no next page
    if (
      !connection ||
      !connection.pageInfo ||
      !connection.pageInfo.hasNextPage
    ) {
      return null;
    }

    let footerContent = (
      <Button onClick={this.props.onShowMore}>
        Show more{this.props.label ? ` ${this.props.label}` : ""}…
      </Button>
    );

    // show a spinner if we're loading more
    if (loading) {
      footerContent = <Spinner style={{ margin: 9.5 }} />;
    }

    return (
      <div className={classNames("text-center", this.props.className)}>
        {footerContent}
      </div>
    );
  }
}

export default createFragmentContainer(ShowMoreFooter, {
  connection: graphql`
    fragment ShowMoreFooter_connection on Connection {
      pageInfo {
        hasNextPage
      }
    }
  `,
});
