import { useState } from "react";
import useFetch from "use-http";
import styled from "styled-components";

import FormRadioGroup from "app/components/shared/FormRadioGroup";
import Dialog from "app/components/shared/Dialog";
import Notice from "app/components/shared/Notice";
import Panel from "app/components/shared/Panel";
import Button from "app/components/shared/Button";
import FormTextField from "app/components/shared/FormTextField";
import FormInputLabel from "app/components/shared/FormInputLabel";
import FormInputHelp from "app/components/shared/FormInputHelp";
import Spinner from "app/components/shared/Spinner";

type StrategyId = "s3" | "gcs";

type BucketExportStrategy = {
  id: StrategyId;
  prefix: string;
  label: string;
};

type ExportBucketUpdaterProps = {
  buildExportStrategies: Array<BucketExportStrategy>;
  defaultStrategy: BucketExportStrategy;
  initialBucketName: string;
  initialBucketStrategy: BucketExportStrategy;
  organizationUuid: string;
  updateEndpoint: string;
};

const REQUEST_HEADERS = { "X-CSRF-Token": window._csrf.token } as const;
const BUILD_EXPORTS_DOCS_URL = "/docs/pipelines/build-exports";
const S3_SETUP_GUIDE_URL =
  "/docs/pipelines/build-exports#preparing-your-s3-bucket";
const GCS_SETUP_GUIDE_URL =
  "/docs/pipelines/build-exports#preparing-your-gcs-bucket";
const UPDATE_ACTIONS = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DISABLE: "DISABLE",
} as const;

const SETUP_GUIDE_BY_STRATEGY_ID: Partial<
  Partial<
    Record<
      StrategyId,
      {
        label: string;
        url: string;
      }
    >
  >
> = {
  s3: { label: "Amazon S3 Setup Guide", url: S3_SETUP_GUIDE_URL },
  gcs: { label: "Google Cloud Storage Setup Guide", url: GCS_SETUP_GUIDE_URL },
};

const ORGANIZATION_UUID_HELP_TEXT_BY_STRATEGY_ID: Partial<
  Partial<Record<StrategyId, string>>
> = {
  s3: "You’ll need this UUID when setting up the Amazon S3 bucket IAM policy.",
  gcs: "You’ll need this UUID when setting up the Google Cloud Storage bucket IAM permissions",
};

const InputWrapper = styled.div`
  .prefix {
    font-weight: 500;
  }
  .input {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    box-shadow: none;
  }
  &:focus-within {
    border-color: var(--lime-500);
    border-width: 2px;
    margin: -1px; // prevents layout shift from border widening when focussed
  }
`;

/**
 * This component returns a form for updating the bucket location used for exporting build data.
 * It uses a radio group to select the bucket export strategy (e.g. "Amazon S3", "Google Cloud Storage", etc.),
 * which determines the prefix used for the bucket name. It also uses a single form field to "enable" exports
 * (by setting a bucket export location) or "update" the bucket export location. Modal dialogs are displayed
 * to show the result (error or success) of form submission.
 *
 * A button to "disable" build exports is also provided, using a confirmation dialog before submitting
 * the same form, this time with an empty bucket name. When exports are "disabled", we will still export
 * build data to our own S3 bucket, but we will not export to the user's bucket.
 */
function ExportBucketUpdater({
  initialBucketName = "",
  ...props
}: ExportBucketUpdaterProps) {
  const [isUpdateResponseDialogOpen, setIsUpdateResponseDialogOpen] =
    useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [persistedBucketName, setPersistedBucketName] = useState(
    initialBucketName.length > 0 ? initialBucketName : null,
  );
  const [bucketName, setBucketName] = useState(initialBucketName);

  const [persistedBucketStrategy, setPersistedBucketStrategy] = useState(
    props.initialBucketStrategy,
  );
  const [bucketStrategy, setBucketStrategy] = useState(
    props.initialBucketStrategy,
  );

  const [errorMessage, setErrorMessage] = useState(null);
  const [lastUpdateAction, setLastUpdateAction] = useState(null);

  const setupGuide = SETUP_GUIDE_BY_STRATEGY_ID[bucketStrategy.id];

  // @ts-expect-error - TS2345 - Argument of type '{ headers: { readonly "X-CSRF-Token": string; }; cachePolicy: "network-only"; }' is not assignable to parameter of type 'any[] | IncomingOptions | OverwriteGlobalOptions | undefined'.
  const { loading, response, error, patch } = useFetch(props.updateEndpoint, {
    headers: REQUEST_HEADERS,
    cachePolicy: "network-only",
  });

  async function updateExportBucket(
    build_export_location,
    build_export_strategy: BucketExportStrategy,
  ) {
    return patch({ build_export_location, build_export_strategy });
  }

  async function handleUpdateExportBucket(
    event: React.SyntheticEvent<HTMLFormElement>,
  ) {
    event.preventDefault();

    // Updating to an empty bucketName is equivalent to disabling build exports, so we'll hand off to
    // the same flow as the "Disable Exports" button
    if (bucketName.length === 0) {
      setIsConfirmationDialogOpen(true);
      return;
    }

    if (isUpdateResponseDialogOpen) {
      setIsUpdateResponseDialogOpen(false);
    }

    await updateExportBucket(bucketName, bucketStrategy);

    setLastUpdateAction(
      // @ts-expect-error - TS2345 - Argument of type '"CREATE" | "UPDATE"' is not assignable to parameter of type 'SetStateAction<null>'.
      persistedBucketName === null
        ? UPDATE_ACTIONS.CREATE
        : UPDATE_ACTIONS.UPDATE,
    );

    if (response.ok) {
      setPersistedBucketName(bucketName);
      setPersistedBucketStrategy(bucketStrategy);
    } else {
      setErrorMessage(response.data.error);
    }

    setIsUpdateResponseDialogOpen(true);
  }

  async function handleDisableExports() {
    await updateExportBucket(null, props.defaultStrategy);

    if (response.ok) {
      setPersistedBucketName(null);
      setPersistedBucketStrategy(props.defaultStrategy);
      setBucketName("");
      setBucketStrategy(props.defaultStrategy);
    } else {
      setErrorMessage(
        // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'SetStateAction<null>'.
        `There was an error disabling your export to ${persistedBucketStrategy.label}. Please try again.`,
      );
    }

    // @ts-expect-error - TS2345 - Argument of type '"DISABLE"' is not assignable to parameter of type 'SetStateAction<null>'.
    setLastUpdateAction(UPDATE_ACTIONS.DISABLE);
    setIsConfirmationDialogOpen(false);
    setIsUpdateResponseDialogOpen(true);
  }

  function renderDialogHeading() {
    let heading = null;

    switch (lastUpdateAction) {
      // @ts-expect-error - TS2678 - Type '"CREATE"' is not comparable to type 'null'.
      case UPDATE_ACTIONS.CREATE:
        // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'null'.
        heading = `Connected to ${bucketStrategy.label}`;
        break;
      // @ts-expect-error - TS2678 - Type '"UPDATE"' is not comparable to type 'null'.
      case UPDATE_ACTIONS.UPDATE:
        // @ts-expect-error - TS2322 - Type '"Updated export location"' is not assignable to type 'null'.
        heading = "Updated export location";
        break;
      // @ts-expect-error - TS2678 - Type '"DISABLE"' is not comparable to type 'null'.
      case UPDATE_ACTIONS.DISABLE:
        // @ts-expect-error - TS2322 - Type '"Export disabled"' is not assignable to type 'null'.
        heading = "Export disabled";
        break;
    }

    if (error) {
      // @ts-expect-error - TS2322 - Type '"There was a problem"' is not assignable to type 'null'.
      heading = "There was a problem";
    }

    return heading !== null ? (
      <h1 className="m0" style={{ fontSize: "28px" }}>
        {heading}
      </h1>
    ) : null;
  }

  function formSubmittable() {
    // If exports are disabled, we can submit if the bucket name is present
    if (!persistedBucketName) {
      return bucketName.length > 0;
    }

    // If exports are enabled, we can submit if the the bucket name has changed.
    // Note: Setting the bucket name to an empty string is equivalent to disabling exports.
    if (bucketName !== persistedBucketName) {
      return true;
    }

    // If exports are enabled, we can submit if the export strategy has changed.
    if (bucketStrategy !== persistedBucketStrategy) {
      return true;
    }

    return false;
  }

  return (
    <>
      <Panel>
        <Panel.Header>Exporting Historical Build Data</Panel.Header>
        <form onSubmit={handleUpdateExportBucket}>
          <Panel.Section>
            <p>
              Configure how to store historical build data for your
              organization. Specify a cloud storage location if you need full
              control of your data or access for over 24 months. If you don’t
              specify a cloud storage location, your data is accessible in the
              Buildkite dashboard and API for 12 months, and you can request it
              from support for a further 18 months (30 months total).{" "}
              <a
                href={BUILD_EXPORTS_DOCS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more ↗
              </a>
            </p>
            <FormRadioGroup
              id="bucketStrategy"
              name="bucketStrategy"
              onChange={(event) => {
                const selectedStrategy = props.buildExportStrategies.find(
                  (option) => option.id === event.target.value,
                );
                if (selectedStrategy) {
                  setBucketStrategy(selectedStrategy);
                }
              }}
              label="Where should we export your build data?"
              value={bucketStrategy.id}
              options={props.buildExportStrategies.map((option) => ({
                label: option.label,
                value: option.id,
              }))}
            />
            {/* We don't have a PrefixedFormTextField component, and the requirements aren't clear to create one just yet, so for now we'll decompose the FormTextField component to customise */}
            <div className="mb2">
              <FormInputLabel label="Bucket URI">
                <InputWrapper className="flex gap1 input">
                  <div className="prefix">{bucketStrategy.prefix}</div>
                  <input
                    name="bucketName"
                    className="input"
                    value={bucketName}
                    placeholder="example-build-export-bucket"
                    onChange={(event) => setBucketName(event.target.value)}
                  />
                </InputWrapper>
              </FormInputLabel>
              <FormInputHelp>
                <>
                  Follow our{" "}
                  <a
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    href={setupGuide.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* @ts-expect-error - TS2532 - Object is possibly 'undefined'. */}
                    {setupGuide.label}
                  </a>{" "}
                  to ensure read/write access and bucket permissions are
                  correct.
                </>
              </FormInputHelp>
            </div>
            <FormTextField
              name="organizationUuid"
              label="Organization UUID"
              className="code"
              style={{ padding: "6px 12px", lineHeight: "20px" }}
              defaultValue={props.organizationUuid}
              readOnly={true}
              help={
                ORGANIZATION_UUID_HELP_TEXT_BY_STRATEGY_ID[bucketStrategy.id]
              }
            />
          </Panel.Section>
          <Panel.Separator />
          <Panel.Footer style={{ borderTop: "1px solid black" }}>
            <div className="flex gap2">
              <Button
                type="submit"
                theme="primary"
                disabled={!formSubmittable()}
              >
                <div className="flex gap1">
                  {loading ? <Spinner size={16} /> : null}
                  {persistedBucketName === null
                    ? "Enable Export"
                    : "Update Bucket Name"}
                </div>
              </Button>
              {persistedBucketName !== null ? (
                <Button
                  type="button"
                  theme="danger"
                  onClick={() => setIsConfirmationDialogOpen(true)}
                >
                  Disable Export
                </Button>
              ) : null}
            </div>
          </Panel.Footer>
        </form>
      </Panel>

      {/* Update Response Dialog - Update Bucket Name */}
      <Dialog
        isOpen={isUpdateResponseDialogOpen}
        onRequestClose={() => setIsUpdateResponseDialogOpen(false)}
      >
        <div className="flex flex-column gap3 m4">
          {renderDialogHeading()}
          {!loading && error ? (
            <>
              {lastUpdateAction === UPDATE_ACTIONS.UPDATE ? (
                <p className="m0">
                  There was a problem connecting to your {bucketStrategy.label}{" "}
                  bucket. Please check that your settings match the{" "}
                  <a
                    // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
                    href={setupGuide.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* @ts-expect-error - TS2532 - Object is possibly 'undefined'. */}
                    {setupGuide.label}
                  </a>
                  .
                </p>
              ) : null}
              {errorMessage ? (
                <Notice noticeType="error">
                  <p className="m0">{errorMessage}</p>
                </Notice>
              ) : null}
              <Button
                type="button"
                theme="default"
                onClick={() => setIsUpdateResponseDialogOpen(false)}
              >
                Close
              </Button>
            </>
          ) : null}
          {!loading && !error ? (
            <>
              <Notice noticeType="success" title="Success">
                <p className="m0">
                  {lastUpdateAction === UPDATE_ACTIONS.CREATE ||
                  lastUpdateAction === UPDATE_ACTIONS.UPDATE
                    ? `From now, all builds in this organization will be backed up to this ${bucketStrategy.label} bucket.`
                    : `Builds from this organization will no longer be exported to this cloud storage location.`}
                </p>
              </Notice>
              <Button
                type="button"
                theme="default"
                onClick={() => setIsUpdateResponseDialogOpen(false)}
              >
                Close
              </Button>
            </>
          ) : null}
        </div>
      </Dialog>

      {/* Confirmation Dialog - Disable Build exports */}
      <Dialog
        isOpen={isConfirmationDialogOpen}
        onRequestClose={() => setIsConfirmationDialogOpen(false)}
      >
        <div className="px4 pb4">
          <h2 className="h2">Are you sure?</h2>
          <p className="mb3">
            When you disable export, existing data will remain in your{" "}
            {bucketStrategy.label} bucket, but new exports will not be added.
            Continue?
          </p>
          <Button theme="danger" type="button" onClick={handleDisableExports}>
            <div className="flex gap1">
              {loading ? <Spinner size={16} /> : null}
              Disable Export
            </div>
          </Button>
        </div>
      </Dialog>
    </>
  );
}

export default ExportBucketUpdater;
