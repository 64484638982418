import { ReactNode, useState } from "react";
import { track } from "app/lib/segmentAnalytics";
import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from "downshift";
import classNames from "classnames";
import isNull from "lodash/isNull";
import map from "lodash/map";
import omitBy from "lodash/omitBy";
import pull from "lodash/pull";
import queryString from "query-string";
import sortBy from "lodash/sortBy";
import styled from "styled-components";

import CollapsibleSection from "./CollapsibleSection";
import Badge from "app/components/shared/Badge";
import Icon from "app/components/shared/Icon";
import Dropdown from "app/components/shared/Dropdown";
import Button from "app/components/shared/Button";
import { Current } from "app/utils/Current";

const STATE_FILTERS = {
  all: "All States",
  running: "Running",
  scheduled: "Scheduled",
  blocked: "Blocked",
  canceled: "Canceled",
  failed: "Failed",
  passed: "Passed",
  skipped: "Skipped",
  canceling: "Canceling",
  not_run: "Not Run",
  started: "Started",
};

const CREATOR_FILTERS = {
  all: "All Users",
  me: "Me",
};

const Menu = styled.ul<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  width: 100%;
  max-height: 235px;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  padding: 0px;
  padding-bottom: 10px;
  margin-bottom: 0px;
`;

const MenuItem = styled.li<{ highlighted: boolean }>`
  color: ${({ highlighted }) =>
    highlighted ? "var(--purple-600)" : "var(--charcoal-700)"};
  border-radius: 4px;
  cursor: pointer;
`;

const SearchIcon = styled(Icon).attrs({ icon: "search" })`
  flex: 0 0 auto;
  height: 20px;
  width: 20px;
  color: var(--gray-800);
`;

const StyledSearchInput = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "flex" : "none")};
  align-items: center;
  height: 38px;
  max-width: 300px;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 1px 1px;

  &:focus-within {
    border-color: var(--lime-500);
    border-width: 2px;
    padding: 0px 0px;

    & ${SearchIcon} {
      color: var(--charcoal-700);
    }
  }

  & input {
    all: unset;
    height: 100%;
    width: 100%;
    padding-right: 5px;

    ::placeholder {
      color: var(--gray-700);
    }
  }
`;

const StyledButton = styled(Button).attrs({
  className: "truncate",
})`
  transition: box-shadow 0.2s;
  outline: none;

  &:focus-visible {
    position: relative;
    z-index: 10; /* ensures that the box shadow stacks on top of neighbouring elements in a list view */
    box-shadow:
      0 0 0 2px #fff,
      0 0 0 4px var(--lime-500);
  }
`;

type MenuItemLinkProps = {
  children: ReactNode;
  href: string;
  id: string;
  isActive: boolean;
  onClick?: () => void;
};

const MenuItemLink = ({
  children,
  isActive,
  href,
  id,
  onClick,
}: MenuItemLinkProps) => (
  <a
    href={href}
    className={classNames(
      "btn black hover-purple focus-purple flex items-center semi-bold p2 my0 rounded gap0.5",
      {
        "btn--activated purple bg-light-purple semi-bold": isActive,
      },
    )}
    data-testid={`pipeline-branch-filter-option-${id}`}
    onClick={onClick}
  >
    {children}
  </a>
);

function getBranchesFilter(inputValue: string | undefined) {
  return function branchFilter(branch: string) {
    return !inputValue || branch.includes(inputValue.toLowerCase());
  };
}

function stateReducer(
  state: UseComboboxState<string>,
  actionAndChanges: UseComboboxStateChangeOptions<string>,
) {
  const { type, changes } = actionAndChanges;

  switch (type) {
    // prevent input value update on selection because a user has clicked on a link
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      return {
        ...changes,
        inputValue: state.inputValue,
      };
    default:
      return changes;
  }
}

export type Props = {
  branches: Array<string>;
  defaultBranch: string | null;
  selectedFilters: {
    branch?: string | null;
    state?: string | null;
    creator?: string | null;
  };
  buildsUrl: string;
  onBranchList?: boolean;
};

function BuildsFilterDropdown(props: Props) {
  let initialBranchList: Array<string>;

  if (props.defaultBranch) {
    const notDefaultBranches = sortBy(
      pull(props.branches, props.defaultBranch),
    );

    initialBranchList = [props.defaultBranch, ...notDefaultBranches];
  } else {
    initialBranchList = props.branches;
  }

  const [filteredBranches, setFilteredBranches] =
    useState<Array<string>>(initialBranchList);
  const showSearchInput = initialBranchList.length > 5;

  const defaultSearchedBranchName = () => {
    const branch = props.selectedFilters.branch;

    return branch && !initialBranchList.includes(branch) ? branch : "";
  };

  const selectedState =
    props.selectedFilters.state || (!props.onBranchList && "all");
  const selectedCreator =
    props.selectedFilters.creator || (!props.onBranchList && "all");

  const {
    inputValue,
    selectedItem: selectedBranch,
    highlightedIndex,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
  } = useCombobox({
    items: filteredBranches,
    initialInputValue: defaultSearchedBranchName(),
    defaultSelectedItem: props.selectedFilters.branch,
    onInputValueChange({ inputValue }) {
      setFilteredBranches(
        initialBranchList.filter(getBranchesFilter(inputValue)),
      );
    },
    stateReducer,
  });

  function filterUrl(params: {
    branch?: string | null;
    state?: string | null;
    creator?: string | null;
  }) {
    const currentSearchParams = queryString.parse(window.location.search);
    const nextSearchParams = omitBy(
      { ...currentSearchParams, ...params },
      isNull,
    );

    if (Object.keys(nextSearchParams).length === 0) {
      return props.buildsUrl;
    }

    return `${props.buildsUrl}?${queryString.stringify(nextSearchParams)}`;
  }

  return (
    <div>
      {/* Branch Filter Section */}
      <CollapsibleSection
        title="Branch"
        expandedByDefault={true}
        showActiveIndicator={Boolean(selectedBranch)}
      >
        <form method="get" action={props.buildsUrl}>
          <StyledSearchInput visible={showSearchInput}>
            <label className="pl2 pr1" {...getLabelProps()}>
              <SearchIcon />
              <span className="hide">Search branches</span>
            </label>

            <input
              className="input"
              placeholder="Search recent branches"
              name="branch"
              {...getInputProps({
                onKeyDown(event) {
                  if (event.key === "Enter") {
                    event.currentTarget.form?.requestSubmit();
                  }
                },
              })}
            />
          </StyledSearchInput>
        </form>

        <Menu {...getMenuProps()} visible={true}>
          <MenuItemLink
            key="all"
            id="all"
            href={filterUrl({ branch: null })}
            isActive={!selectedBranch && !props.onBranchList}
          >
            <Icon
              icon="custom/outline/14x/branch"
              className="flex-none"
              style={{ width: 16, height: 16 }}
            />
            <span>All Branches</span>
          </MenuItemLink>

          {filteredBranches.map((branch, index) => (
            <MenuItem
              key={branch}
              highlighted={highlightedIndex === index}
              {...getItemProps({ item: branch, index })}
            >
              <MenuItemLink
                id={branch}
                href={filterUrl({ branch })}
                isActive={selectedBranch === branch}
                onClick={_trackItemClicked({
                  action: "view-branch",
                  text: branch,
                  href: filterUrl({ branch }),
                })}
              >
                <span
                  className={classNames({
                    truncate: branch === props.defaultBranch,
                  })}
                  style={{
                    wordBreak:
                      branch !== props.defaultBranch && branch.includes("_")
                        ? "break-all"
                        : undefined,
                  }}
                >
                  {branch}
                </span>

                {branch === props.defaultBranch && (
                  <Badge
                    outline={true}
                    className={classNames("color-inherit", {
                      "badge--purple": selectedBranch === props.defaultBranch,
                    })}
                  >
                    Default
                  </Badge>
                )}
              </MenuItemLink>
            </MenuItem>
          ))}

          {filteredBranches.length === 0 && (
            <div className="flex flex-column items-center justify-center p4">
              <p className="bold">Nothing here</p>
              <p className="dark-gray text-center mb3">
                We show the most recent 50 branches here. Older branches can be
                found using their direct URL
              </p>

              <a
                className="btn btn-default flex items-center px2 py1 line-height-2"
                href={filterUrl({ branch: inputValue })}
                onClick={_trackItemClicked({
                  action: "view-branch-as-searched",
                  text: "Go to branch",
                  href: filterUrl({ branch: inputValue }),
                  theme: "default",
                })}
              >
                Go to branch
              </a>
            </div>
          )}
        </Menu>
      </CollapsibleSection>

      <hr className="my0 border-gray" />

      {/* State Filter Section */}
      <CollapsibleSection
        title="State"
        showActiveIndicator={Boolean(selectedState && selectedState !== "all")}
      >
        <Menu visible={true}>
          {map(STATE_FILTERS, (label, state) => (
            <MenuItemLink
              key={`filter-state-${state}`}
              id={`filter-state-${state}`}
              href={filterUrl({ state: state === "all" ? null : state })}
              isActive={selectedState === state}
              onClick={_trackItemClicked({
                action: "view-state",
                text: label,
                href: filterUrl({ state: state === "all" ? null : state }),
              })}
            >
              <span>{label}</span>
            </MenuItemLink>
          ))}
        </Menu>
      </CollapsibleSection>

      <hr className="my0 border-gray" />

      {/* User filter section */}
      <CollapsibleSection
        title="User"
        showActiveIndicator={Boolean(
          selectedCreator && selectedCreator !== "all",
        )}
      >
        <Menu visible={true}>
          {map(CREATOR_FILTERS, (label, creator) => (
            <MenuItemLink
              key={`filter-creator-${creator}`}
              id={`filter-creator-${creator}`}
              href={filterUrl({ creator: creator === "all" ? null : creator })}
              isActive={
                creator === "me"
                  ? selectedCreator === Current.user.uuid
                  : selectedCreator === creator
              }
              onClick={_trackItemClicked({
                action: "view-creator",
                text: label,
                href: filterUrl({
                  creator: creator === "all" ? null : creator,
                }),
              })}
            >
              <span>{label}</span>
            </MenuItemLink>
          ))}
        </Menu>
      </CollapsibleSection>
    </div>
  );
}

export default function BuildsFilterDropdownWrapper(props: Props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const appliedFilterCount = Object.values(props.selectedFilters).filter(
    Boolean,
  ).length;

  return (
    <Dropdown
      className="block antialiased"
      width={300}
      offsetY={8}
      style={{ maxWidth: "300px" }}
      onToggle={setIsDropdownOpen}
    >
      <StyledButton data-testid="pipeline-builds-filter-trigger" theme="small">
        <div className="flex items-center gap1 cursor-pointer">
          <Icon
            icon="custom/outline/16x/filter"
            className="flex-none"
            style={{ width: 16, height: 16 }}
          />

          <div className="flex items-center" style={{ minHeight: "22px" }}>
            <span className="semi-bold m0 truncate">Filter</span>
            {appliedFilterCount > 0 ? (
              <Badge outline={true} className="color-inherit">
                {appliedFilterCount}
              </Badge>
            ) : null}
          </div>

          <Icon
            icon={isDropdownOpen ? "up-triangle" : "down-triangle"}
            className="flex-none"
            style={{ width: 7, height: 7 }}
          />
        </div>
      </StyledButton>

      <BuildsFilterDropdown {...props} />
    </Dropdown>
  );
}

BuildsFilterDropdownWrapper.defaultProps = {
  selectedFilters: {
    branch: null,
    state: null,
    creator: null,
  },
};

const _trackItemClicked =
  ({
    action,
    text,
    href = "",
    theme = "",
  }: {
    action: string;
    text: string;
    href?: string;
    theme?: string;
  }) =>
  () => {
    track("Pipelines Branch Filter Item Clicked", {
      action,
      text,
      source: "Builds Filter Dropdown",
      href,
      theme,
    });
  };
