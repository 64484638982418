import React, { useEffect } from "react";
import { useBuild } from "app/components/Playground/BuildContext";
import { isTerminalBuildState } from "app/constants/BuildStates";
import NotEnoughData from "../components/Waterfall/EmptyStates/NotEnoughData";
import UpgadeRequired from "../components/Waterfall/EmptyStates/UpgadeRequired";
import IncompatibleBuild from "../components/Waterfall/EmptyStates/IncompatibleBuild";
import { Build } from "app/stores/BuildShowStore";
import { useParams } from "react-router-dom";
import Skeleton from "../components/Waterfall/EmptyStates/Skeleton";
import classNames from "classnames";
import { DrawerOutlet } from "../components/DrawerOutlet";
import WaterfallRowLabel from "../components/Waterfall/WaterfallRowLabel";
import WaterfallRowChart from "../components/Waterfall/WaterfallRowChart";
import {
  useWaterfallStore,
  WaterfallData,
  WaterfallRowType,
} from "../lib/useWaterfallStore";
import { useFetch, CachePolicies } from "use-http";

const useWaterfallData = () => {
  const { org, pipeline, number } = useParams();
  const waterfallDataUrl = `/${org}/${pipeline}/builds/${number}/waterfall_tab.json`;
  const store = useWaterfallStore();

  const { error, get, loading } = useFetch(waterfallDataUrl, {
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
      "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
    },
    cachePolicy: CachePolicies.CACHE_AND_NETWORK,
  });

  const fetchData = async () => {
    const data = await get();
    if (data) {
      store.setData(data);
    }
  };

  return { data: store.data, error, loading, fetchData };
};

function MissingData({ build }: { build: Build }) {
  if (isTerminalBuildState(build.state)) {
    return <IncompatibleBuild />;
  }
  return <NotEnoughData />;
}

export type FlattenedWaterfallRow = Omit<WaterfallRowType, "children">;

// Flatten the data for easier access. Convert from a tree structure to a flat list
function flattenChartChildren(chartData: WaterfallData["chart_data"]) {
  return chartData.reduce((acc, row) => {
    const { children, ...rest } = row;
    acc.push(rest);
    if (children.length > 0) {
      acc.push(...flattenChartChildren(children));
    }
    return acc;
  }, [] as FlattenedWaterfallRow[]);
}

export default function WaterfallPage() {
  const { store } = useBuild();
  if (!store) {
    throw new Error("Missing build context");
  }

  // Need to upgrade to see the waterfall
  if (!store.waterfallAvailable) {
    return <UpgadeRequired />;
  }

  return (
    <>
      <div className="flex-auto relative">
        <div
          className={classNames(
            "flex w-full h-full",
            Features.BuildSidebar ? "absolute overflow-y-auto" : "",
          )}
        >
          <WaterfallView />
        </div>
      </div>
      <DrawerOutlet />
    </>
  );
}

const WaterfallView = () => {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const { data, loading, fetchData } = useWaterfallData();
  useEffect(() => {
    fetchData();
  }, [build]);

  if (!data && loading) {
    return <Skeleton />;
  }

  if (!data) {
    return null;
  }

  if (data.chart_data.length === 0) {
    return <MissingData build={build} />;
  }

  return (
    <div className="flex-1">
      <section
        className={classNames(
          "relative w-full overflow-clip flex divide-x divide-gray-400",
          Features.BuildSidebar ? "" : "border border-gray-400 rounded-lg",
        )}
      >
        {/*
          We seperate out rendering labels from rendering bars so that we can
          make use of CSS divide-y to create borders between labels and child labels
        */}
        <div className="col-3 bg-silver m-0 p-0 divide-y divide-gray-400">
          {data.chart_data.map((row) => {
            const uniqueKey = ["label", row.step_uuid, row.job_uuid];
            return <WaterfallRowLabel key={uniqueKey.join("-")} data={row} />;
          })}
        </div>
        <div className="col-9 bg-white m-0 p-0 divide-y divide-gray-400">
          {flattenChartChildren(data.chart_data).map((row) => {
            const uniqueKey = ["chart", row.step_uuid, row.job_uuid];
            return (
              <WaterfallRowChart
                key={uniqueKey.join("-")}
                bar_container_padding={data.bar_container_padding}
                data={row}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};
