import classNames from "classnames";
import copy from "copy-to-clipboard";
import useFetch from "use-http";
import { map, set, toNumber } from "lodash";
import { updateIn } from "immutable";
import { useEffect, useState } from "react";

import { parse } from "app/lib/job-log";
import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";
import LegacyLoading from "app/components/shared/LegacyLoading";
import {
  Log,
  LogBody,
  LogContent,
  LogControl,
  LogGroup,
  LogGroupHeader,
  LogGroupName,
  LogGroupTime,
  LogHeader,
  LogLine,
  LogLineContent,
  LogLineNumber,
  LogLoading,
  LogMessage,
  LogTimestamp,
  LogCopyPermalink,
} from "app/components/shared/Log";
import { useUserSession } from "app/lib/useUserSession";
import { useHash } from "app/lib/useHash";

type LogLine = {
  number: number;
  startedAt: number | null;
  line: string;
};

type LogGroup = {
  id: string;
  number: number;
  name: string;
  startedAt: number | null;
  finishedAt: number | null;
  finished: boolean;
  durationPercentage: number;
  lines: Array<LogLine>;
  expanded: boolean;
  deemphasized: boolean;
};

type Log = {
  linesHaveTimestamps: boolean;
  totalLines: number;
  groups: Array<LogGroup>;
};

export default function LogViewer({
  logUrl,
  job,
}: {
  logUrl: string;
  job: {
    id: string;
    state: string;
    base_path: string;
    log_deleted_message: string;
    started_at_from_agent: number | null;
    finished_at_from_agent: number | null;
    permissions: {
      delete_job_log: {
        allowed: boolean;
      };
    };
  };
}) {
  const isDeleted = Boolean(job.log_deleted_message);
  const canDeleteLog =
    ["finished", "canceled", "timed_out"].includes(job.state) &&
    job?.permissions?.delete_job_log?.allowed;

  const [log, setLog] = useState<Log | null>(null);
  const [showCopy, setShowCopy] = useState(false);
  const [hash, setHash] = useHash();
  const [theme, setTheme] = useUserSession("code-theme", "default");
  const [timestamps, setTimestamps] = useUserSession(
    "job-log-timestamps-on",
    false,
  );
  const [utcTimestamps, setUtcTimestamps] = useUserSession(
    "timestamps-utc",
    false,
  );

  const { get, response, loading, error } = useFetch(logUrl);

  async function loadLog() {
    const data = await get();

    if (!response.ok) {
      return;
    }

    if (data) {
      const parsedLog = parse(
        {
          id: job.id,
          url: logUrl,
          truncated: false,
          startedAtFromAgent: job.started_at_from_agent,
          finishedAtFromAgent: job.finished_at_from_agent,
        },
        data,
      );

      const [groupNumber] = hash.slice(1).split("-").map(toNumber);

      if (groupNumber) {
        return setLog({
          ...parsedLog,
          groups: parsedLog.groups.map((group) => {
            if (group.number === groupNumber) {
              return {
                ...group,
                expanded: true,
              };
            }

            return group;
          }),
        });
      }

      setLog(parsedLog);
    } else {
      setLog({
        groups: [],
        linesHaveTimestamps: false,
        totalLines: 0,
      });
    }
  }

  useEffect(() => {
    if (!isDeleted) {
      loadLog();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function toggleTheme() {
    setTheme(theme === "default" ? "solarized" : "default");
  }

  function scrollToEnd() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }

  function toggleTimestamps() {
    setTimestamps(!timestamps);
  }

  function toggleUtcTimestamps() {
    setUtcTimestamps(!utcTimestamps);
  }

  function setAllExpanded(expanded: boolean) {
    if (!log) {
      return;
    }

    setLog({
      ...log,
      groups: map(log.groups, (group) => set(group, "expanded", expanded)),
    });
  }

  function toggleGroup(index: number) {
    if (!log) {
      return;
    }

    setLog(
      updateIn(log, ["groups", index, "expanded"], (expanded) => !expanded),
    );
  }

  function lineHash(group: LogGroup, line?: LogLine) {
    return line ? `#${group.number}-${line.number}` : `#${group.number}`;
  }

  function isHighlighted(group: LogGroup, line?: LogLine) {
    return hash === lineHash(group, line);
  }

  function toggleHighlighted(group: LogGroup, line?: LogLine) {
    const nextHash = lineHash(group, line);

    if (hash === nextHash) {
      setHash("_");
    } else {
      setShowCopy(true);
      setHash(nextHash);
    }
  }

  function copyPermalink(group: LogGroup, line?: LogLine) {
    return (event: React.MouseEvent) => {
      event.stopPropagation();

      const url = new URL(window.location.href);
      url.hash = lineHash(group, line);
      copy(url.toString());

      setShowCopy(false);
    };
  }

  return (
    <Log
      theme={theme}
      className={classNames({ "min-h-0 p-0": error || isDeleted })}
    >
      {!error && !isDeleted && (
        <LogHeader>
          <LogControl onClick={() => setAllExpanded(true)}>
            <Icon icon="heroicons/16/solid/plus" className="w-4 h-4" />
            Expand groups
          </LogControl>

          <LogControl onClick={() => setAllExpanded(false)}>
            <Icon icon="heroicons/16/solid/minus" className="w-4 h-4" />
            Collapse groups
          </LogControl>

          {log?.linesHaveTimestamps && (
            <LogControl onClick={toggleTimestamps}>
              <Icon icon="heroicons/16/solid/calendar" className="w-4 h-4" />
              {timestamps ? "Hide timestamps" : "Show timestamps"}
            </LogControl>
          )}

          <LogControl className="md:ml-auto" onClick={toggleTheme}>
            <Icon
              icon={
                theme === "default"
                  ? "heroicons/16/solid/sun"
                  : "heroicons/16/solid/moon"
              }
              className="h-4 w-4"
            />
            Theme
          </LogControl>

          {canDeleteLog && (
            <LogControl
              href={`${job.base_path}/delete_log`}
              data-method="delete"
              data-confirm="Permanently delete this job’s log?"
            >
              <Icon icon="heroicons/16/solid/trash" className="h-4 w-4" />
              Delete
            </LogControl>
          )}

          <LogControl href={`${job.base_path}/download.txt`}>
            <Icon
              icon="heroicons/16/solid/arrow-down-tray"
              className="h-4 w-4"
            />
            Download
          </LogControl>

          <LogControl onClick={scrollToEnd}>
            <Icon
              icon="heroicons/16/solid/chevron-double-down"
              className="h-4 w-4"
            />
            Jump to end
          </LogControl>
        </LogHeader>
      )}

      <LogBody>
        {loading && (
          <LogLoading>
            <LegacyLoading />
          </LogLoading>
        )}

        {isDeleted && (
          <LogMessage>
            <Emojify text={job.log_deleted_message} />
          </LogMessage>
        )}

        {error && <LogMessage>There was an error loading the log</LogMessage>}

        {log && (
          <LogContent totalLines={log.totalLines}>
            {log.groups.map((group, index) => (
              <LogGroup
                key={group.id}
                data-testid={`log-group-${group.number}`}
              >
                <LogGroupHeader
                  highlighted={isHighlighted(group)}
                  deemphasized={group.deemphasized}
                >
                  <LogLineNumber
                    number={group.number}
                    onClick={() => toggleHighlighted(group)}
                  >
                    <LogCopyPermalink
                      visible={showCopy && isHighlighted(group)}
                      onClick={copyPermalink(group)}
                    />
                  </LogLineNumber>

                  {timestamps && (
                    <LogTimestamp
                      utc={utcTimestamps}
                      time={group.startedAt}
                      onClick={toggleUtcTimestamps}
                    />
                  )}

                  <LogGroupName
                    name={group.name}
                    expanded={group.expanded}
                    onClick={() => toggleGroup(index)}
                  />

                  <LogGroupTime
                    startedAt={group.startedAt}
                    finishedAt={group.finishedAt}
                    finished={group.finished}
                    durationPercentage={group.durationPercentage}
                  />
                </LogGroupHeader>

                {group.lines.map((line) => (
                  <LogLine
                    hidden={!group.expanded}
                    key={line.number}
                    highlighted={isHighlighted(group, line)}
                  >
                    <LogLineNumber
                      number={line.number}
                      onClick={() => toggleHighlighted(group, line)}
                    >
                      <LogCopyPermalink
                        visible={showCopy && isHighlighted(group, line)}
                        onClick={copyPermalink(group, line)}
                      />
                    </LogLineNumber>

                    {timestamps && (
                      <LogTimestamp
                        utc={utcTimestamps}
                        time={line.startedAt}
                        onClick={toggleUtcTimestamps}
                      />
                    )}

                    <LogLineContent colSpan={2}>
                      <span dangerouslySetInnerHTML={{ __html: line.line }} />
                    </LogLineContent>
                  </LogLine>
                ))}
              </LogGroup>
            ))}
          </LogContent>
        )}
      </LogBody>
    </Log>
  );
}
