import * as React from "react";
import Emojify from "app/components/shared/Emojify";
import { shortCommit } from "app/lib/commits";
import classNames from "classnames";
import { jobTime } from "app/lib/jobs";
import Duration from "app/components/shared/Duration";
import Icon from "app/components/shared/Icon";
import PipelineStateIcon from "app/components/shared/PipelineStateIcon";
import { elementIdFor } from "app/components/job/Job";
import { TriggerJob } from "../../Header/pipeline/types/TriggerJob";
import { BuildStates } from "app/constants/BuildStates";
import { MissingDependenciesNode } from "./MissingDependenciesNode";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  HoverCardPortal,
} from "app/components/shared/HoverCard";

type Props = {
  className?: string;
  job: TriggerJob;
  isRetry: boolean | null | undefined;
  build: {
    state: BuildStates;
  };
};

export default class TriggerJobSummary extends React.PureComponent<Props> {
  render() {
    let state =
      this.props.job.state === "finished" ||
      this.props.job.state === "timed_out" ||
      this.props.job.state === "canceled" ||
      this.props.job.state === "expired"
        ? this.props.job.passed
          ? "passed"
          : "failed"
        : this.props.job.state;

    // Canceling and timing out are still running, so let's show them as such.
    if (state === "canceling" || state === "timing_out") {
      state = "running";
    }

    const triggerFailed = !!this.props.job.triggerFailedReason;

    let contentsNode = (
      <div className="build-details-pipeline-job__header flex items-center antialiased px-3 py-3.5 gap-x-2.5 bg-white hover z2 hover-bg-silver">
        <div className="flex gap-x-1.5 flex-auto items-center">
          <span className="build-details-pipeline-icon">
            <PipelineStateIcon job={this.props.job} />
          </span>

          <span className="flex-none truncate job-name">
            <Emojify text={this.props.job.name || "Trigger Build"} />
          </span>

          <span className="dark-gray truncate">
            {this.props.isRetry ? "Rebuild" : "Triggered build"}
            {" of "}
            <code className="monospace">{this.props.job.triggerBranch}</code>
            {" \x2F "}
            <code className="monospace" title={this.props.job.triggerCommit}>
              {shortCommit(
                this.props.job.triggerCommit,
                this.props.job.triggerCommitShortLength,
              )}
            </code>
            {" on "}
            {this.nameNode()}
          </span>
        </div>

        {triggerFailed ? (
          this.errorMessageNode()
        ) : (
          <div className="flex flex-none items-center">
            <MissingDependenciesNode
              job={this.props.job}
              build={this.props.build}
            />

            {this.durationNode()}
            {this.deletedNode()}

            {this.props.job.triggeredBuild && (
              <div className="flex-none dark-gray pl2">
                <Icon icon="chevron-right" style={{ height: 12, width: 12 }} />
              </div>
            )}
          </div>
        )}
      </div>
    );

    const classes = classNames(
      "build-details-pipeline-job relative color-inherit focus-color-inherit text-decoration-none hover-color-inherit block",
      {
        [`build-details-pipeline-job-state-${state}`]: true,
      },
      this.props.className,
    );

    if (
      this.props.job.triggeredBuild &&
      !this.props.job.triggeredBuild.destroyedAt
    ) {
      contentsNode = (
        <a
          href={this.props.job.triggeredBuild.url}
          className={classes}
          id={this.elementId()}
        >
          {contentsNode}
        </a>
      );
    } else {
      contentsNode = (
        <div className={classes} id={this.elementId()}>
          {contentsNode}
        </div>
      );
    }

    return contentsNode;
  }

  nameNode() {
    // triggerProject might be soft-deleted, in which case it has a destroyedAt.
    // If triggerProject has been hard-deleted, it will be null/undefined/nada.
    // However triggerProjectSlug etc remain stored on the job, so those remain.
    if (this.props.job.triggerProject) {
      return <Emojify text={this.props.job.triggerProject.name} />;
    }
    if (this.props.job.triggerProjectSlug) {
      return (
        <code className="monospace">{this.props.job.triggerProjectSlug}</code>
      );
    }
    return "unknown pipeline";
  }

  durationNode() {
    const buildNumber = this.props.job.triggeredBuild
      ? `#${this.props.job.triggeredBuild.number}`
      : "";

    if (this.props.job.triggerAsync) {
      return (
        <div className="xs-flex-wrap sm-flex-none dark-gray pl2">
          Build {buildNumber} (asynchronous)
        </div>
      );
    }

    if (this.props.job.startedAt) {
      const runTense =
        this.props.job.state === "running" ? "running for" : "ran in";

      const duration = jobTime(this.props.job);

      return (
        <div className="xs-flex-wrap sm-flex-none dark-gray pl2">
          <span>
            Build {buildNumber} {runTense} <Duration.Short {...duration} />
          </span>
        </div>
      );
    }
  }

  deletedNode() {
    const job = this.props.job;
    return (
      (job.triggeredBuildDeleted === true ||
        (job.triggeredBuild && job.triggeredBuild.destroyedAt)) && (
        <div className="flex-none ml1 red">(Deleted)</div>
      )
    );
  }

  errorMessageNode() {
    const triggerDenied =
      window.Features.Rules &&
      this.props.job.triggerFailedReason?.startsWith("Trigger denied:");
    return (
      this.props.job.triggerFailedReason && (
        <HoverCard>
          <HoverCardTrigger>
            <div className="flex-none red max-width-3 ml1">
              <Icon
                icon="heroicons/solid/exclamation-triangle"
                className="h-4 w-4 mr-1"
              />
              {triggerDenied ? "Trigger denied" : "Trigger failed"}
            </div>
          </HoverCardTrigger>

          <HoverCardContent size="max-lg">
            {triggerDenied ? (
              <div>
                <p>{this.props.job.triggerFailedReason}</p>
                <br />
                {this.props.job.triggerFailedReason ===
                  "Trigger denied: Builds can only be triggered between pipelines in the same cluster" && (
                  <div>
                    <strong>Tip:</strong> If you are trying to trigger a build
                    between pipelines in different clusters, you can create a{" "}
                    <a href="/docs/pipelines/rules">
                      <code>pipeline.trigger_build.pipeline</code> rule
                    </a>{" "}
                    to allow triggering a pipeline in another cluster.
                  </div>
                )}
              </div>
            ) : (
              <div>{this.props.job.triggerFailedReason}</div>
            )}
          </HoverCardContent>
        </HoverCard>
      )
    );
  }

  elementId() {
    return elementIdFor(this.props.job.id);
  }
}
