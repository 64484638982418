import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import Icon from "app/components/shared/Icon";
import { Outcome, State, Step } from "app/lib/pipeline";
import { StepListItem } from "./StepListItem";
import { StepRouteParams } from "../../..";
import { useBuild } from "app/components/Playground/BuildContext";

export function CollapsibleStepListItem({
  step,
  children,
}: PropsWithChildren<{ step: Step }>) {
  const { stepOrJobId = "" } = useParams<StepRouteParams>();
  const { build, store } = useBuild();

  // If the step is hard failed or failing, default to open
  const [value, setValue] = useState<string[]>(
    (step.state === State.Finished && step.outcome === Outcome.HardFailed) ||
      step.state === State.Failing
      ? [step.uuid]
      : [],
  );

  // If the step transitions to hard failed or failing, open the step
  useEffect(() => {
    if (
      (step.state === State.Finished && step.outcome === Outcome.HardFailed) ||
      step.state === State.Failing
    ) {
      setValue([step.uuid]);
    }
  }, [step.state]);

  useEffect(() => {
    if (!build || !store) {
      return;
    }

    const job = store.jobs.get(stepOrJobId);

    if (step.type === "group") {
      const groupStepIds = step.steps?.map((step) => step.uuid) || [];

      // step within group step is clicked
      if (groupStepIds.includes(stepOrJobId)) {
        setValue([step.uuid]);
      }

      // job within group step is clicked
      if (job && job.groupUuid === step.uuid) {
        setValue([step.uuid]);
      }
    }

    if (job && step.uuid === job.stepUuid) {
      setValue([step.uuid]);
    }
  }, [stepOrJobId]);

  const onValueChange = useCallback((value: string[]) => {
    setValue(value);
  }, []);

  const open = useCallback(() => {
    setValue([step.uuid]);
  }, []);

  return (
    <Accordion.Root
      type="multiple"
      className="flex flex-auto"
      onValueChange={onValueChange}
      value={value}
    >
      <Accordion.Item value={step.uuid} className="w-full">
        <div
          className={classNames("w-full", {
            "bg-purple-100 ring-2 ring-purple-600 rounded-md font-semibold [&_.ring-2]:ring-0":
              step.uuid === stepOrJobId,
          })}
        >
          <Accordion.Trigger className="flex w-full items-baseline rounded-md hover:bg-purple-100 [&>div>svg]:data-[state=open]:rotate-90">
            <div className="self-stretch shrink-0 flex items-center justify-center w-7 hover:bg-[#CDC6FF] rounded-md">
              <Icon
                icon="heroicons/outline/chevron-right"
                className="w-3 h-3 transition-transform duration-150"
              />
            </div>
            <div className="flex flex-auto">
              <StepListItem step={step} onClick={open} className="pl-0" />
            </div>
          </Accordion.Trigger>
        </div>
        <div className="overflow-y-clip">
          <Accordion.Content className="data-[state=open]:animate-[radixAccordionSlideDown_150ms_ease-in-out] data-[state=closed]:animate-[radixAccordionSlideUp_150ms_ease-in-out] py-[2px]">
            <div className="flex flex-col border-l border-gray-400 ml-3">
              {children}
            </div>
          </Accordion.Content>
        </div>
      </Accordion.Item>
    </Accordion.Root>
  );
}
