/* eslint-disable id-length */

import { ChartDataset, TooltipModel } from "chart.js";
import { sumBy, take } from "lodash";
import { Fragment, useEffect } from "react";

import LineChart, { LineChartUnit } from "../LineChart";
import { ChartLegend } from "../ChartLegend";
import { TimePeriod } from "../Renderer";
import { useChart } from "app/components/shared/Chart/chart";
import { Tooltip, TooltipLabel, TooltipValue } from "../Tooltip";
import { useChartData } from "./hooks";
import SparkLineChart, { dataForSparkline } from "../SparkLineChart";
import {
  Card,
  CardSection,
  CardChart,
  CardLabel,
  CardStat,
  CardDelta,
} from "../Card";
import {
  calculatePercentage,
  calculateSum,
  formatPercentage,
  getThemeFromDelta,
  getTimePeriodString,
  labelHeader,
  Scope,
} from "./utils";

interface DataPoint {
  x: number;
  passed: number;
  failed: number;
  total: number;
  percentage: number | null;
}

export function JobPassRateSpark({
  datasets: { current, previous },
  timePeriod,
}: {
  datasets: {
    current: DataPoint[];
    previous: DataPoint[];
  };
  timePeriod: TimePeriod;
}) {
  const total = calculateSum(current, "total");
  const failed = calculateSum(current, "failed");
  const passed = calculateSum(current, "passed");
  const percentage = calculatePercentage(passed, total);

  const previousTotal = calculateSum(previous, "total");
  const previousPassed = calculateSum(previous, "passed");
  const previousPercentage = calculatePercentage(previousPassed, previousTotal);

  const delta =
    percentage &&
    previousPercentage &&
    Math.round(Math.abs(percentage - previousPercentage));
  const theme = getThemeFromDelta(delta);

  return (
    <Card>
      <CardSection>
        <CardLabel>Job pass rate</CardLabel>
        {!!delta && (
          <CardDelta
            formattedValue={formatPercentage(delta)}
            value={delta}
            color={theme.color}
          />
        )}
      </CardSection>

      <CardSection>
        <CardStat
          value={formatPercentage(percentage)}
          size="lg"
          layout="stacked"
          label={getTimePeriodString(timePeriod)}
        />

        <CardSection className="flex-col">
          <CardStat label="Total" value={total.toLocaleString()} />
          <CardStat label="Failed" value={failed.toLocaleString()} />
        </CardSection>
      </CardSection>

      <CardChart>
        <SparkLineChart
          data={dataForSparkline(previous, current, "percentage", theme)}
        />
      </CardChart>
    </Card>
  );
}

export function JobPassRateDetail({
  scope,
  datasets,
  timePeriod,
}: {
  scope: Scope;
  datasets: ChartDataset<"line", DataPoint[]>[];
  timePeriod: TimePeriod;
}) {
  const { chartRef, updateData } = useChart<"line", DataPoint>();

  const data = useChartData({
    datasets: datasets,
    orderDatasetBy: ({ data }) => calculateSum(data, "total"),
    colorsOffset: 4,
  });

  useEffect(() => {
    updateData(data);
  }, [data, updateData]);

  const total = sumBy(datasets, ({ data }) => calculateSum(data, "total"));
  const failed = sumBy(datasets, ({ data }) => calculateSum(data, "failed"));

  return (
    <Card className="lg:flex-row">
      <div className="flex flex-col grow gap-3 min-w-0">
        <CardSection>
          <CardLabel description={getTimePeriodString(timePeriod)}>
            Job pass rate
          </CardLabel>

          <CardSection className="flex-col">
            <CardStat value={total.toLocaleString()} label="Total Jobs" />
            <CardStat value={failed.toLocaleString()} label="Total Failed" />
          </CardSection>
        </CardSection>

        <CardChart>
          <LineChart
            ref={chartRef}
            data={data}
            tooltip={ChartTooltip}
            options={{
              parsing: { yAxisKey: "percentage", xAxisKey: "x" },
            }}
            units={{ y: LineChartUnit.percentage }}
          />
        </CardChart>
      </div>

      <ChartLegend
        labelHeader={labelHeader(scope, "Queue")}
        datasets={data.datasets}
        chartRef={chartRef}
        columns={{
          "Pass rate": ({ data }) => {
            const passed = calculateSum(data, "passed");
            const total = calculateSum(data, "total");
            const percentage = calculatePercentage(passed, total);

            return formatPercentage(percentage);
          },
          Failed: ({ data }) => calculateSum(data, "failed").toLocaleString(),
          Passed: ({ data }) => calculateSum(data, "passed").toLocaleString(),
          Total: ({ data }) => calculateSum(data, "total").toLocaleString(),
        }}
      />
    </Card>
  );
}

const ChartTooltip = ({ tooltip }: { tooltip: TooltipModel<"line"> }) => {
  const visibleDataPoints = take(tooltip.dataPoints, 8);
  const hiddenDataPoints = tooltip.dataPoints.length - 8;

  return (
    <Tooltip tooltip={tooltip}>
      {visibleDataPoints.map(({ dataset, parsed }) => (
        <Fragment key={dataset.label}>
          <TooltipLabel color={dataset.borderColor as string}>
            {dataset.label}
          </TooltipLabel>

          <TooltipValue>{formatPercentage(parsed.y)}</TooltipValue>
        </Fragment>
      ))}

      {hiddenDataPoints > 0 && (
        <span className="col-span-2 text-[11px] font-medium text-charcoal-300">
          ({hiddenDataPoints} not shown)
        </span>
      )}
    </Tooltip>
  );
};
