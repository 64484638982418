import Environment from "app/lib/relay/environment";
import usePreloadedRelayQuery from "app/lib/usePreloadedRelayQuery";
import { useRef, useState } from "react";
import { QueryRenderer, createRefetchContainer, graphql } from "react-relay";

import Dropdown from "app/components/shared/Dropdown";
import Panel from "app/components/shared/Panel";
import SearchField from "app/components/shared/SearchField";
import ShowMoreFooter from "app/components/shared/ShowMoreFooter";
import Spinner from "app/components/shared/Spinner";
import TeamPrivacyConstants from "app/constants/TeamPrivacyConstants";
import { formatNumber } from "app/lib/number";
import SectionLoader from "../shared/SectionLoader";
import Row from "./Row";

const TEAM_PRIVACIES = [
  { name: "All Teams", id: null },
  { name: "Visible", id: TeamPrivacyConstants.VISIBLE },
  { name: "Secret", id: TeamPrivacyConstants.SECRET },
];

const PAGE_SIZE = 10;

const TeamsPage = (props) => {
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [searching, setSearching] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [privacy, setPrivacy] = useState(null);
  const [teamSearch, setTeamSearch] = useState(null);

  const privacyDropdownRef = useRef();

  const teamViewPermission = props.organization.permissions.teamView;
  // Teasers / banner for the Teams Permissions feature will be shown in the app/views/teams/index.html.erb page
  if (!teamViewPermission.allowed) {
    return null;
  }

  const handlePrivacySelect = (privacy) => {
    setPrivacy(privacy);
    handleTeamFilterChange({ privacy });
  };

  const handleTeamSearch = (search) => {
    setTeamSearch(search);
    handleTeamFilterChange({ search });
  };

  const handleShowMoreTeams = () => {
    setLoadingMore(true);

    const teamPageSize = pageSize + PAGE_SIZE;

    setPageSize(teamPageSize);

    props.relay.refetch(
      (prevValues) => ({
        ...prevValues,
        pageSize: teamPageSize,
      }),
      null,
      () => {
        setLoadingMore(false);
      },
      { force: true },
    );
  };

  const handleTeamFilterChange = (variables) => {
    setSearching(true);
    props.relay.refetch(
      (prevValues) => ({
        ...prevValues,
        ...variables,
      }),
      null,
      () => {
        setSearching(false);
      },
      { force: true },
    );
  };

  const TeamPrivacies = () =>
    TEAM_PRIVACIES.map((role, index) => {
      return (
        <div
          key={index}
          className="btn block hover-bg-silver"
          onClick={() => {
            if (privacyDropdownRef && privacyDropdownRef.current) {
              privacyDropdownRef.current.setShowing(false);
            }
            handlePrivacySelect(role.id);
          }}
        >
          <span className="block">{role.name}</span>
        </div>
      );
    });

  const TeamSearchInfo = ({ teams, teamSearch, searching }) => {
    if (!teamSearch || !teams || searching) {
      return null;
    }

    return (
      <div className="bg-silver semi-bold py2 px3">
        <small className="dark-gray">
          {formatNumber(teams.count)} matching teams
        </small>
      </div>
    );
  };

  // Teams needs to return an array so cannot be a Functional component for now
  const renderTeams = ({ teams }) => {
    if (!teams) {
      return (
        <Panel.Section className="text-center">
          <Spinner />
        </Panel.Section>
      );
    }

    if (teams.edges.length === 0) {
      if (teamSearch) {
        return null;
      }

      return (
        <Panel.Section className="dark-gray">
          <p>There are no teams in this organization</p>
        </Panel.Section>
      );
    }

    return teams.edges.map((edge) => (
      <Row key={edge.node.id} team={edge.node} />
    ));
  };

  return (
    <div>
      <div>
        <Panel className="mb4">
          <div className="py2 px3">
            <div className="flex flex-auto items-center">
              <SearchField
                className="flex-auto"
                placeholder="Search teams…"
                searching={searching}
                onChange={handleTeamSearch}
              />

              <div className="flex-none pl3 flex">
                <Dropdown
                  width={150}
                  ref={(_privacyDropdown) =>
                    (privacyDropdownRef.current = _privacyDropdown)
                  }
                >
                  <div className="underline-dotted cursor-pointer inline-block regular dark-gray">
                    {
                      TEAM_PRIVACIES.find(
                        (teamPrivacy) => teamPrivacy.id === privacy,
                      ).name
                    }
                  </div>
                  <TeamPrivacies />
                </Dropdown>
              </div>
            </div>
          </div>

          <TeamSearchInfo
            teams={props.organization.teams}
            teamSearch={teamSearch}
            searching={searching}
          />

          {renderTeams({ teams: props.organization.teams })}
          <ShowMoreFooter
            connection={props.organization.teams}
            label="teams"
            loading={loadingMore}
            onShowMore={handleShowMoreTeams}
          />
        </Panel>
      </div>
    </div>
  );
};

const TeamsPageContainer = createRefetchContainer(
  TeamsPage,
  {
    organization: graphql`
      fragment TeamsRenderer_organization on Organization
      @argumentDefinitions(
        pageSize: { type: "Int", defaultValue: 10 }
        search: { type: "String" }
        privacy: { type: "[TeamPrivacy!]" }
      ) {
        name
        slug
        permissions {
          teamView {
            allowed
            code
            message
          }
          teamCreate {
            allowed
          }
        }
        allTeams: teams {
          count
        }
        teams(
          first: $pageSize
          search: $search
          privacy: $privacy
          order: NAME
        ) {
          ...ShowMoreFooter_connection
          count
          edges {
            node {
              id
              ...Row_team
            }
          }
        }
      }
    `,
  },
  graphql`
    query TeamsRendererRefetchQuery(
      $organization: ID!
      $pageSize: Int!
      $search: String
      $privacy: [TeamPrivacy!]
    ) {
      organization(slug: $organization) {
        ...TeamsRenderer_organization
          @arguments(pageSize: $pageSize, search: $search, privacy: $privacy)
      }
    }
  `,
);

const TeamsPageRenderer = ({ slug }) => {
  const environment = Environment.get();
  const query = graphql`
    query TeamsRendererQuery($organization: ID!) {
      organization(slug: $organization) {
        ...TeamsRenderer_organization
      }
    }
  `;
  const variables = { organization: slug };

  usePreloadedRelayQuery(
    true,
    {
      environment,
      query,
      variables,
    },
    [],
  );

  return (
    <QueryRenderer
      fetchPolicy="store-and-network"
      environment={environment}
      query={query}
      variables={variables}
      render={({ props, error }) => {
        if (error || !props) {
          return <SectionLoader />;
        }
        return <TeamsPageContainer {...props} />;
      }}
    />
  );
};

export default TeamsPageRenderer;
