import { useEffect, useRef } from "react";
import { Link, useMatches, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { BuildView, StepRouteParams } from "app/components/build/Show/index";
import { urlForView } from "app/components/build/Show/lib/urlForView";
import { useBuild } from "app/components/Playground/BuildContext";
import { useTrack } from "app/components/build/Show/lib/useTrack";

/**
 * Get the currently active step view (ie. canvas, waterfall, table).
 */
const useCurrentStepView = () => {
  const matches = useMatches();
  const stepViews = [BuildView.Canvas, BuildView.Waterfall, BuildView.Table];

  let view: BuildView = BuildView.Canvas;
  matches.forEach((match) => {
    if (stepViews.includes(match?.id as BuildView)) {
      view = match.id as BuildView;
    }
  });

  return view;
};

/**
 * A link to a step (with optional job ID).
 */
export const StepLink = ({
  uuid,
  type,
  className,
  children,
  onClick,
}: {
  uuid: string;
  type?: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }
  const { stepOrJobId } = useParams<StepRouteParams>();
  const track = useTrack();
  const view = useCurrentStepView();
  const path = urlForView(build?.path, view, uuid);
  const scrollRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (uuid === stepOrJobId) {
      // Delay to ensure a nested group step is expanded before scrolling into view
      setTimeout(() => {
        const stepEl = scrollRef.current;

        if (stepEl === null) {
          return;
        }

        // Only scroll step into view if it is not visible in the viewport
        if (
          stepEl.getBoundingClientRect().bottom > window.innerHeight ||
          stepEl.getBoundingClientRect().top < 0
        ) {
          stepEl.scrollIntoView({ block: "center" });
        }
      }, 250);
    }
  }, [uuid, stepOrJobId]);

  const handleClick = () => {
    track("Build Sidebar Step Clicked", { type });
    onClick?.();
  };

  return (
    <Link
      onClick={handleClick}
      key={uuid}
      to={path}
      ref={scrollRef}
      className={twMerge(
        "flex flex-auto items-baseline text-charcoal-700 no-underline hover:no-underline active:no-underline focus:no-underline hover:bg-purple-100 rounded-md hover:text-inherit focus:text-inherit",
        uuid === stepOrJobId &&
          "bg-purple-100 ring-2 ring-purple-600 font-semibold relative",
        className,
      )}
    >
      {children}
    </Link>
  );
};
