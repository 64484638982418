import {
  useBuild,
  useMostRecentParallelJobsForStep,
} from "app/components/Playground/BuildContext";
import { CommandStep } from "app/lib/pipeline";
import { sortByState } from "../TableView/TableCell";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { Link } from "react-router-dom";
import PipelineStateIcon, {
  colorForJob,
} from "app/components/shared/PipelineStateIcon/PipelineStateIcon";

interface Props {
  step: CommandStep;
}

export const ParallelStepDetails = ({ step }: Props) => {
  const { build } = useBuild();

  const jobs = useMostRecentParallelJobsForStep(step.uuid);
  jobs.sort(sortByState("asc"));

  return (
    <div className="flex flex-col flex-auto gap-2 pb-2">
      <div
        className="gap-1.5 grid grid-col-dense"
        style={{
          // 75px is the minimum width that seems to handle 1000+ job counts
          gridTemplateColumns: "repeat(auto-fill, minmax(70px, 1fr))",
        }}
      >
        {jobs.map((job) => {
          const index = job.parallelGroupIndex || 0;
          return (
            <Link
              data-testid="parallel-job-link"
              to={`${build?.path}/canvas/${job.id}`}
              key={job.id}
              className={twMerge(
                "p-1.5",
                "border border-slate-500 bg-white rounded-md transition-all flex items-center gap-1 hover:no-underline hover:shadow-md active:shadow-inner duration-200",
                classNames({
                  "border-lime-500": job.state === "finished" && job.passed,
                  "border-red-500 bg-red-100":
                    job.state === "finished" && !job.passed,
                  "border-orange-500 bg-orange-100": job.state === "running",
                }),
              )}
            >
              <span
                className={twMerge(
                  classNames(
                    "rounded white bg-white small tabular-numerals px-1 text-charcoal-700 border border-slate-500 flex-1 justify-center flex",
                    {
                      "border-lime-500": job.state === "finished" && job.passed,
                      "border-red-500": job.state === "finished" && !job.passed,
                      "border-orange-500": job.state === "running",
                    },
                  ),
                )}
              >
                {index + 1}
              </span>

              <PipelineStateIcon
                job={job}
                className={classNames("block shrink-0", colorForJob(job))}
                style={{ width: "14px", height: "14px" }}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};
