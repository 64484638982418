import friendsImage from "app/images/objects/friends.png";
import friendsImage2x from "app/images/objects/friends@2x.png";

export const EmptyResults = () => (
  <div className="text-center py4">
    <img
      width="166"
      height="150"
      className="mb3"
      src={friendsImage}
      srcSet={`${friendsImage} 1x, ${friendsImage2x} 2x`}
      alt=""
    />
    <p className="semi-bold" style={{ fontSize: "1.1em" }}>
      You’re awesome
    </p>
    <p className="dark-gray">Builds may fail, but you never will.</p>
  </div>
);
