import { PropsWithChildren } from "react";
import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";
import { CollapsibleStepListItem } from "./CollapsibleStepListItem";
import { StepLink } from "./StepLink";
import { JobLabel } from "./JobLabel";
import {
  filterJobs,
  GroupByOption,
  sortJobsByState,
  useFilterStore,
} from "../useFilterStore";
import { StepListItem } from "./StepListItem";
import {
  useJobsForStep,
  useMostRecentMatrixJobsForStep,
  useMostRecentParallelJobsForStep,
} from "app/components/Playground/BuildContext";
import {
  CommandStep,
  isDefaultCommandStep,
  isMatrixCommandStep,
  isParallelCommandStep,
} from "app/lib/pipeline";

type Props = PropsWithChildren<{ step: CommandStep }>;
/**
 * An command step list item.
 *
 * Rendered as a single list item unless there are multiple jobs for the step.
 */
export function CommandStepListItem({ step }: Props) {
  switch (true) {
    case isMatrixCommandStep(step):
      return <MatrixCommandStepListItem step={step} />;
    case isParallelCommandStep(step):
      return <ParallelCommandStepListItem step={step} />;
    case isDefaultCommandStep(step):
    default:
      return <DefaultCommandStepListItem step={step} />;
  }
}

function MatrixCommandStepListItem({ step }: Props) {
  const stateFilter = useFilterStore((state) => state.filterBy);
  const groupOption = useFilterStore((state) => state.groupOption);

  let jobs = useMostRecentMatrixJobsForStep(step.uuid);
  jobs = filterJobs(jobs, stateFilter);
  if (groupOption === GroupByOption.State) {
    jobs = [...jobs].sort(sortJobsByState("asc"));
  }

  return (
    <>
      {jobs.map((job) => (
        <StepLink uuid={job.id} className="pl-2" key={job.id} type={step.type}>
          <JobLabel job={job} />
        </StepLink>
      ))}
    </>
  );
}

function ParallelCommandStepListItem({ step }: Props) {
  const stateFilter = useFilterStore((state) => state.filterBy);
  const groupOption = useFilterStore((state) => state.groupOption);

  let jobs = useMostRecentParallelJobsForStep(step.uuid);
  jobs = filterJobs(jobs, stateFilter);
  if (groupOption === GroupByOption.State) {
    jobs = [...jobs].sort(sortJobsByState("asc"));
  }

  return (
    <CollapsibleStepListItem step={step}>
      {jobs?.map((job) => (
        <div key={job.id} className="flex flex-auto pl-1.5">
          <StepLink uuid={job.id} className="pl-2" type={step.type}>
            <JobLabel job={job} />
          </StepLink>
        </div>
      ))}
    </CollapsibleStepListItem>
  );
}

function DefaultCommandStepListItem({ step }: Props) {
  const stateFilter = useFilterStore((state) => state.filterBy);
  const groupOption = useFilterStore((state) => state.groupOption);

  let jobs = useJobsForStep<CommandJob>(step.uuid);
  jobs = filterJobs(jobs, stateFilter);
  if (groupOption === GroupByOption.State) {
    jobs = [...jobs].sort(sortJobsByState("asc"));
  }

  if (jobs.length <= 1) {
    return <StepListItem step={step} />;
  }

  const job = jobs[jobs.length - 1];

  return (
    <StepLink uuid={job.id} className="pl-2" type={step.type}>
      <JobLabel job={job} />
    </StepLink>
  );
}
