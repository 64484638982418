import { useCallback, useEffect, useState } from "react";
import Icon from "app/components/shared/Icon";
import Button from "app/components/shared/Button";
import Dropdown from "app/components/shared/Dropdown";
import UserSessionStore from "app/stores/UserSessionStore";
import { parseTagParams, BRANCH_KEY } from "../tagHelpers";

type Props = {
  branch_name?: string;
  default_branch?: string;
  suite_uuid: string;
  show_custom_branch_input: boolean;
  use_branch_from_tags: boolean;
};

const ALL_BRANCHES = "all branches";
const USER_SESSION_STORE_DEFAULT_BRANCH_KEY = "test-analytics-branch-filter";

const BranchFilter = ({
  branch_name,
  default_branch,
  suite_uuid,
  show_custom_branch_input,
  use_branch_from_tags,
}: Props) => {
  const [customBranchInput, setCustomBranchInput] = useState("");

  const currentBranch =
    branch_name ||
    UserSessionStore.get(
      `${USER_SESSION_STORE_DEFAULT_BRANCH_KEY}:${suite_uuid}`,
    ) ||
    default_branch ||
    ALL_BRANCHES;

  // The current branch is a custom branch if it's not the default_branch or ALL_BRANCHES
  const isCustomBranch =
    show_custom_branch_input &&
    currentBranch !== default_branch &&
    currentBranch !== ALL_BRANCHES;

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const branchInParams = currentUrl.searchParams.get("branch");

    // This is omitted from the `use_branch_from_tags` option as it's not 100% clear why we need it,
    // I would expect the branch to be passed into the component via the view correctly, and not necessary to update here.
    if (!use_branch_from_tags && currentBranch !== branchInParams) {
      currentUrl.searchParams.set("branch", currentBranch);
      // Turbo adds to history state for re-ification of past pages. We need to
      // pass this along when we add the branch param to ensure the back button
      // still works ok.
      window.history.replaceState(window.history.state, "", currentUrl);
    }
  }, [currentBranch, use_branch_from_tags]);

  const onChangeBranch = useCallback(
    (value) => {
      const link = new URL(window.location.href);

      if (use_branch_from_tags) {
        const tags = parseTagParams(link.searchParams.get("tags"));
        let updatedTags;

        if (value === ALL_BRANCHES) {
          // Treat "all branches" as no branch tag
          updatedTags = tags.excludeByKey(BRANCH_KEY);
        } else {
          updatedTags = tags.includeByKey(BRANCH_KEY, value);
        }

        link.searchParams.set("tags", updatedTags.toQueryString());
      } else {
        UserSessionStore.set(
          `${USER_SESSION_STORE_DEFAULT_BRANCH_KEY}:${suite_uuid}`,
          value,
        );

        link.searchParams.set("branch", value);
      }

      window.location.href = link.href;
    },
    [suite_uuid, use_branch_from_tags],
  );

  const handleCustomBranchKeyDown = (event) => {
    if (event.key === "Enter") {
      onChangeBranch(event.target.value);
    }
  };

  return (
    <Dropdown className="flex">
      <Button theme="small" className="font-medium">
        <Icon
          icon="custom/outline/14x/branch"
          style={{
            fontWeight: 400,
            fill: "var(--charcoal-500)",
            width: 15,
            height: 15,
            marginRight: "2px",
          }}
        />

        {currentBranch === ALL_BRANCHES ? "All branches" : currentBranch}

        <Icon
          icon="down-triangle"
          style={{ width: 8, height: 8, marginLeft: "5px" }}
        />
      </Button>

      <div
        className="px-2 py-[3px] flex flex-column gap-2"
        data-testid="dropdown"
      >
        {default_branch != null && (
          <DropdownButton
            onClick={onChangeBranch}
            selected={currentBranch === default_branch}
            value={default_branch}
          />
        )}

        {!show_custom_branch_input && (
          // If using custom branch input don't render this separator before that all branches button
          <div
            role="separator"
            aria-orientation="horizontal"
            className="-mx-2 h-px bg-gray-500"
          />
        )}

        <DropdownButton
          data-testid="allBranches"
          onClick={onChangeBranch}
          selected={currentBranch === ALL_BRANCHES}
          value={ALL_BRANCHES}
        />

        {show_custom_branch_input && (
          <>
            {isCustomBranch && (
              <DropdownButton
                onClick={onChangeBranch}
                selected={isCustomBranch}
                value={currentBranch}
              />
            )}

            <div
              role="separator"
              aria-orientation="horizontal"
              className="-mx-2 h-px bg-gray-500"
            />

            <div className="flex flex-row-reverse items-center p-1 text-sm text-charcoal-700 font-medium">
              <input
                id="custom-branch"
                type="text"
                value={customBranchInput}
                onChange={(event) => setCustomBranchInput(event.target.value)}
                onBlur={(event) => onChangeBranch(event.target.value)}
                onKeyDown={handleCustomBranchKeyDown}
                placeholder="Custom branch"
                className="peer w-full placeholder:text-charcoal-700 cursor-pointer p-1 rounded focus:cursor-text focus:outline focus:outline-2 focus:outline-lime-500 focus:placeholder:text-slate-500"
              />

              <Icon
                icon="heroicons/outline/plus"
                style={{
                  fontWeight: 400,
                  fill: "var(--charcoal-500)",
                  width: 15,
                  height: 15,
                  marginRight: "2px",
                }}
                className="peer-focus:hidden"
              />
            </div>
          </>
        )}
      </div>
    </Dropdown>
  );
};

type DropdownButtonProps = {
  onClick: (string) => void;
  selected: boolean;
  value: string;
};

const DropdownButton = ({ onClick, selected, value }: DropdownButtonProps) => {
  const title = value === ALL_BRANCHES ? "All branches" : value;
  const handleButtonClick = useCallback(() => onClick(value), [value, onClick]);

  return (
    <button
      className="text-left flex p-2 text-sm text-charcoal-700 font-semibold rounded focus:no-underline focus:text-charcoal-700 focus:outline focus:outline-2 focus:outline-lime-500 hover:text-purple-600 data-[active=true]:bg-purple-100 data-[active=true]:text-purple-600 hover:no-underline"
      onClick={handleButtonClick}
      data-turbo="false"
      data-active={selected}
    >
      {title}
    </button>
  );
};

export default BranchFilter;
