import { Build } from "app/stores/BuildShowStore";
import classNames from "classnames";
import Emojify from "app/components/shared/Emojify";
import MaybeLink from "./MaybeLink";
import Badge from "app/components/shared/Badge";
import { useBuild } from "app/components/Playground/BuildContext";
import { WaterfallRowType } from "../../lib/useWaterfallStore";

type TextFormatter = Record<
  WaterfallRowType["label"]["format"],
  (text: string) => React.ReactNode
>;

const textFormatter: TextFormatter = {
  emojify: (text) => <Emojify title={text} text={text} />,
  raw: (text) => <span title={text}>{text}</span>,
  code: (text) => (
    <code title={text} className="monospace">
      {text}
    </code>
  ),
};

type WaterfallRowLabelProps = {
  data: WaterfallRowType;
  depth?: number;
};

function useUrlForRow(data: WaterfallRowType, build: Build) {
  if (Features.BuildSidebar) {
    if (data.type === "trigger") {
      return `${build.path}/waterfall/${data.step_uuid}`;
    } else if (data.type === "command") {
      return `${build.path}/waterfall/${data.job_uuid}`;
    }
    return null;
  }

  return data.job_url;
}

export default function WaterfallRowLabel(props: WaterfallRowLabelProps) {
  const { depth = 0 } = props;
  const { build } = useBuild();

  if (!build) {
    throw "Missing build context";
  }

  const rowUrl = useUrlForRow(props.data, build);

  return (
    <MaybeLink
      data-testid="waterfall-job-link"
      newTab={props.data.type === "trigger" && !Features.BuildSidebar}
      to={rowUrl}
      className={classNames(
        "flex-1 my-0 p-0 flex flex-col divide-y divide-gray-400 text-decoration-none text-inherit hover:text-inherit focus:text-inherit",
      )}
    >
      <div
        className={classNames(
          "flex items-center w-full line-height-4 box-content",
          depth > 0 && "bg-white border-l border-gray-400",
          Features.BuildSidebar ? "h-[37.5px]" : "h-[52px]",
        )}
      >
        <span
          className="font-medium px-[15px] w-full truncate"
          data-testid="row-label"
        >
          {props.data.parallel_group_index &&
            props.data.parallel_group_total && (
              <Badge outline={true} className="charcoal-500 mr-1">
                {props.data.parallel_group_index}/
                {props.data.parallel_group_total}
              </Badge>
            )}
          {textFormatter[props.data.label.format](props.data.label.text)}
        </span>
      </div>
      {props.data.children.length > 0 && (
        <section className="pl-4 py-0 m-0 divide-y divide-gray-400">
          {props.data.children.map((childData) => (
            <WaterfallRowLabel
              key={childData.job_uuid}
              data={childData}
              depth={depth + 1}
            />
          ))}
        </section>
      )}
    </MaybeLink>
  );
}
