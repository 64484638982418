import classNames from "classnames";
import capitalize from "lodash/capitalize";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "app/components/shared/DropdownMenu";
import Icon from "app/components/shared/Icon";
import { useTrack } from "app/components/build/Show/lib/useTrack";

import { GroupByOption, useFilterStore } from "./useFilterStore";

export default function GroupByDropdown() {
  const groupOption = useFilterStore((state) => state.groupOption);
  const setGroupOption = useFilterStore((state) => state.setGroupOption);
  const resetGroup = useFilterStore((state) => state.resetGroup);
  const track = useTrack();

  return (
    <DropdownMenu>
      <div className="flex">
        <DropdownMenuTrigger asChild={true}>
          <button
            className={classNames(
              "group rounded-md inline-flex items-center px-2 py-1 space-x-1 self-stretch text-charcoal-700 hover:bg-purple-100 hover:text-purple-600 transition-colors max-w-full focus:z-10",
              {
                "bg-purple-100 text-purple-600": !!groupOption,
                "rounded-r-none": !!groupOption,
              },
            )}
          >
            <Icon icon="custom/outline/group" className="w-3 h-3 shrink-0" />

            {groupOption && (
              <span className="truncate">
                {"Group by "}
                <span className="font-medium">{capitalize(groupOption)}</span>
              </span>
            )}

            {!groupOption && (
              <>
                <span className="truncate">Group</span>
                <Icon
                  icon="down-triangle"
                  className="flex-none"
                  style={{ width: 7, height: 7 }}
                />
              </>
            )}
          </button>
        </DropdownMenuTrigger>

        {groupOption && (
          <button
            data-testid="reset-group-button"
            onClick={() => {
              track("Clear Sidebar Steps Group Filter");
              resetGroup();
            }}
            className="bg-gray-100 self-stretch text-charcoal-700 hover:text-purple-600 hover:bg-purple-100 rounded-md w-7 flex items-center justify-center border-l-[1px] border-white rounded-l-none"
          >
            <Icon icon="heroicons/16/solid/x-mark" className="w-4 h-4" />
          </button>
        )}
      </div>
      <DropdownMenuPortal>
        <DropdownMenuContent
          align="start"
          alignOffset={1}
          data-testid="group-by-dropdown-content"
        >
          <DropdownMenuItem
            id="default"
            key="default"
            active={!groupOption}
            asChild={true}
          >
            <button
              onClick={() => {
                track("Clear Sidebar Steps Group Filter");
                setGroupOption(null);
              }}
            >
              Default (No grouping)
            </button>
          </DropdownMenuItem>

          {Object.values(GroupByOption).map((option) => (
            <DropdownMenuItem
              id={option}
              key={option}
              asChild={true}
              active={groupOption === option}
            >
              <button
                onClick={() => {
                  track(`Group Sidebar Steps`, { group_by: option });
                  setGroupOption(option);
                }}
              >
                {capitalize(option)}
              </button>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}
