import styled from "styled-components";
import Emojify from "app/components/shared/Emojify";
import CopyButton from "app/components/shared/CopyButton";
import { _trackTALinkClicked } from "app/components/analytics/shared/segmentEvents";
import TagSelectors from "./TagSelectors";

export type MetadataCardType = {
  run_name: string;
  run_url: string;
  location: string | null | undefined;
  tags: {
    key: string;
    value: string;
  }[];
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  border-radius: 3px;
  background: var(--base-0);

  div {
    border-bottom: 1px solid #e0e0e0;
  }

  div:last-child {
    border: 0;
  }
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 100px auto auto;
  align-items: baseline;
  word-break: break-all;
  padding: 15px 0px;
  width: 100%;
`;

const MetadataCard = ({
  run_url,
  run_name,
  location,
  tags,
}: MetadataCardType) => {
  return (
    <HeaderContainer>
      <StyledRow>
        <span className="mr4 bold">Run</span>
        <span className="truncate">
          <a
            href={run_url}
            title={run_name}
            className="lime"
            onClick={() =>
              _trackTALinkClicked({
                linkTo: "run#show",
                linkTitle: run_name,
                url: run_url,
                source: "MetadataCard link",
              })
            }
          >
            <Emojify text={run_name} />
          </a>
        </span>
      </StyledRow>

      {location && (
        <StyledRow>
          <span className="mr4 bold">Location</span>
          <div className="flex items-center gap-0.5 charcoal-700">
            <span className="monospace">{location}</span>
            <CopyButton contentToCopy={location} iconOnly={true} />
          </div>
        </StyledRow>
      )}

      {window.Features.AnalyticsTaggingV1 && tags.length > 0 && (
        <StyledRow>
          <span className="mr4 bold">Tags</span>
          <TagSelectors tags={tags} />
        </StyledRow>
      )}
    </HeaderContainer>
  );
};

export default MetadataCard;
