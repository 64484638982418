import { InputJob } from "app/components/build/Header/pipeline/types/InputJob";
import {
  useBuild,
  useMostRecentJob,
} from "app/components/Playground/BuildContext";
import { UnblockButton } from "app/components/Playground/Node/InputStepNode";
import Icon from "app/components/shared/Icon";
import { BlockStep, InputStep, Outcome, State } from "app/lib/pipeline";
import { useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { StateIcon } from "app/components/Playground/Node/BaseNode/StateIcon";
import { textColorForStep } from "app/components/Playground/Node/colors";
import { twMerge } from "tailwind-merge";
import Emojify from "app/components/shared/Emojify";
import classNames from "classnames";
import { StepRouteParams } from "../../..";
import { useTrack } from "app/components/build/Show/lib/useTrack";

export function InputStepListItem({ step }: { step: InputStep | BlockStep }) {
  const { build } = useBuild();
  const job = useMostRecentJob<InputJob>(step.uuid);
  const navigate = useNavigate();
  const { stepOrJobId } = useParams<StepRouteParams>();
  const track = useTrack();

  const url = `${build?.path}/canvas/${step.uuid}`;
  const label = step.label || "Continue";

  const onClick = useCallback(() => {
    track("Build Sidebar Step Clicked", { type: step.type });
    navigate(`${build?.path}/canvas/${step.uuid}`);
  }, [build?.path, step.uuid, navigate]);

  if (job?.state !== "blocked") {
    return (
      <Link
        to={url}
        onClick={onClick}
        className={twMerge(
          classNames(
            "font-medium flex flex-auto items-baseline justify-start text-charcoal-700 no-underline hover:no-underline active:no-underline focus:no-underline rounded-md hover:text-inherit focus:text-inherit hover:bg-gray-200 pl-2 overflow-hidden",
            {
              "cursor-not-allowed border-0 opacity-40":
                job?.state === "blocked_failed",
              "bg-gray-200": step.uuid === stepOrJobId,
            },
          ),
        )}
      >
        {!job?.unblockedAt && (
          <div className="self-stretch shrink-0 flex items-center justify-center w-7 -ml-2">
            <Icon
              className="text-current h-3 w-3 stroke-2"
              icon="heroicons/solid/pause"
            />
          </div>
        )}

        <div className="flex flex-auto py-1">
          <div className="truncate w-full leading-normal">
            <Emojify text={label} />
          </div>
        </div>

        {job?.unblockedAt && (
          <span className="rounded-[3px] px-0.5 border border-current text-xs bg-white inline-block ml-1">
            Unblocked
          </span>
        )}

        {job?.unblockedAt ? (
          <div className="shrink-0 flex items-center justify-center self-stretch">
            <StateIcon
              state={State.Finished}
              outcome={Outcome.Passed}
              className={twMerge(
                "w-7",
                textColorForStep({
                  state: State.Finished,
                  outcome: Outcome.Passed,
                }),
              )}
            />
          </div>
        ) : (
          <div className="shrink-0 self-stretch flex items-center bg-gray-600 w-7 justify-center">
            <Icon icon="arrow-right" className="h-4 w-4 text-white" />
          </div>
        )}
      </Link>
    );
  }

  return (
    <div onClick={onClick}>
      {job && (
        <UnblockButton
          job={job}
          className="hover:bg-purple-100 font-medium flex flex-auto items-baseline text-charcoal-700 no-underline hover:no-underline active:no-underline focus:no-underline rounded-md hover:text-inherit justify-start focus:text-inherit relative border-2 border-purple-600 p-0 w-full text-left"
        >
          <div className="self-stretch shrink-0 flex items-center justify-center w-7">
            <Icon
              className="text-current h-3 w-3 stroke-2"
              icon="heroicons/solid/pause"
            />
          </div>
          <div className="flex flex-auto py-1">
            <div className="truncate w-full leading-normal">
              <Emojify text={label} />
            </div>
          </div>
          <div className="shrink-0 self-stretch flex items-center bg-purple-600 w-7 justify-center">
            <Icon icon="arrow-right" className="h-4 w-4 text-white" />
          </div>
        </UnblockButton>
      )}
    </div>
  );
}
