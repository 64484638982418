import { useCallback, useState, Ref } from "react";
import Skeleton from "react-loading-skeleton";
import partition from "lodash/partition";
import useFetch from "use-http";

import Pagination from "app/components/shared/Pagination";
import usePagination, {
  PaginationProps,
} from "app/components/analytics/shared/hooks/usePagination";
import ErrorState from "app/components/analytics/shared/ErrorState";
import { Suite } from "app/components/analytics/shared/type";
import { track } from "app/lib/segmentAnalytics";
import ListItem, { NAVATAR_DIMENSIONS } from "./ListItem";
import EmptyState from "app/components/shared/EmptyState";

type Props = {
  endpoint: string;
  current_account_member: boolean;
  query: string | null;
};

type SuiteProps = {
  suite: Suite;
  path: string;
};

const LoadingState = () => {
  return (
    <>
      {[...Array(8)].map((_el, index) => (
        <div
          key={index}
          className="flex items-center border rounded-3 border-gray mb2 gap4 p6"
        >
          <div className="flex items-center gap4">
            <Skeleton
              containerClassName="flex-1"
              width={NAVATAR_DIMENSIONS}
              height={NAVATAR_DIMENSIONS}
            />
            <Skeleton containerClassName="flex-1" width="200px" />
          </div>
          <Skeleton containerClassName="flex-1 text-right" width="180px" />
        </div>
      ))}
    </>
  );
};

const List = ({ endpoint, current_account_member, query }: Props) => {
  const [refetchSuites, setRefetchSuites] = useState(false);
  const { currentData, pageCount, handleOnChange, loading, error } =
    usePagination({ endpoint, refetch: refetchSuites });

  const favoriteSuiteFetch = useFetch({
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
    },
  });

  const handleBookmarkClick = useCallback(async (suite, buttonRef) => {
    buttonRef.current.disabled = true;

    _trackBookmarkButtonClicked({
      alreadyBookmarked: suite.favorite,
      suite_id: suite.id,
    });

    if (suite.favorite) {
      await favoriteSuiteFetch.del(suite.unfavorite_endpoint);
    } else {
      await favoriteSuiteFetch.post(suite.favorite_endpoint);
    }

    if (favoriteSuiteFetch.response.ok) {
      setRefetchSuites(true);
    } else {
      buttonRef.current.disabled = false;
    }

    setRefetchSuites(false);
  }, []);

  return (
    <ListData
      currentData={currentData}
      pageCount={pageCount}
      handleOnChange={handleOnChange}
      loading={loading}
      error={error}
      currentAccountMember={current_account_member}
      onBookmarkClick={handleBookmarkClick}
      query={query}
    />
  );
};

type ListDataPaginationProps = Omit<PaginationProps, "currentData"> & {
  currentData: Array<SuiteProps>;
  currentAccountMember: boolean;
  onBookmarkClick: (suite: Suite, buttonRef: Ref<HTMLButtonElement>) => void;
  query: string | null;
};

export const ListData = ({
  currentData,
  loading,
  error,
  pageCount,
  handleOnChange,
  currentAccountMember,
  onBookmarkClick,
  query,
}: ListDataPaginationProps) => {
  if (error) {
    return (
      <ErrorState
        emoji="🕳️"
        heading="There was an error loading the test suites"
        subheading="Try refreshing the page; if the problem persists"
        urlText="let us know"
        url="mailto:support@buildkite.com"
      />
    );
  }

  if (!loading && !!query && currentData.length === 0) {
    return (
      <EmptyState
        emoji="🕳️"
        heading={`No suites with the name or slug "${query}"`}
      />
    );
  }

  const [favoriteSuites, remainingSuites] = partition(
    currentData,
    (suite) => suite.suite.favorite,
  );

  return (
    <div>
      {loading ? (
        <LoadingState />
      ) : (
        <>
          {favoriteSuites.length > 0 && currentAccountMember && (
            <section className="ta-suites-bookmark-wrapper">
              <h2 className="hide">Favorite suites</h2>
              <ul className="list-reset container">
                {favoriteSuites.map((suite) => (
                  <ListItem
                    key={suite.suite.id}
                    suite={suite.suite}
                    path={suite.path}
                    currentAccountMember={currentAccountMember}
                    onBookmarkClick={onBookmarkClick}
                  />
                ))}
              </ul>
            </section>
          )}

          <section>
            <ul className="list-reset">
              {remainingSuites.map((suite) => (
                <ListItem
                  key={suite.suite.id}
                  suite={suite.suite}
                  path={suite.path}
                  currentAccountMember={currentAccountMember}
                  onBookmarkClick={onBookmarkClick}
                />
              ))}
            </ul>
          </section>
        </>
      )}
      <Pagination pageCount={pageCount} onPageChange={handleOnChange} />
    </div>
  );
};

const _trackBookmarkButtonClicked = ({
  alreadyBookmarked,
  suite_id,
}: {
  alreadyBookmarked: boolean;
  suite_id: string;
}) => {
  track("TA Suite Bookmark button clicked", {
    source: "ListItem",
    action: alreadyBookmarked
      ? "suite_favorite#destroy"
      : "suite_favorite#create",
    bookmarked: alreadyBookmarked ? "Bookmark removed" : "Bookmark saved",
    suite_id: suite_id,
  });
};

export default List;
