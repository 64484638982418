import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import BuildStatusDescription from "app/components/shared/BuildStatusDescription";
import Duration from "app/components/shared/Duration";
import Emojify from "app/components/shared/Emojify";
import UserAvatar from "app/components/shared/UserAvatar";
import { buildTime } from "app/lib/builds";
import { shortMessage, shortCommit } from "app/lib/commits";

type Props = {
  build: any;
};

class BuildTooltip extends PureComponent<Props> {
  render() {
    const { createdBy } = this.props.build;

    return (
      <div className="flex items-top mx2 my1">
        <div className="no-flex mr2 text-center">
          {createdBy && createdBy.avatar ? (
            <UserAvatar
              user={{
                avatar: createdBy.avatar,
                name: createdBy.name || createdBy.maybeName,
              }}
              className="block"
              style={{ width: 30, height: 30, minWidth: 30, minHeight: 30 }}
            />
          ) : null}
          <small className="dark-gray">
            <Duration.Micro
              {...buildTime(this.props.build)}
              tabularNumerals={false}
            />
          </small>
        </div>
        <div className="flex-auto line-height-2">
          <span className="block line-height-3 overflow-hidden overflow-ellipsis">
            <Emojify
              className="semi-bold mr1"
              text={shortMessage(this.props.build.message)}
            />
            <span className="dark-gray monospace">
              {this.renderBranchOrCommit()}
            </span>
          </span>
          <small className="dark-gray">
            <BuildStatusDescription
              build={this.props.build}
              updateFrequency={0}
            />
          </small>
        </div>
      </div>
    );
  }

  renderBranchOrCommit() {
    if (this.props.build.pipeline.defaultBranch) {
      return shortCommit(
        this.props.build.commit,
        (this.props.build.pipeline &&
          this.props.build.pipeline.commitShortLength) ||
          undefined,
      );
    }
    return this.props.build.branch;
  }
}

export default createFragmentContainer(BuildTooltip, {
  build: graphql`
    fragment BuildTooltip_build on Build {
      message
      url
      branch
      commit
      state
      createdAt
      startedAt
      finishedAt
      canceledAt
      scheduledAt
      pipeline {
        commitShortLength
        defaultBranch
      }
      createdBy {
        ... on User {
          name
          avatar {
            url
          }
        }
        ... on UnregisteredUser {
          maybeName: name
          avatar {
            url
          }
        }
      }
    }
  `,
});
