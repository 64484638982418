import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

import Emojify from "app/components/shared/Emojify";
import { _trackTALinkClicked } from "app/components/analytics/shared/segmentEvents";
import { DropdownContainer } from "app/components/shared/Dropdown";
import Button from "app/components/shared/Button";
import calculateViewportOffsets from "app/components/shared/Popover/calculate-viewport-offsets";
import Activity from "app/components/analytics/shared/Activity";
import { ActivityProps } from "app/components/analytics/shared/Activity/Bar";
import { LoadingChart } from "app/components/analytics/shared/LoadingStates";

export interface Test {
  activity: {
    activities: Array<ActivityProps>;
    limit: number;
  };
  reliability: string;
  failedCount: number;
  p50Duration: number;
  p95Duration: number;
  url: string;
}

type TestTooltipProps = {
  test?: Test;
  loading: boolean;
  wrapperNode: HTMLElement;
};

const ContentWrapper = styled.div`
  font-size: 14px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  hr {
    margin: 0;
    border-bottom: 1px dotted var(--gray-500);
    border-top: 0;
    flex: auto;
    margin-right: 15px;
  }

  span {
    font-weight: 400;
  }
`;

const _trackTestLinkClicked = ({ href }: { href: string }) => {
  _trackTALinkClicked({
    source: "Analytics/Shared/Tooltips/TestTooltip",
    linkTo: "test#show",
    linkTitle: "View test",
    url: href,
  });
};

const LoadingState = () => (
  <div data-testid="loading">
    <Skeleton className="mb4" width="60%" />
    <LoadingChart barWidth="15px" />
    <Skeleton className="mt4 mb2" width="90%" />
    <Skeleton count={4} />
    <Skeleton height="30px" className="mt2" />
  </div>
);

const TestContent = ({ test }: { test: Test }) => (
  <ContentWrapper>
    <p className="mt0 mb3">Most recent {test.activity.limit} executions</p>
    <Activity
      activities={test.activity.activities}
      disableTooltips={true}
      wrapperHeight="40px"
    />
    <div className="bg-slate-100 p4 mt2 mb2">
      <p>Last 7 days</p>
      <ul className="list-reset m0">
        <ListItem className="mb2">
          Reliability
          <hr />
          <span>{test.reliability}</span>
        </ListItem>
        <ListItem className="mb2">
          Failures
          <hr />
          <span>{test.failedCount}</span>
        </ListItem>
        <ListItem className="mb2">
          Duration (p50)
          <hr />
          <span>{test.p50Duration}</span>
        </ListItem>
        <ListItem>
          Duration (p95)
          <hr />
          <span>{test.p95Duration}</span>
        </ListItem>
      </ul>
    </div>
    <Button
      as="a"
      href={test.url}
      target="_blank"
      rel="noreferrer"
      theme="primary"
      className="block text-center"
      onClick={() => _trackTestLinkClicked({ href: test.url })}
    >
      View test
    </Button>
  </ContentWrapper>
);

const TestTooltip = ({ test, loading, wrapperNode }: TestTooltipProps) => {
  const [showing, setShowing] = useState(false);
  const { offsetX, offsetY, width } = calculateViewportOffsets(
    280,
    wrapperNode,
  );

  useEffect(() => {
    // Delay the rendering of the Tooltip to trigger an animation
    const showingTimeout = setTimeout(() => {
      setShowing(true);
    }, 100);

    return () => {
      clearTimeout(showingTimeout);
    };
  }, []);

  return (
    <DropdownContainer
      offsetX={offsetX}
      offsetY={offsetY}
      width={width}
      mountOnEnter={true}
      in={showing}
      nibOffsetX={0}
      timeout={200}
    >
      <div className="p4 system">
        {loading ? (
          <LoadingState />
        ) : test ? (
          <TestContent test={test} />
        ) : (
          <div className="flex flex-column text-center justify-center">
            <Emojify
              text="🤖"
              style={{ fontSize: 45 }}
              title="Test not found"
            />
            <h5 className="mb2">Test not found in Test Analytics</h5>
            <p>
              This could be because it is still uploading, or there could be an
              issue. Check again soon.
            </p>
          </div>
        )}
      </div>
    </DropdownContainer>
  );
};

export default TestTooltip;
