/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from "react";

import { track } from "app/lib/segmentAnalytics";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "app/components/shared/HoverCard";
import RemoteButtonComponent from "app/components/shared/RemoteButtonComponent";
import Spinner from "app/components/shared/Spinner";
import BuildShowStore, { Job } from "app/stores/BuildShowStore";
import BuildsStore from "app/stores/BuildsStore";
import { twMerge } from "tailwind-merge";

interface Props {
  build: {
    path: string;
    retryFailedJobsPath?: string;
    permissions: {
      retry: {
        allowed: boolean;
      };
    };
    canRetryFailedJobs: boolean;
    canRetryFailedTriggeredJobs: boolean;
    jobs?: Job[];
  };
  store: BuildShowStore | BuildsStore;
  className?: string;
}

export default function RetryFailedJobsButton({
  build,
  store,
  className,
}: Props) {
  // We set isLoading when the request starts, but we don't unset it once the
  // request finishes. We want to keep the spinner going until the build starts
  // running again, at which point this will be unrendered.
  const [isLoading, setIsLoading] = useState(false);

  // When new jobs start, clear the loading state
  useEffect(() => {
    setIsLoading(false);
  }, [build.jobs?.length, build.canRetryFailedTriggeredJobs]);

  function handleBeforeStart() {
    setIsLoading(true);
    track("Retry Failed Jobs Clicked", {
      text: "Retry failed jobs",
      source: "Rebuild Dropdown",
    });
  }

  function handleSuccess(_event: Event, response: any) {
    if (response) {
      store.loadAndEmit(response);
    }
  }

  function handleError() {
    window.location.assign(build.path || "");
  }

  if (
    !build.permissions.retry.allowed ||
    (!build.canRetryFailedJobs && !build.canRetryFailedTriggeredJobs)
  ) {
    return null;
  }

  return (
    <HoverCard>
      <HoverCardTrigger className="text-inherit hover:text-inherit active:text-inherit">
        <RemoteButtonComponent
          url={build.retryFailedJobsPath}
          method="post"
          loading={isLoading}
          loadingText="Retrying jobs&hellip;"
          loadingIndicator={<Spinner />}
          className={twMerge(
            "btn btn-flex gap-1 hover:text-inherit active:text-inherit",
            className,
          )}
          onBeforeStart={handleBeforeStart}
          onSuccess={handleSuccess}
          onError={handleError}
          title="Retry failed jobs"
        >
          {!build.canRetryFailedJobs && build.canRetryFailedTriggeredJobs
            ? "Retry failed triggered jobs"
            : "Retry failed jobs"}
        </RemoteButtonComponent>
      </HoverCardTrigger>

      <HoverCardContent size="max-lg">
        {!build.canRetryFailedJobs && build.canRetryFailedTriggeredJobs
          ? "Retry failed jobs in synchronous trigger builds"
          : "Retry failed jobs in this build, and in any synchronous trigger builds"}
      </HoverCardContent>
    </HoverCard>
  );
}
