import { BuildView } from "..";

export function urlForView(
  buildPath: string,
  view?: BuildView,
  stepOrJobId?: string,
) {
  switch (view) {
    case BuildView.Canvas:
      return stepOrJobId
        ? `${buildPath}/canvas/${stepOrJobId}`
        : `${buildPath}/canvas`;
    case BuildView.Waterfall:
      return stepOrJobId
        ? `${buildPath}/waterfall/${stepOrJobId}`
        : `${buildPath}/waterfall`;
    case BuildView.Table:
      return stepOrJobId
        ? `${buildPath}/table/${stepOrJobId}`
        : `${buildPath}/table`;
    case BuildView.TestDigest:
      return `${buildPath}/test-digest`;
    case BuildView.Summary:
      return `${buildPath}/summary`;
    default:
      return `${buildPath}`;
  }
}
