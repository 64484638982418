import { create } from "zustand";
import { set as _set } from "lodash/fp";

export type TestPlan = {
  id: string;
  stepId: string;
  statistics: {
    results: {
      passed_on_first_run: number;
      passed_on_retry: number;
      muted_failed: number;
      muted_passed: number;
      failed: number;
      skipped: number;
      total: number;
    };
  };
};

interface BktecBadgeStore {
  testPlans: Record<string, TestPlan>;
  setTestPlan: (testPlan: TestPlan) => void;
}

export const useTestEngineStore = create<BktecBadgeStore>((set) => ({
  testPlans: {},
  setTestPlan: (testPlan) => set(_set(["testPlans", testPlan.id], testPlan)),
}));
