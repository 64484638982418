import {
  default as React,
  Children,
  createRef,
  createContext,
  createElement,
  PureComponent,
  ReactNode,
  ComponentType,
} from "react";
import classNames from "classnames";
import unified from "unified";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import rehypeReact from "rehype-react";
import styled, { keyframes } from "styled-components";
import highlightWords from "highlight-words";
import copy from "copy-to-clipboard";
import { track } from "app/lib/segmentAnalytics";

import Bugsnag from "app/lib/Bugsnag";

import SearchField from "app/components/shared/SearchField";
import SectionLoader from "app/components/shared/SectionLoader";
import Icon from "app/components/shared/Icon";

/* eslint-disable react/no-multi-comp */

// The context which provides the syntax highlighting we later use
const HighlightContext = createContext<string>("");

// Highlight mark component, with styles pinched from the docs site's search
const HighlightMark = styled.mark`
  color: #14cc80;
  background: rgba(20, 204, 128, 0.1);
  padding: 0;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px 0 0 rgba(20, 204, 128, 0.8);
`;

type HighlightedElementProps = {
  children: Node | null | undefined;
  outerTag: string | ComponentType<Record<any, any>>;
  highlightTag: string | ComponentType<Record<any, any>>;
  highlightProps?: any;
};

/// Render a React element, with any instances `HighlightContext` turned into `highlightTag` elements.
const HighlightedElement = ({
  children,
  outerTag: OuterTag,
  highlightTag: HighlightTag,
  highlightProps,
  ...props
}: HighlightedElementProps) => (
  <HighlightContext.Consumer>
    {(highlightText) => {
      const content = highlightText
        ? Children.map(children, (child) => {
            if (typeof child === "string") {
              const chunks = highlightWords({
                text: child,
                query: highlightText,
                matchExactly: true,
              });

              if (chunks.length > 0) {
                return chunks.map((chunk) =>
                  chunk.match ? (
                    <HighlightTag {...highlightProps} key={chunk.key}>
                      {chunk.text}
                    </HighlightTag>
                  ) : (
                    <span key={chunk.key}>{chunk.text}</span>
                  ),
                );
              }
            }

            return child;
          })
        : children;

      return <OuterTag {...props}>{content}</OuterTag>;
    }}
  </HighlightContext.Consumer>
);

HighlightedElement.defaultProps = {
  outerTag: "span",
  highlightTag: HighlightMark,
};

// Code component, with styles pinched from the agent docs (we should probably share this some other way!)
const Code = styled(HighlightedElement).attrs({
  outerTag: "code",
})`
  font-family: "SFMono-Regular", Monaco, Menlo, Consolas, "Liberation Mono",
    Courier, monospace;
  font-size: 0.9em;
`;

// Figure component, allowing for Markdown code blocks to have captions
const Figure = styled(HighlightedElement).attrs({
  outerTag: "figure",
  className: "flex flex-column mx0 my1 rounded border border-gray bg-silver",
})`
  width: 100%;

  > figcaption {
    padding: 10px;
    font-weight: bold;
  }

  > figcaption:first-child {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  > figcaption:last-child {
    border-top-style: solid;
    border-top-width: 1px;
  }

  > pre.code {
    background: white;
    border: none;
    border-radius: none;
    margin: 0;
  }
`;

const CopyButton = styled.button.attrs({
  className: "dark-gray hover-lime flex-none",
})`
  appearance: none;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

const flashAnimation = keyframes`
  0% { background-color: #FFFDD3; }
  100% { background-color: transparent; }
`;

const AttributeContentContainer = styled.div<{ flashContent?: boolean }>`
  animation-name: ${flashAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: ${(props) => (props.flashContent ? 1 : 0)};
  animation-delay: 0;
`;

type CopyableFigcaptionProps = {
  children: Node;
  className: string | null | undefined;
};

type CopyableFigcaptionState = {
  justCopied: boolean | null | undefined;
};

class CopyableFigcaption extends PureComponent<
  CopyableFigcaptionProps,
  CopyableFigcaptionState
> {
  element = createRef<HTMLElement>();

  state = {
    justCopied: false,
  };

  handleCopyClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (this.element.current) {
      // Accept either the next or previous element sibling,
      // as we may want to use the figcaption as a footer
      const siblingElement =
        this.element.current.nextElementSibling ||
        this.element.current.previousElementSibling;

      if (siblingElement) {
        // @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
        copy(siblingElement.textContent);

        this.setState({ justCopied: true }, () => {
          setTimeout(() => this.setState({ justCopied: false }), 1500);
        });
      }
    }
  };

  render() {
    const { children, className, ...props } = this.props;

    return (
      <figcaption
        {...props}
        className={classNames("border-gray flex", className)}
        ref={this.element}
      >
        {/* @ts-expect-error - TS2322 - Type '{ children: Node & ReactNode; className: string; }' is not assignable to type 'IntrinsicAttributes & Pick<HighlightedElementProps, "children" | "highlightProps"> & Partial<Pick<HighlightedElementProps, "outerTag" | "highlightTag">> & Partial<...>'. */}
        <HighlightedElement className="flex-auto">
          {children}
        </HighlightedElement>
        <CopyButton onClick={this.handleCopyClick} title="Copy">
          {this.state.justCopied ? "Copied " : ""}
          <Icon icon="copy" />
        </CopyButton>
      </figcaption>
    );
  }
}

const PROP_OVERRIDES: {
  [key: string]: any;
} = {
  figcaption: {
    className: "border-gray",
  },
  pre: {
    className: "code my1",
  },
  // eslint-disable-next-line id-length
  a: {
    className: "lime hover-lime text-decoration-none hover-underline",
    target: "_blank",
    rel: "noreferrer noopener",
  },
};

type MarkdownProps = {
  disallowLinks?: boolean;
  children: Node;
};

class Markdown extends PureComponent<MarkdownProps> {
  createElement = (elementType, props = {}, ...children) => {
    if (typeof elementType === "string") {
      // If we're being asked about an anchor component, and we've been told to
      // disallow links, we convert it into a <span> instead, so they're not
      // clickable elements.
      if (elementType === "a" && this.props.disallowLinks) {
        elementType = "span";
      }

      // Override props if we have any specific overrides
      if (elementType in PROP_OVERRIDES) {
        Object.assign(props, PROP_OVERRIDES[elementType]);
      }

      // Override HTML element types within the Markdown
      // content to be HighlightedElements
      // @ts-expect-error - TS2339 - Property 'outerTag' does not exist on type '{}'.
      props.outerTag = elementType;
      elementType = HighlightedElement;
    }

    return createElement(elementType, props, ...children);
  };

  renderMarkdown(markdown: React.ReactNode) {
    return (
      unified()
        // @ts-expect-error - TS2769 - No overload matches this call.
        .use(remarkParse)
        // @ts-expect-error - TS2345 - Argument of type 'Plugin<[] | [Processor<any, any, any, any>, (Options | undefined)?] | [null | undefined, (Options | undefined)?] | [Options], Root, Root>' is not assignable to parameter of type 'Plugin<[] | [Processor<any, any, any, any>, (Options | undefined)?] | [null | undefined, (Options | undefined)?] | [Options], Settings>'.
        .use(remarkRehype, { allowDangerousHtml: true })
        .use(rehypeRaw)
        .use(rehypeSanitize)
        .use(rehypeReact, {
          createElement: this.createElement,
          components: {
            // @ts-expect-error - TS2322 - Type 'StyledComponent<{ ({ children, outerTag: OuterTag, highlightTag: HighlightTag, highlightProps, ...props }: HighlightedElementProps): Element; defaultProps: { outerTag: string; highlightTag: StyledComponent<"mark", any, {}, never>; }; }, any, { ...; }, "outerTag">' is not assignable to type 'ComponentLike<DetailedReactHTMLElement<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>'.
            code: Code,
            // @ts-expect-error - TS2322 - Type 'StyledComponent<{ ({ children, outerTag: OuterTag, highlightTag: HighlightTag, highlightProps, ...props }: HighlightedElementProps): Element; defaultProps: { outerTag: string; highlightTag: StyledComponent<"mark", any, {}, never>; }; }, any, { ...; }, "className" | "outerTag">' is not assignable to type 'ComponentLike<DetailedReactHTMLElement<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>'.
            figure: Figure,
            // @ts-expect-error - TS2322 - Type 'typeof CopyableFigcaption' is not assignable to type 'ComponentLike<DetailedReactHTMLElement<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>'.
            figcaption: CopyableFigcaption,
          },
        })
        // @ts-expect-error - TS2345 - Argument of type 'ReactNode' is not assignable to parameter of type 'VFileCompatible'. | TS2339 - Property 'result' does not exist on type 'VFile'.
        .processSync(markdown).result
    );
  }

  render() {
    // We want to pass through all props except
    // `disallowLinks` & `children` here
    const { disallowLinks: _disallowLinks, children, ...props } = this.props;

    return <div {...props}>{this.renderMarkdown(children)}</div>;
  }
}

const ListItemTitle = styled.h3`
  margin: 0;
  line-height: 22px;
  font-weight: bold;
`;

type RetrievedContent = {
  [key: string]: ContentCategory;
};

// Top-level categories - ie "Steps" / "Notify"
type ContentCategory = {
  name: string;
  textContent: string;
  attributes: Array<ContentItem>;
};

// ie "Command" / "Wait" / "Block"
type ContentItem = {
  name: string;
  shortDescription: string;
  docsURL?: string;
  textContent: string;
  attributes: Array<ContentAttribute>;
};

// ie "Soft Fail" / "Email"
type ContentAttribute = {
  name: string;
  isRequired?: boolean;
  textContent: string;
  docsURL?: string;
};

type SidebarState = {
  filterText: string;
  activeContentItem: ContentItem | null | undefined; // set when a contentItem is clicked, ie from the main sidebar list,
  activeContentAttribute: ContentAttribute | null | undefined; // set when an individual contentAttribute is clicked in search results, so we can scroll to/highlight it,
  retrievedContent: RetrievedContent;
  loading: boolean;
  loadingFailed: boolean;
};

type SidebarProps = {
  trackingContext: {
    organizationUuid: string;
  };
};

// Filter which matches either in the ContentAttribute's name, or the first line of the `textContent` value.
const contentAttributeDoesMatchFilter = (
  { name, textContent }: ContentAttribute,
  filterText: string,
) => {
  // If the name matches, we return immediately to avoid more complicated tests
  if (name.indexOf(filterText) !== -1) {
    return true;
  }

  // First, quickly find the first line ending, or just the
  // length of the whole string if there isn't one
  let firstLineOrEndIndex = textContent.indexOf("\n");
  if (firstLineOrEndIndex === -1) {
    firstLineOrEndIndex = textContent.length;
  }

  // Then, let's grab that chunk of the string, and strip out links,
  // and find the first index of a match in the textContent
  return (
    textContent
      .slice(0, firstLineOrEndIndex)
      .replace(/!?\[([^\]]+)\]\([^)]+\)/g, "$1")
      .indexOf(filterText) !== -1
  );
};

export default class PipelineEditorSidebar extends PureComponent<
  SidebarProps,
  SidebarState
> {
  state: SidebarState = {
    filterText: "",
    activeContentItem: null,
    activeContentAttribute: null,
    retrievedContent: {},
    loading: true,
    loadingFailed: false,
  };

  fetchReference() {
    this.setState({ loading: true, loadingFailed: false });

    // Allow fetching the data from the local dev environment
    // if we're running in development and add ?local-docs to the URL
    const docsHost =
      process.env.NODE_ENV === "development" &&
      window.location.search &&
      window.location.search.indexOf("local-docs") !== -1
        ? "http://localhost:3000"
        : "https://buildkite.com";

    fetch(`${docsHost}/docs/quick-reference/pipelines.json`, {
      // XXX: This fails CORS in development and test
      // headers: { 'X-Buildkite-Frontend-Version': BUILDKITE_FRONTEND_VERSION }
    })
      .then((response) => response.json())
      .then((retrievedContent) => {
        this.setState({ retrievedContent, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false, loadingFailed: true });
      });
  }

  componentDidMount() {
    this.fetchReference();
  }

  handleFilterTextChange = (filterQuery: string) => {
    this.setState({ filterText: filterQuery });
  };

  handleActiveContentItemUpdate = (contentItem: ContentItem) => {
    this.setState({ activeContentItem: contentItem, filterText: "" });
    _trackContentItemClicked(contentItem, this.props.trackingContext);
  };

  handleClearContentItem = (event: React.SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({
      activeContentItem: null,
      activeContentAttribute: null,
      filterText: "",
    });
  };

  handleActiveContentAttributeUpdate = (contentAttribute: ContentAttribute) => {
    this.setState({ activeContentAttribute: contentAttribute, filterText: "" });
  };

  handleRefreshClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.fetchReference();
  };

  renderActiveContentItem() {
    if (!this.state.activeContentItem) {
      return null;
    }

    const matchedContentAttributes = this.state.activeContentItem.attributes
      .filter((contentAttribute: ContentAttribute) =>
        contentAttributeDoesMatchFilter(
          contentAttribute,
          this.state.filterText,
        ),
      )
      .map((contentAttribute) => (
        <FilteredContentAttributeView
          key={contentAttribute.name}
          contentAttribute={contentAttribute}
          activeItem={contentAttribute === this.state.activeContentAttribute}
        />
      ));

    if (!this.state.activeContentItem) {
      return null;
    }

    return (
      <div style={{ height: "100%" }}>
        <a
          className="bold dark-gray text-decoration-none hover-underline block p2 pb0"
          href="#"
          onClick={this.handleClearContentItem}
        >
          ← Back to Reference
        </a>
        <div
          className="p2"
          style={{ height: "calc(100% - 50px)", overflowY: "auto" }}
          id="sidebar-results-container"
        >
          <div className="flex flex-wrap items-center mb2">
            <h2 className="flex-auto my0">
              {this.state.activeContentItem.name}
            </h2>
            {this.state.activeContentItem.docsURL && (
              <a
                className="flex-none ml-auto lime hover-lime text-decoration-none hover-underline"
                href={this.state.activeContentItem.docsURL}
                target="_blank"
                rel="noreferrer noopener"
              >
                View in Docs
              </a>
            )}
          </div>
          {this.state.activeContentItem.textContent ? (
            // @ts-expect-error - TS2769 - No overload matches this call.
            <Markdown>{this.state.activeContentItem.textContent}</Markdown>
          ) : this.state.activeContentItem.shortDescription ? (
            <p>{this.state.activeContentItem.shortDescription}</p>
          ) : null}
          {this.state.activeContentItem.attributes.length > 0 && (
            <HighlightContext.Provider value={this.state.filterText}>
              <h3>Attributes</h3>
              <SearchField
                className="my2"
                onChange={this.handleFilterTextChange}
                placeholder="Filter attributes"
              />
              {matchedContentAttributes.length > 0 ? (
                matchedContentAttributes
              ) : (
                <p className="dark-gray">
                  No {this.state.activeContentItem.name} attributes match “
                  {this.state.filterText}”.
                </p>
              )}
            </HighlightContext.Provider>
          )}
        </div>
      </div>
    );
  }

  renderFilteredContentList() {
    const matchedContent = (Object.values(this.state.retrievedContent) as any)
      .map((contentCategory: ContentCategory) => contentCategory.attributes)
      .flat()
      .map((contentItem: ContentItem) =>
        contentItem.attributes
          .filter((contentAttribute: ContentAttribute) =>
            contentAttributeDoesMatchFilter(
              contentAttribute,
              this.state.filterText,
            ),
          )
          .map((contentAttribute: ContentAttribute) => (
            <FilteredContentAttributeView
              key={`${contentItem.name}/${contentAttribute.name}`}
              contentAttribute={contentAttribute}
              contextContentItem={contentItem}
              onClick={() => {
                this.handleActiveContentItemUpdate(contentItem);
                this.handleActiveContentAttributeUpdate(contentAttribute);
              }}
            />
          )),
      )
      .flat();

    return (
      <HighlightContext.Provider value={this.state.filterText}>
        <div className="flex flex-column" style={{ height: "100%" }}>
          <SearchField
            className="mx2 mt2"
            onChange={this.handleFilterTextChange}
          />
          <div className="pt2" style={{ overflowY: "auto" }}>
            {matchedContent.length > 0 ? (
              matchedContent
            ) : (
              <p className="dark-gray px2">
                No matches found for “{this.state.filterText}”.
              </p>
            )}
          </div>
        </div>
      </HighlightContext.Provider>
    );
  }

  renderContentItemList() {
    return (
      <HighlightContext.Provider value={this.state.filterText}>
        <div className="flex flex-column" style={{ height: "100%" }}>
          <SearchField
            className="mx2 mt2"
            onChange={this.handleFilterTextChange}
          />
          <div style={{ overflowY: "auto" }}>
            {(Object.values(this.state.retrievedContent) as any).map(
              (contentCategory: ContentCategory) => (
                <div className="mb4" key={contentCategory.name}>
                  <h2 className="bold my2 px2">{contentCategory.name}</h2>
                  <div className="my2 px2">
                    {/* @ts-expect-error - TS2769 - No overload matches this call. */}
                    <Markdown>{contentCategory.textContent}</Markdown>
                  </div>
                  {contentCategory.attributes.map((contentItem) => (
                    <ContentItemListItem
                      key={contentItem.name}
                      contentItem={contentItem}
                      onClick={this.handleActiveContentItemUpdate}
                    />
                  ))}
                </div>
              ),
            )}
          </div>
        </div>
      </HighlightContext.Provider>
    );
  }

  render() {
    if (this.state.loading) {
      return <SectionLoader />;
    } else if (this.state.loadingFailed) {
      return (
        <div className="flex flex-column items-center text-center dark-gray">
          <p>Unable to load the pipeline reference.</p>
          <a
            className="lime hover-lime text-decoration-none hover-underline"
            href="#"
            onClick={this.handleRefreshClick}
          >
            Retry?
          </a>
        </div>
      );
    } else if (this.state.activeContentItem) {
      return this.renderActiveContentItem();
    } else if (this.state.filterText) {
      return this.renderFilteredContentList();
    }

    return this.renderContentItemList();
  }
}

const StackableListLinkAnchor = styled.a.attrs({
  href: "#",
  className:
    "flex items-center text-decoration-none color-inherit border-gray hover-bg-silver focus-bg-silver py2",
})`
  border-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;

  & + & {
    border-top-style: none;
  }
`;

type StackableListLinkProps = {
  children: Node;
};

const StackableListLink = ({ children, ...props }: StackableListLinkProps) => (
  <StackableListLinkAnchor {...props}>
    {children}
    <Icon icon="chevron-right" className="ml1 flex-none" />
  </StackableListLinkAnchor>
);

const ICON_MAPPING: {
  [key: string]: string;
} = {
  "pipelines/command-step": "stepTypeCommand",
  "pipelines/wait-step": "stepTypeWait",
  "pipelines/block-step": "stepTypeBlock",
  "pipelines/input-step": "stepTypeInput",
  "pipelines/trigger-step": "stepTypeTrigger",
  "pipelines/group-step": "stepTypeGroup",
  "pipelines/notifications": "notificationServices",
};

type ContentItemListItemProps = {
  contentItem: ContentItem;
  onClick: (contentItem: ContentItem) => void;
};

class ContentItemListItem extends PureComponent<ContentItemListItemProps> {
  handleContentItemClick = (event: React.SyntheticEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.props.onClick(this.props.contentItem);
  };

  get iconName(): string {
    const { name, docsURL } = this.props.contentItem;

    const docsPage = docsURL && docsURL.split("/docs/").pop();
    const icon = docsPage && ICON_MAPPING[docsPage];

    if (!icon) {
      Bugsnag.notify(
        new TypeError(
          `PipelineEditorSidebar: No icon mapping for docs page with title ${JSON.stringify(
            name,
          )} & docs URL ${JSON.stringify(docsURL) || "<none>"}`,
        ),
      );
    }

    return icon || "pipeline";
  }

  render() {
    return (
      <StackableListLink
        key={this.props.contentItem.name}
        onClick={this.handleContentItemClick}
      >
        {/* @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'Node'. */}
        <div className="flex flex-auto items-top px2">
          <Icon className="flex-none ml1 mr2" icon={this.iconName} />
          <div className="flex flex-column">
            <ListItemTitle>{this.props.contentItem.name}</ListItemTitle>
            <p className="dark-gray m0">
              {this.props.contentItem.shortDescription}
            </p>
          </div>
        </div>
      </StackableListLink>
    );
  }
}

type FilteredContentAttributeViewProps = {
  contentAttribute: ContentAttribute;
  contextContentItem?: ContentItem;
  onClick?: (contentItem: ContentItem) => void;
  activeItem?: boolean;
};

type FilteredContentAttributeViewState = {
  flashContent?: boolean;
};

class FilteredContentAttributeView extends PureComponent<
  FilteredContentAttributeViewProps,
  FilteredContentAttributeViewState
> {
  handleContentAttributeClick = (
    event: React.SyntheticEvent<HTMLAnchorElement>,
  ) => {
    if (this.props.onClick && this.props.contextContentItem) {
      this.props.onClick(this.props.contextContentItem);
      event.preventDefault();
    }
  };

  scrollRef: {
    current: null | HTMLDivElement;
  };

  constructor(props: FilteredContentAttributeViewProps) {
    super(props);
    this.scrollRef = createRef();
  }

  componentDidMount() {
    if (this.props.activeItem === true && this.scrollRef.current !== null) {
      this.scrollRef.current.scrollIntoView(true);
    }
  }

  render() {
    const isClickable = this.props.onClick && !!this.props.contextContentItem;

    const content = (
      <AttributeContentContainer flashContent={this.props.activeItem}>
        <div className="flex flex-wrap items-center mb2" ref={this.scrollRef}>
          <h3 className="flex-auto my0">
            {/* @ts-expect-error - TS2769 - No overload matches this call. */}
            <Code>{this.props.contentAttribute.name}</Code>
            {this.props.contextContentItem && (
              <span> in {this.props.contextContentItem.name}</span>
            )}
          </h3>
          {this.props.contentAttribute.docsURL && (
            <a
              className="flex-none ml-auto lime hover-lime text-decoration-none hover-underline"
              href={this.props.contentAttribute.docsURL}
              target="_blank"
              rel="noreferrer noopener"
            >
              View in Docs
            </a>
          )}
        </div>
        <Markdown disallowLinks={isClickable}>
          {/* @ts-expect-error - TS2769 - No overload matches this call. */}
          {this.props.onClick
            ? this.props.contentAttribute.textContent.split("\n").shift()
            : this.props.contentAttribute.textContent}
        </Markdown>
      </AttributeContentContainer>
    );

    if (isClickable) {
      return (
        <StackableListLink onClick={this.handleContentAttributeClick}>
          {/* @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'Node'. */}
          <div className="px2">{content}</div>
        </StackableListLink>
      );
    }

    return <div className="my3">{content}</div>;
  }
}

export type TrackingContext = {
  organizationUuid: string;
};

function _trackContentItemClicked(
  contentItem: ContentItem,
  trackingContext: TrackingContext,
) {
  track("Step Editor Guide Content Item Clicked", {
    name: contentItem.name,
    organization_uuid: trackingContext.organizationUuid,
  });
}
