import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import Spinner from "./Spinner";

/* eslint-disable react/prefer-es6-class */
// TODO: Move to a class

export default createReactClass({
  displayName: "RemoteButtonComponent",

  propTypes: {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onBeforeStart: PropTypes.func,
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
    loadingIndicator: PropTypes.node,
    children: PropTypes.node,
    tooltipText: PropTypes.string,
    url: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    confirmText: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
  },

  getDefaultProps() {
    return {
      loadingIndicator: <Spinner className="mx-2" size={16} color={false} />,
    };
  },

  getInitialState() {
    return { loading: false };
  },

  componentDidMount() {
    // FYI, These are @rails/ujs event handlers
    this.buttonNode.addEventListener("ajax:beforeSend", this._onAjaxBeforeSend);
    this.buttonNode.addEventListener("ajax:success", this._onAjaxSuccess);
    this.buttonNode.addEventListener("ajax:error", this._onAjaxError);
  },

  componentWillUnmount() {
    this.buttonNode.removeEventListener(
      "ajax:beforeSend",
      this._onAjaxBeforeSend,
    );
    this.buttonNode.removeEventListener("ajax:success", this._onAjaxSuccess);
    this.buttonNode.removeEventListener("ajax:error", this._onAjaxError);
  },

  _onAjaxBeforeSend() {
    if (this.props.onBeforeStart) {
      this.props.onBeforeStart();
    }

    return this.setState({ loading: true });
  },

  _onAjaxSuccess(event) {
    // https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
    const [response, _status, _xhr] = event.detail;

    this.setState({ loading: false });

    if (this.props.onSuccess) {
      return this.props.onSuccess(event, response);
    }
  },

  _onAjaxError(event) {
    // https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
    const [response, _status, _xhr] = event.detail;

    this.setState({ loading: false });

    if (this.props.onError) {
      this.props.onError(event, response);
    } else {
      if (response.message) {
        alert(response.message);
      }
    }
  },

  render() {
    const contents =
      this.state.loading || this.props.loading ? (
        <>
          {this.props.loadingIndicator} {this.props.loadingText}
        </>
      ) : (
        this.props.children
      );

    const attributes = {};

    if (this.props.tooltipText) {
      attributes["aria-label"] = this.props.tooltipText;
    }

    return (
      <a
        ref={(buttonNode) => (this.buttonNode = buttonNode)}
        href={this.props.url}
        data-method={this.props.method}
        data-remote="true"
        data-confirm={this.props.confirmText}
        disabled={this.state.loading || this.props.loading}
        style={this.props.style}
        rel="nofollow"
        className={this.props.className}
        {...attributes}
      >
        {contents}
      </a>
    );
  },
});
