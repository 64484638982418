import EmptyState from "app/components/shared/EmptyState";
import { Test } from "../../shared/type";
import Row from "./Row";
import Tooltip from "../../shared/Tooltips/BasicTooltip";

export type RowTest = Omit<Test, "averageDuration"> & {
  average_duration: string;
};

type Props = {
  tests: Array<RowTest>;
  tooltip: string;
};

const Table = ({ tests, tooltip }: Props) => {
  if (tests.length === 0) {
    return (
      <EmptyState
        emoji="🤖"
        heading="No tests have been run"
        subheading="We haven’t received any test executions. Create a run and check back soon."
      />
    );
  }

  return (
    <section>
      <ul className="ta-panel">
        <li
          aria-hidden={true}
          className="ta-panel--heading grid-cols-[auto_6rem_8rem]"
        >
          <div>Description</div>
          <div className="flex items-center justify-end">
            Reliability
            <Tooltip classNames="ml-1" name="Test reliability calculation">
              <p className="font-semibold m-0 charcoal-800">Test reliability</p>
              <p
                className="m-0 mt-1 charcoal-800"
                dangerouslySetInnerHTML={{ __html: tooltip }}
              />
            </Tooltip>
          </div>
          <div className="text-right">Average duration</div>
        </li>

        {tests.map((test) => (
          <Row key={test.id} test={test} />
        ))}
      </ul>
    </section>
  );
};

export default Table;
