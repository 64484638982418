export type BuildState =
  | "failed"
  | "passed"
  | "started"
  | "scheduled"
  | "impersonate"
  | "blocked";

export interface FaviconPath {
  svg: string;
  png: {
    light: string;
    dark: string;
  };
}

export type FaviconPaths = Partial<Record<BuildState, FaviconPath>>;

const createFaviconLink = (options: {
  type: "image/svg+xml" | "image/png";
  href: string;
  media?: string;
}): HTMLLinkElement => {
  const link = document.createElement("link");
  link.rel = "icon";
  link.type = options.type;
  link.href = options.href;
  if (options.media) {
    link.media = options.media;
  }
  return link;
};

const updateLink = (iconPath: FaviconPath): void => {
  const {
    svg,
    png: { light, dark },
  } = iconPath;
  const existingIcons =
    document.querySelectorAll<HTMLLinkElement>("link[rel='icon']");
  existingIcons.forEach((icon) => icon.remove());

  // SVG favicon (light/dark theme handled inside SVGs)
  document.head.appendChild(
    createFaviconLink({
      type: "image/svg+xml",
      href: svg,
    }),
  );

  // PNG light mode favicon
  document.head.appendChild(
    createFaviconLink({
      type: "image/png",
      href: light,
      media: "(prefers-color-scheme: light)",
    }),
  );

  // PNG dark mode favicon
  document.head.appendChild(
    createFaviconLink({
      type: "image/png",
      href: dark,
      media: "(prefers-color-scheme: dark)",
    }),
  );
};

const updateFavicon = (
  paths: FaviconPaths,
  state: BuildState,
  isImpersonating: boolean,
): void => {
  const impersonatePath = paths["impersonate"];
  const iconPath =
    isImpersonating && impersonatePath ? impersonatePath : paths[state];

  if (iconPath) {
    updateLink(iconPath);
  }
};

export default updateFavicon;
