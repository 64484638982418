import { track } from "app/lib/segmentAnalytics";
import { useBuild } from "app/components/Playground/BuildContext";

// NOTE: Can only be used on new build#show page
export const useTrack = () => {
  const { build } = useBuild();

  if (!build) {
    return () => null;
  }

  const buildContext = {
    build_number: build.number,
    organization_slug: build.account.slug,
    pipeline: build.project.slug,
  };

  return (event: string, properties?: Record<string, any>) => {
    track(event, Object.assign(buildContext, properties));
  };
};
