/* eslint-disable react/jsx-no-bind */
import { useEffect } from "react";
import { useBuild } from "app/components/Playground/BuildContext";
import {
  useFilterStore,
  FilterByOption,
  GroupByOption,
} from "./useFilterStore";
import GroupByDropdown from "./GroupByDropdown";
import FilterByDropdown from "./FilterByDropdown";
import { StepList } from "./StepList";
import { AnnotationsLink } from "./AnnotationsLink";

export default function Sidebar() {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const setFilterBy = useFilterStore((state) => state.setFilterBy);
  const groupOption = useFilterStore((state) => state.groupOption);

  useEffect(() => {
    // If already grouping by state, don't bother applying any filters when running.
    if (groupOption === GroupByOption.State && build.state === "failing") {
      return;
    }

    // If the build has failed, default the view to show failed steps
    if (build.state === "failed" || build.state === "failing") {
      setFilterBy([FilterByOption.Failed]);
    }
  }, [build.state]);

  return (
    <div
      className="absolute -left-2 -right-2 px-2 pb-0.5 h-full overflow-y-auto no-scrollbar flex flex-col gap-2 z-0"
      data-testid="sidebar"
    >
      <div className="flex flex-col sticky top-0 bg-white gap-4 z-10 pb-1.5 -mx-1.5">
        <AnnotationsLink {...build} />

        <div className="flex gap-1 w-full items-center flex-wrap">
          <div className="flex flex-auto gap-1 flex-wrap">
            <FilterByDropdown />
            <GroupByDropdown />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <StepList />
      </div>
    </div>
  );
}
