import { twMerge } from "tailwind-merge";

import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";
import Emojify from "app/components/shared/Emojify";
import PipelineStateIcon, {
  colorForJob,
} from "app/components/shared/PipelineStateIcon/PipelineStateIcon";

import { Label } from "./Label";
import { JobRetries } from "./JobRetries";

export const JobLabel = ({ job }: { job: CommandJob }) => {
  const name = job.name || job.command || "";

  let count: string | null = null;
  if (job.parallelGroupIndex !== null && job.parallelGroupIndex !== undefined) {
    count = `${job.parallelGroupIndex + 1}/${job.parallelGroupTotal}`;
  }

  return (
    <Label
      command={job.command || undefined}
      status={
        <>
          <JobRetries job={job} />
          <div
            className={twMerge(
              "self-stretch flex items-center justify-center w-7",
              colorForJob(job),
            )}
          >
            <PipelineStateIcon
              job={job}
              className="w-4 h-4"
              style={{ width: undefined, height: undefined }}
            />
          </div>
        </>
      }
    >
      <div className="inline-block space-x-1">
        {count && (
          <span className="rounded-[3px] px-0.5 border border-current text-xs bg-white inline-block">
            {count}
          </span>
        )}

        {name && <Emojify text={name} />}
      </div>
    </Label>
  );
};
