import * as Select from "@radix-ui/react-select";
import { useCallback } from "react";

import PipelineStateIcon from "app/components/shared/PipelineStateIcon/PipelineStateIcon";
import Icon from "app/components/shared/Icon";
import { BuildStates } from "app/constants/BuildStates";
import { Build } from "app/stores/BuildShowStore";
import { useTrack } from "app/components/build/Show/lib/useTrack";

import { getBuildColor } from "../Show/lib/getBuildColor";
import { urlForView } from "../Show/lib/urlForView";
import { BuildView } from "../Show";

interface Props {
  number: number;
  currentView?: BuildView;
  state: BuildStates;
  branchName: string;
  project: {
    url: string;
  };
  nextBranchBuild?: Build["nextBranchBuild"];
  prevBranchBuild?: Build["prevBranchBuild"];
}

export default function BuildNumber({
  currentView,
  number,
  state,
  branchName,
  prevBranchBuild,
  nextBranchBuild,
  project,
}: Props) {
  const track = useTrack();
  const navigate = useCallback((url) => {
    track("Open Different Build", {
      latest: url.match(/\/builds\/latest/i) ? true : false,
    });

    window.location.href = url;
  }, []);

  if (!prevBranchBuild && !nextBranchBuild) {
    return <span className="flex items-center">#{number} (latest)</span>;
  }

  return (
    <Select.Root
      value={`${number}`}
      defaultValue={`${number}`}
      onValueChange={navigate}
      disabled={!prevBranchBuild && !nextBranchBuild}
    >
      <Select.Trigger className="flex items-center rounded-md transition-colors outline-none hover:underline">
        <Select.Value />
        <span className="w-4">
          <Icon icon="custom/outline/chevron-down" className="w-5 h-5" />
        </span>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content className="z-50">
          <Select.Viewport className="bg-white rounded shadow-md">
            {prevBranchBuild && (
              <Select.Item
                title="Previous build on this branch"
                value={urlForView(prevBranchBuild.url, currentView)}
                className="px-2 py-1 flex gap-1 justify-between items-center hover:text-purple-600 hover:bg-purple-100 hover:outline-none cursor-pointer aria-[selected=true]:bg-purple-100 aria-[selected=true]:text-purple-600"
              >
                <Select.ItemText>#{prevBranchBuild.number}</Select.ItemText>
                <PipelineStateIcon
                  build={prevBranchBuild}
                  style={{ color: getBuildColor(prevBranchBuild).primaryColor }}
                />
              </Select.Item>
            )}

            <Select.Item
              value={`${number}`}
              className="px-2 py-1 flex gap-1 justify-between items-center hover:text-purple-600 hover:bg-purple-100 hover:outline-none cursor-pointer aria-[selected=true]:bg-purple-100 aria-[selected=true]:text-purple-600"
            >
              <Select.ItemText>
                #{number}
                {!nextBranchBuild && " (latest)"}
              </Select.ItemText>
              <PipelineStateIcon
                build={{ state }}
                style={{ color: getBuildColor({ state }).primaryColor }}
              />
            </Select.Item>

            {nextBranchBuild && (
              <Select.Item
                title="Next build on this branch"
                value={urlForView(nextBranchBuild.url, currentView)}
                className="px-2 py-1 flex gap-1 justify-between items-center hover:text-purple-600 hover:bg-purple-100 hover:outline-none cursor-pointer aria-[selected=true]:bg-purple-100 aria-[selected=true]:text-purple-600"
              >
                <Select.ItemText>#{nextBranchBuild.number}</Select.ItemText>
                <PipelineStateIcon
                  build={nextBranchBuild}
                  style={{ color: getBuildColor(nextBranchBuild).primaryColor }}
                />
              </Select.Item>
            )}

            {nextBranchBuild && (
              <>
                <Select.Separator className="border-b" />

                <Select.Item
                  title="Next build on this branch"
                  value={
                    urlForView(`${project.url}/builds/latest`, currentView) +
                    `?branch=${branchName}`
                  }
                  className="px-2 py-1 flex items-center hover:text-purple-600 hover:bg-purple-100 hover:outline-none cursor-pointer aria-[selected=true]:bg-purple-100 aria-[selected=true]:text-purple-600"
                >
                  <Select.ItemText>Latest</Select.ItemText>
                </Select.Item>
              </>
            )}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
