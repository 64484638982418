import { AnimatePresence } from "framer-motion";
import { cloneElement, useEffect, useState } from "react";
import { useOutlet } from "react-router-dom";
import { createPortal } from "react-dom";

/**
 * A react-router outlet for animating in a drawer on route changes.
 *
 * The key is fixed to ensure the drawer doesn't slide up-and-down when switching out the inner
 * content within a drawer (ie. selected different steps).
 */
export const DrawerOutlet = () => {
  const element = useOutlet();
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const portal = document.getElementById("drawer-container");
    setContainer(portal);
  }, []);

  if (!container) {
    return element;
  }

  return createPortal(
    <AnimatePresence initial={false}>
      {element && cloneElement(element, { key: `drawer` })}
    </AnimatePresence>,
    container,
  );
};
