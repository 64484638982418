import { useBuild } from "app/components/Playground/BuildContext";
import { useMemo } from "react";
import capitalize from "lodash/capitalize";

import Badge from "app/components/shared/Badge";
import { GroupStep, Step } from "app/lib/pipeline";
import EmptyState from "./EmptyState";
import { createStepListItem } from "./StepListItem";
import {
  groupStepsBy,
  filterSteps,
  useFilterStore,
  StateOrder,
  GroupByOption,
} from "./useFilterStore";
import { AnimatePresence, motion } from "framer-motion";
import * as Accordion from "@radix-ui/react-accordion";
import Icon from "app/components/shared/Icon";

// Create nested steps by grouping steps by their group UUID
function createNestedSteps(steps: Step[]) {
  const stepsMap = new Map(steps.map((step) => [step.uuid, { ...step }]));

  stepsMap.forEach((step) => {
    if (step.groupUuid) {
      const group = stepsMap.get(step.groupUuid) as GroupStep;
      if (group && step.type !== "group") {
        if (!group.steps) {
          group.steps = [];
        }
        group.steps.push(step);
      }
      stepsMap.delete(step.uuid);
    }
  });

  // Remove empty groups
  return Array.from(stepsMap.values()).filter((step) => {
    if (step.type === "group" && !step.steps?.length) {
      return false;
    }
    return true;
  });
}

/**
 * Render a list of build steps.
 */
export function StepList() {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const filterBy = useFilterStore((state) => state.filterBy);
  const groupOption = useFilterStore((state) => state.groupOption);

  // Apply filters then group steps by group uuid
  const filteredSteps = useMemo(() => {
    const filtered = filterSteps(build.steps, filterBy, build);

    // If we are grouping by state, we don't want to group the steps
    if (groupOption === GroupByOption.State) {
      return filtered.filter((step) => step.type !== "group");
    }

    return createNestedSteps(filtered);
  }, [build.steps, filterBy, groupOption]);

  const groupedSteps = useMemo(() => {
    return groupStepsBy(filteredSteps, groupOption);
  }, [filteredSteps, groupOption]);

  if (filteredSteps.length === 0) {
    return <EmptyState />;
  }

  return (
    <div>
      {groupedSteps ? (
        <div className="flex flex-col gap-5">
          <Accordion.Root type="multiple" defaultValue={[...StateOrder]}>
            {StateOrder.map((state) => {
              const steps = groupedSteps[state];
              if (!steps) {
                return null;
              }

              return (
                <Accordion.Item
                  key={state}
                  value={state}
                  data-testid={`${state}-group`}
                >
                  <Accordion.Header className="m-0">
                    <Accordion.Trigger className="flex items-center gap-1 px-2 group">
                      <Badge className="bg-white font-normal tracking-wider text-charcoal-600 text-[12px] py-0.5 px-0 m-0 my-1 uppercase">
                        {capitalize(state)}
                      </Badge>
                      <div className="self-stretch shrink-0 flex items-center justify-center w-7">
                        <Icon
                          icon="heroicons/outline/chevron-right"
                          className="w-3 h-3 transition-transform duration-150 group-data-[state=open]:rotate-90"
                        />
                      </div>
                    </Accordion.Trigger>
                  </Accordion.Header>
                  <Accordion.Content>
                    <AnimatePresence initial={false}>
                      {steps.map((step) => (
                        <motion.div
                          key={step.uuid}
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                        >
                          {createStepListItem({ step })}
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </Accordion.Content>
                </Accordion.Item>
              );
            })}
          </Accordion.Root>
        </div>
      ) : (
        filteredSteps.map((step) => createStepListItem({ step }))
      )}
    </div>
  );
}
