import { createFragmentContainer, graphql } from "react-relay";
import classNames from "classnames";

import TeamLabels from "./Labels";

import { formatNumber } from "app/lib/number";
import Panel from "app/components/shared/Panel";
import UserAvatar from "app/components/shared/UserAvatar";
import Emojify from "app/components/shared/Emojify";

const maxAvatars = 4;
const avatarSize = 30;

const Description = ({ description }) =>
  description ? (
    <div className="regular dark-gray mt1 h5">
      <Emojify text={description} />
    </div>
  ) : null;

const AssignmentsCount = ({ pipelines, suites, registries }) => {
  const countsText = new Array();

  if (pipelines.count !== 0) {
    countsText.push(
      `${pipelines.count} pipeline${pipelines.count === 1 ? "" : "s"}`,
    );
  }

  if (suites.count !== 0) {
    countsText.push(`${suites.count} suite${suites.count === 1 ? "" : "s"}`);
  }

  if (registries.count !== 0) {
    countsText.push(
      `${registries.count} registr${registries.count === 1 ? "y" : "ies"}`,
    );
  }

  if (countsText.length > 0) {
    return <span className="regular mr2">{countsText.join(", ")}</span>;
  }

  return null;
};

const MemberExtrasCount = ({ members }) => {
  const extrasCount = members.count - maxAvatars;

  if (extrasCount > 0) {
    return (
      <div
        className="inline-block bg-gray bold text-center border border-white semi-bold px1"
        style={{
          borderRadius: avatarSize / 2,
          minWidth: avatarSize,
          height: avatarSize,
          lineHeight: `${avatarSize - 4}px`,
          fontSize: 11,
          borderWidth: 2,
        }}
        title={`and another ${formatNumber(extrasCount)} member${
          extrasCount === 1 ? "" : "s"
        }`}
      >
        {"+" + formatNumber(extrasCount)}
      </div>
    );
  }

  return null;
};

const MemberAvatars = ({ members }) =>
  members.count !== 0 && (
    <div className="min-w-[120px] mr3 flex justify-end -space-x-2.5">
      {members.edges.map((edge) => {
        return (
          <UserAvatar
            key={edge.node.id}
            user={edge.node.user}
            className="align-middle border border-white"
            style={{
              width: avatarSize,
              height: avatarSize,
              borderWidth: 2,
            }}
          />
        );
      })}
      <MemberExtrasCount members={members} />
    </div>
  );

const TeamRow = ({ team }) => {
  return (
    <Panel.RowLink
      key={team.id}
      href={`/organizations/${team.organization.slug}/teams/${team.slug}`}
    >
      <div
        className="flex flex-stretch items-center line-height-1"
        style={{ minHeight: "3em" }}
      >
        <div className="flex-auto">
          <div
            className={classNames("m0 flex items-center", {
              mb1: team.description,
            })}
          >
            <Emojify text={team.name} className="semi-bold" />
            <TeamLabels team={team} />
          </div>
          <Description description={team.description} />
        </div>
        <div className="flex items-center my1">
          <AssignmentsCount
            pipelines={team.pipelines}
            suites={team.suites}
            registries={team.registries}
          />

          <MemberAvatars members={team.members} />
        </div>
      </div>
    </Panel.RowLink>
  );
};

export default createFragmentContainer(TeamRow, {
  team: graphql`
    fragment Row_team on Team
    @argumentDefinitions(maxAvatars: { type: "Int", defaultValue: 4 }) {
      id
      name
      description
      slug
      ...Labels_team
      organization {
        slug
      }
      members(first: $maxAvatars) {
        count
        edges {
          node {
            id
            user {
              name
              avatar {
                url
              }
            }
          }
        }
      }
      pipelines {
        count
      }
      suites {
        count
      }
      registries {
        count
      }
    }
  `,
});
