import { PureComponent } from "react";

import Spinner from "./Spinner";

export default class SectionLoader extends PureComponent {
  render() {
    return (
      <div className="text-center my4">
        <Spinner />
      </div>
    );
  }
}
