import { includes } from "lodash";

import CopyButton from "app/components/shared/CopyButton";
import Icon from "app/components/shared/Icon";

interface Props {
  location: string | undefined;
  locationUrl?: string | undefined;
}

const TestLocation = ({ location, locationUrl }: Props) => {
  if (!location) {
    return null;
  }

  return (
    <div className="flex items-center gap-0.5 charcoal-300 text-xs max-w-full">
      <span className="flex items-center gap-0.5 truncate">
        <span className="truncate">{location}</span>
        <CopyButton contentToCopy={location} iconOnly={true} />
      </span>

      {locationUrl && (
        <>
          <Icon
            icon="custom/outline/20x/slash"
            style={{ height: 14, width: 14 }}
          />
          <a
            href={locationUrl}
            className="flex items-center gap-0.5 color-inherit hover-color-inherit no-underline hover:underline focus:underline"
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              icon={includes(locationUrl, "github.com") ? "github" : "git"}
              style={{ height: 14, width: 14 }}
            />
            {includes(locationUrl, "github.com") ? "github" : "git"}
          </a>
        </>
      )}
    </div>
  );
};

export default TestLocation;
