import { useState } from "react";
import { countBy, map } from "lodash";
import { twMerge } from "tailwind-merge";

import Emojify from "app/components/shared/Emojify";
import { RunDuration } from "app/components/job/Job/JobDuration";
import { useRetriesForJob } from "app/components/Playground/BuildContext";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  HoverCardPortal,
} from "app/components/shared/HoverCard";
import PipelineStateIcon, {
  colorForJob,
} from "app/components/shared/PipelineStateIcon/PipelineStateIcon";

import { StepLink } from "./StepLink";
import { Label } from "./Label";

export function JobRetries({ job }) {
  const [open, setOpen] = useState(false);

  const jobs = useRetriesForJob(job, { includeLatest: false });

  if (!jobs.length) {
    return null;
  }

  const retryCounts = countBy(jobs, "retryType");
  const formattedRetryCounts = map(
    retryCounts,
    (count, type) => `${count} ${type}`,
  ).join(" · ");

  return (
    <HoverCard open={open} onOpenChange={setOpen}>
      <HoverCardTrigger asChild={true}>
        <span className="text-charcoal-500 underline decoration-dashed decoration-gray-600 underline-offset-2 text-xs font-medium ml-1.5">
          {jobs.length > 1 ? `${jobs.length} retries` : `${jobs.length} retry`}
        </span>
      </HoverCardTrigger>

      <HoverCardPortal>
        <HoverCardContent side="right" size="max-lg">
          <div className="flex items-center justify-between mb-1 gap-x-4 whitespace-nowrap">
            <Emojify text={job.name} className="text-sm font-medium truncate" />

            <div className="text-charcoal-600 text-[11px]">
              {formattedRetryCounts}
            </div>
          </div>

          {jobs.map((job) => {
            const retryType =
              job.retryType === "manual"
                ? "Retried manually"
                : "Retried automatically";

            return (
              <StepLink
                onClick={() => setOpen(false)}
                className="text-xs px-2 -mx-2 py-1 gap-x-4"
                uuid={job.id}
                key={job.id}
              >
                <Label
                  status={
                    <div className={twMerge("font-normal", colorForJob(job))}>
                      <RunDuration job={job} />

                      <PipelineStateIcon
                        job={job}
                        className="ml-1"
                        style={{ width: 16, height: 16 }}
                      />
                    </div>
                  }
                >
                  {retryType}
                </Label>
              </StepLink>
            );
          })}
        </HoverCardContent>
      </HoverCardPortal>
    </HoverCard>
  );
}
